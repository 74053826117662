import React from "react";
import PropTypes from "prop-types";

const ImgContainer = ({ alt, src, width, height, className }) => {
  return (
    <img
      alt={alt}
      src={src}
      width={width}
      height={height}
      className={className}
    />
  );
};

export default ImgContainer;

ImgContainer.propTypes = {
  src: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
