import { useState, useEffect } from "react";

const usePersistentCountdownTimer = (initialSeconds = 3600) => {
  const [timer, setTimer] = useState(() => {
    const savedTimer = localStorage.getItem("timer");

    return JSON.parse(savedTimer) || [];
  });

  useEffect(() => {
    if (timer?.length !== 0) {
      const activeTimers = timer?.filter((time) => time?.isActive);

      if (activeTimers.length === 0) {
        return;
      }

      const interval = setInterval(() => {
        setTimer((prevTimers) => {
          return prevTimers.map((time) => {
            if (time.isActive && time.seconds > 0) {
              const newSeconds = time.seconds - 1;

              return { ...time, seconds: newSeconds };
            }

            return { ...time, isActive: false };
          });
        });

        localStorage.setItem("timer", JSON.stringify(timer));
      }, 1000);

      // eslint-disable-next-line consistent-return
      return () => clearInterval(interval);
    }
  }, [timer]);

  const toggleTimer = (id) => {
    const newTimer = [
      ...timer,
      {
        id,
        seconds: initialSeconds,
        isActive: true,
      },
    ];

    setTimer(newTimer);
    localStorage.setItem("timer", JSON.stringify(newTimer));
  };

  return {
    timer,
    setTimer,
    toggleTimer,
  };
};

export default usePersistentCountdownTimer;
