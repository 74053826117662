import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fetchTable } from "services/services.projects";
import { fetchCongressmanProjects } from "services/services.congressman";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const StyledModalBody = styled(ModalBody)`
  padding: 32px;
  max-height: 500px;
  overflow-y: auto;
`;

const DownloadLink = styled.a`
  color: #808080;
  margin-left: 13px;
  display: block;
  font-size: 15px;
  :hover {
    color: #909090;
  }
`;

const JustificationWrapper = styled.div`
  margin-top: 30px;
`;

const SupportingDocumentsModal = ({
  congID,
  congressmanId,
  isOpen,
  programID,
  selectedAgency,
  setModal,
}) => {
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    await fetchTable({
      url: `/project/${programID}/recipients/`,
      callback: (data) => {
        setProjects(data?.rows);
      },
    });
  };

  const fetchCongressProjects = async () => {
    await fetchCongressmanProjects({
      // eslint-disable-next-line max-len
      url: `/congressman/${congressmanId}/agencies/${selectedAgency?.ID}/recipients`,
      callback: (data) => {
        setProjects(data?.rows);
      },
    });
  };

  useEffect(() => {
    if (isOpen) {
      if (congID && programID) {
        fetchProjects();
      } else {
        fetchCongressProjects();
      }
    }
  }, [isOpen]);

  const getFileName = (document) => {
    const parts = document.split("/");
    const filenameWithEncodedChars = parts[parts.length - 1];

    return decodeURIComponent(filenameWithEncodedChars);
  };

  const renderFiles = () => {
    const filteredData = projects.find((item) => item.id === congID);
    const supportingDocuments = filteredData?.documents?.supporting;

    if (!supportingDocuments || supportingDocuments?.length === 0) {
      return <p>{"No supporting documents attached"}</p>;
    }

    return supportingDocuments.map((document, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          {index + 1}
          {". "}
          {getFileName(document)}
          <DownloadLink
            href={document}
            download="file.png"
          >
            {"Download File"}
          </DownloadLink>
        </div>
      );
    });
  };

  const renderJustification = () => {
    const filteredData = projects.find((item) => item.id === congID);
    const justification = filteredData?.justification || "No Justification";

    return (
      <JustificationWrapper>
        <h5>{"Justification:"}</h5>
        <p>{justification}</p>
      </JustificationWrapper>
    );
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={setModal}
      >
        <ModalHeader toggle={setModal}>
          {"Attachments and Justification"}
        </ModalHeader>
        <StyledModalBody>
          <h5>{"Supporting Documents: "}</h5>
          {renderFiles()}
          {renderJustification()}
        </StyledModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={setModal}
          >
            {"Back"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SupportingDocumentsModal;
