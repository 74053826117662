import React, { useState } from "react";
import { Col } from "reactstrap";
import SearchBar from "components/customs/searchbar";
import horlogotext from "assets/img/19thCong.png";
import horseal from "assets/img/HOR.png";
import api from "services/api";

const Main = () => {
  const [searchResult, setSearchResult] = useState([]);

  let changeSearchTimeoutID;

  const handleSearchOnKeyUp = (e) => {
    e.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(async () => {
      const { value } = e.target;

      if (value?.length !== 0 && value !== " ") {
        try {
          await api({
            Method: "GET",
            Url: `/congressman?search=${value}`,
            // eslint-disable-next-line consistent-return
            ResponseSuccessCallback: (response) => {
              if (response && response?.data) {
                const data = response?.data?.length !== 0 ? response?.data : [];

                setSearchResult(data);
              }
            },
            ResponseFailCallback: () => {
              setSearchResult([]);
            },
          });
        } catch (err) {
          setSearchResult([]);
          // eslint-disable-next-line no-console
          console.error(err);
        }
      }

      if (value?.length === 0 && searchResult?.length !== 0) {
        setSearchResult([]);
      }
    }, 1000);
  };

  return (
    <>
      <div className="mt-5 hor-logo d-flex justify-content-center">
        <img
          alt="horseal"
          src={horseal}
        />
      </div>
      <div className="d-flex justify-content-center text-logo">
        <img
          alt="horlogo"
          src={horlogotext}
        />
      </div>
      <div className="d-flex justify-content-center mt-3 mb-4">
        <Col
          xs="10"
          sm="8"
          md="6"
          lg="5"
          xl="4"
          className="text-center"
        >
          <h5 className="layout1-dir-hor-text">
            {"DIRECTORY OF HOUSE MEMBERS PROGRAMS AND PROJECTS"}
          </h5>
        </Col>
      </div>
      <div className="mt-2 mb-3 d-flex justify-content-center">
        <Col
          xs="7"
          sm="6"
          md="6"
          lg="5"
          xl="4"
        >
          {/* params : search result, onchange func */}
          <SearchBar
            handleOnKeyUp={handleSearchOnKeyUp}
            searchResult={searchResult}
          />
        </Col>
      </div>
    </>
  );
};

export default Main;
