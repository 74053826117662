import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import mime from "mime";
import PropTypes from "prop-types";

import "yet-another-react-lightbox/styles.css";
import thumbnail from "assets/img/videothumbnail.png";

const ImageVideoSlider = ({ images, isOpen, setIsOpen }) => {
  const slides = images.map((image) => {
    const fileType = mime.getType(image)?.split("/")[0];

    if (fileType === "image") {
      return {
        src: image,
        width: 3840,
        height: 2560,
      };
    }

    return {
      type: "video",
      width: 3840,
      height: 2560,
      autoPlay: true,
      poster: thumbnail,
      sources: [
        {
          src: image,
          type: "video/mp4",
        },
      ],
    };
  });

  return (
    <Lightbox
      plugins={[Video]}
      index={0}
      open={isOpen}
      close={() => setIsOpen(false)}
      slides={slides}
    />
  );
};

ImageVideoSlider.propTypes = {
  images: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default ImageVideoSlider;
