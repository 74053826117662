/* eslint-disable max-len */
import { useState } from "react";

export default function useTextSeeMoreOrLess() {
  const [expandedSeeMoreOrLessTexts, setExpandedSeeMoreOrLessTexts] = useState(
    []
  );

  const isTextExpanded = (text) =>
    expandedSeeMoreOrLessTexts.find(
      (expandedSeeMoreOrLessText) => expandedSeeMoreOrLessText === text
    ) !== undefined;

  const insertOrRemoveExpandedSeeMoreOrLessTexts = (text) => {
    if (
      !expandedSeeMoreOrLessTexts.some(
        (expandedSeeMoreOrLessText) => expandedSeeMoreOrLessText === text
      )
    ) {
      const expandedSeeMoreOrLessTextsTemp = expandedSeeMoreOrLessTexts;

      expandedSeeMoreOrLessTextsTemp.push(text);
      setExpandedSeeMoreOrLessTexts(expandedSeeMoreOrLessTextsTemp);
    } else {
      const textIndex = expandedSeeMoreOrLessTexts.findIndex(
        (expandedSeeMoreOrLessText) => expandedSeeMoreOrLessText === text
      );

      if (textIndex !== -1) {
        const expandedSeeMoreOrLessTextsTemp = expandedSeeMoreOrLessTexts;

        expandedSeeMoreOrLessTextsTemp.splice(textIndex, 1);
        setExpandedSeeMoreOrLessTexts(expandedSeeMoreOrLessTextsTemp);
      }
    }

    return isTextExpanded(text);
  };

  return {
    expandedSeeMoreOrLessTexts,
    setExpandedSeeMoreOrLessTexts,
    insertOrRemoveExpandedSeeMoreOrLessTexts,
    isTextExpanded,
  };
}
