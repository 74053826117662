import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";

import "./style.scss";

const SearchBar = ({ handleOnKeyUp, isDisabled = false, searchResult }) => {
  useEffect(() => {
    const el = document.querySelector(".searchbar-dropdown-container");

    if (el && Object.keys(searchResult).length && searchResult?.lenght !== 0) {
      el.classList.add("is-active");
    } else if (el.classList.contains("is-active")) {
      el.classList.remove("is-active");
    }
  }, [searchResult]);

  return (
    <div className="searchbar-dropdown-container">
      <div className="form-group has-search custom-searchbar position-relative has-icon-left">
        <span className="form-control-feedback">
          <FontAwesomeIcon icon={faSearch} />
        </span>

        <Input
          onChange={handleOnKeyUp}
          disabled={isDisabled}
          placeholder="Search"
        />

        <ul className="search-results mb-0">
          {searchResult &&
            searchResult?.lenght !== 0 &&
            searchResult?.map((search) => {
              return (
                <li
                  key={search?.id}
                  className="mt-1 mb-0"
                >
                  <a
                    // eslint-disable-next-line max-len
                    href={`/representatives/${search?.region?.id}/cong/${search?.id}`}
                  >
                    {`${search?.lastName}, ${search?.firstName}`}
                  </a>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  handleOnKeyUp: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  searchResult: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

export default SearchBar;
