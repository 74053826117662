import { Toast } from "utility/helpers";
import AWS from "aws-sdk";
import awsconfig from "config/aws-config";
import {
  AWS_ACCESS_KEY_ID,
  AWS_ACCESS_KEY,
  TINGOG_URL,
  TINGOG_API_KEY,
  S3_BUCKET,
} from "config";
import axios from "axios";
import api from "./api";

export const editRegionalDirector = async ({ url, formData, callback }) => {
  await api({
    Method: "PUT",
    Data: formData,
    Url: url,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: (err) => {
      Toast({
        type: 4,
        content: err?.message,
      });
    },
  });
};

export const createRegionalDirector = async ({ formData, callback }) => {
  await api({
    Url: "/regional-director",
    Method: "POST",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: (err) => {
      Toast({
        type: 4,
        content: err?.message,
      });
    },
  });
};

export const uploadPhoto = async ({ fileName, file, folderName, callback }) => {
  const S3_BUCKET_NAME = `${S3_BUCKET}/${folderName}`;
  const REGION = awsconfig.aws_cognito_region;

  AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_ACCESS_KEY,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET_NAME },
    region: REGION,
  });

  const params = {
    Bucket: S3_BUCKET_NAME,
    Key: fileName,
    Body: file,
  };

  try {
    const data = await s3.upload(params).promise();

    if (data) {
      callback(data);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("err", err);
  }
};

export const fetchAtcOptions = async ({ params, callback }) => {
  try {
    const url = `${TINGOG_URL}/integration/master-file/principal`;

    const response = await axios.get(url, {
      headers: {
        Authorization: TINGOG_API_KEY,
      },
      params,
    });

    if (response && response.status === 200) {
      callback(response.data.data);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const fetchATCList = async ({ url, params, callback }) => {
  try {
    const response = await axios.get(url, {
      params,
      headers: {
        Authorization: TINGOG_API_KEY,
      },
    });

    if (response && response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const fetchSocialServicePrograms = async ({ callback }) => {
  try {
    const url = `${TINGOG_URL}/integration/master-file/service-options`;

    const response = await axios.get(url, {
      headers: {
        Authorization: TINGOG_API_KEY,
      },
    });

    if (response && response.status === 200) {
      callback(response.data.data);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const fetchAtcAgencies = async ({ callback }) => {
  try {
    const url = `${TINGOG_URL}/integration/master-file/agency`;

    const response = await axios.get(url, {
      headers: {
        Authorization: TINGOG_API_KEY,
      },
    });

    if (response && response.status === 200) {
      callback(response.data.data);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
