import React from "react";
import Layout4 from "components/layout/Layout4";
import Main from "./Main";

const DistrictRepresentatives = (props) => {
  return (
    <Layout4>
      <Main {...props} />
    </Layout4>
  );
};

export default DistrictRepresentatives;
