import React from "react";
import "./styles.scss";
import { X } from "react-feather";
import Flatpickr from "react-flatpickr";
import PropTypes from "prop-types";
import CustomSelect from "../dropdown";

import "flatpickr/dist/themes/material_blue.css";

const InputGroup = ({
  value,
  placeholder,
  handleOnChange,
  handleClearInput,
  minDate,
  maxDate,
  type,
  options = [],
  maxLength,
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      border: "none",
      boxShadow: "none",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      display: "none",
    }),
  };

  let inputComponent = null;

  switch (type) {
    case "text":
      inputComponent = (
        <input
          type="text"
          maxLength={maxLength || null}
          className="form-control"
          placeholder={placeholder || ""}
          value={value || ""}
          onChange={handleOnChange}
        />
      );
      break;

    case "date":
      inputComponent = (
        <Flatpickr
          value={value || new Date()}
          placeholder="Date"
          className="form-control"
          options={{
            enableTime: true,
            dateFormat: "M d, Y",
            minDate: minDate || null,
            maxDate: maxDate || null,
          }}
          onChange={handleOnChange}
        />
      );
      break;

    case "select":
      inputComponent = (
        <CustomSelect
          className="form-control p-0"
          placeholder={placeholder}
          onChange={handleOnChange}
          value={value}
          options={options}
          styles={customStyles}
          classNamePrefix="select"
        />
      );
      break;

    case "mobile":
      inputComponent = (
        <input
          type="text"
          maxLength={12}
          minLength={12}
          autoComplete="off"
          className="form-control"
          value={value}
          onKeyDown={(event) => {
            if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
              event.preventDefault();
            }
          }}
          // eslint-disable-next-line consistent-return
          onChange={(evt) => {
            const val = evt.target.value;

            if (val.startsWith("0")) {
              return handleOnChange(val.replace(/^0/, ""));
            }

            if (val === "") {
              return handleOnChange("63");
            }

            if (val[2] !== "9") {
              return handleOnChange(`63${val.substring(3)}`);
            }

            handleOnChange(val);
          }}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className="input-group mb-3 custom-input-group">
      <div className="input-group-append d-flex w-100">
        {inputComponent}
        <button
          className="btn clear-button"
          type="button"
          onClick={handleClearInput}
        >
          <X />
        </button>
      </div>
    </div>
  );
};

InputGroup.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleClearInput: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object]),
  maxLength: PropTypes.number,
};

export default InputGroup;
