import React from "react";
import { Col, Row } from "reactstrap";
import { Congress, HorSeal } from "../../views/Home/style";

const FullLayout = ({ children }) => {
  return (
    <Row className="m-0 p-0 full-layout">
      <Col
        className="p-0"
        lg="7"
        xl="8"
      >
        <div className="login-container">
          <HorSeal />
          <Congress />
          <div className="d-flex justify-content-center mt-3 mb-4">
            <Col
              xs="5"
              lg="6"
              xl="4"
              className="text-center"
            >
              <h6>{"DIRECTORY OF HOUSE MEMBERS, PROGRAMS AND PROJECTS"}</h6>
            </Col>
          </div>
        </div>
      </Col>
      {children}
    </Row>
  );
};

export default FullLayout;
