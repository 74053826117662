import React from "react";
import Select from "react-select";
import "./styles.scss";

const CustomSelect = (props) => {
  return (
    <div className="custom-dropdown--container">
      <Select {...props} />
    </div>
  );
};

CustomSelect.propTypes = {};

export default CustomSelect;
