import React from "react";
import Layout3 from "components/layout/Layout3";
import ViceGovernor from "./Vicegovernor";

const ViceGovernorDetails = (props) => {
  return (
    <Layout3>
      <ViceGovernor {...props} />
    </Layout3>
  );
};

export default ViceGovernorDetails;
