import React from "react";
import Layout3 from "components/layout/Layout3";
import Governor from "./Governor";

const GovernorDetails = (props) => {
  return (
    <Layout3>
      <Governor {...props} />
    </Layout3>
  );
};

export default GovernorDetails;
