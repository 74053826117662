import React, { useState } from "react";
import { Col } from "reactstrap";
import LoginForm from "components/forms/Login";
import ChangePasswordForm from "components/forms/ChangePassword";
import FullLayout from "../../components/layout/FullLayout";

const LoginComponent = () => {
  const [showLoginForm, setShowLoginForm] = useState(true);

  const handleNewPasswordRequired = () => {
    setShowLoginForm(false);
  };

  return (
    <FullLayout>
      <Col
        lg="5"
        xl="4"
        className="h-100 login-card-bg d-flex justify-content-center align-items-center flex-column"
      >
        {showLoginForm ? (
          <LoginForm handleNewPasswordRequired={handleNewPasswordRequired} />
        ) : (
          <ChangePasswordForm />
        )}
      </Col>
    </FullLayout>
  );
};

export default LoginComponent;
