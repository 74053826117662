import React from "react";
import Layout3 from "components/layout/Layout3";
import Main from "./Main";

const AddProject = (props) => {
  return (
    <Layout3>
      <Main {...props} />
    </Layout3>
  );
};

export default AddProject;
