import React from "react";
import Layout3 from "components/layout/Layout3";
import ChangePasswordComponent from "./ChangePassword";

const ChangePassword = (props) => {
  return (
    <Layout3>
      <ChangePasswordComponent {...props} />
    </Layout3>
  );
};

export default ChangePassword;
