import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, UncontrolledTooltip, Badge } from "reactstrap";
import ImgContainer from "components/common/img";
import BreadCrumbComponent from "components/customs/breadCrumb";
import SearchBarComponent from "components/customs/searchbarV2";
import CustomDataTable from "components/customs/table";
import CustomSelect from "components/customs/dropdown";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Edit2, Eye, Trash2 } from "react-feather";
import {
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormGenertor from "components/customs/form-generator/input";
import {
  getFormattedDateString,
  getFormattedNumber,
  hasPermission,
  Toast,
} from "utility/helpers";
import _ from "lodash";
import BudgetComponent from "components/customs/budget";
import StackedProgressBar from "components/customs/stacked-progress-bar";
import {
  editTotalBudget,
  fetchTable,
  fetchAllAgencies,
  fetchProjectAllocation,
  updateRow,
  deleteProject,
} from "services/services.projects";
import { genericGet } from "services/services.general";
import Swal from "sweetalert2";
import {
  defaultTableOption,
  DropdownIndicator,
  customStyles,
} from "utility/constants";

const Main = () => {
  const [tableOption, setTableOption] = useState(defaultTableOption);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectorOptions, setSelectorOptions] = useState([]);
  const [agencyDetails, setAgencyDetails] = useState();
  const [editingRowId, setEditingRowId] = useState("");
  const [initialProjects, setInitialProjects] = useState([]);
  const [columns, setColumns] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [totalBudget, setTotalBudget] = useState("");
  const [projects, setProjects] = useState([]);
  const [remainingBalance, setRemainingBalance] = useState("");
  const [isBudgetEditing, setIsBudgetEditing] = useState(false);
  const [totalAmountSpent, setTotalAmountSpent] = useState("");
  const [newTotalBudget, setNewTotalBudget] = useState();
  const [hasAccess, setHasAccess] = useState(true);

  const previousDateFrom = useRef();
  const isInitialMount = useRef(true);

  const navigate = useNavigate();

  const { id } = useParams();

  const dateRange = useSelector((state) => state.dateRange);
  const { dateFrom, dateTo } = dateRange || {};

  const dateParams =
    dateFrom && dateTo
      ? {
          startDate: dateFrom,
          endDate: dateTo,
        }
      : {};

  let changeSearchTimeoutID;

  const handleCancelClicked = (index) => {
    setEditingRowId("");

    const newData = [...tableOption.data];
    newData[index] = initialProjects[index];

    setTableOption({
      ...tableOption,
      data: newData,
    });
  };

  const defaultColumns = [
    {
      name: "",
      selector: (row) => row?.id,
      sortable: false,
      width: "50px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => <div>{index + 1}</div>,
    },
    {
      name: "Agency",
      selector: (row) => row?.name,
      identifier: "name",
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ name, acronym }) => <div>{`${name} (${acronym})`}</div>,
    },
    {
      name: "Budget Allocation",
      selector: (row) => row?.budgetAllocation,
      identifier: "budgetAllocation",
      sortable: true,
      id: "budget",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ budgetAllocation }) => (
        <div>{`PHP ${Number(budgetAllocation)?.toLocaleString("en-US")}`}</div>
      ),
    },
  ];

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value;

      setTableOption({
        ...tableOption,
        page: 1,
        searchValue: eventValue.trim(),
      });
    }, 1000);
  };

  const onChangePage = (page) => {
    setTableOption({
      ...tableOption,
      page,
      offset: tableOption.currentRowsPerPage * page - 10,
    });
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setTableOption({
      ...tableOption,
      currentRowsPerPage,
      page: currentPage,
    });
  };

  const sortArray = (column, sortDirection) => {
    if (column.identifier === "budgetAllocation") {
      let newData = [];

      if (sortDirection === "asc") {
        newData = tableOption.data.sort(
          (a, b) => a.budgetAllocation - b.budgetAllocation
        );
      } else {
        newData = tableOption.data.sort(
          (a, b) => b.budgetAllocation - a.budgetAllocation
        );
      }

      setTableOption({
        ...tableOption,
        data: newData,
      });
    }

    if (column.identifier === "name") {
      let newData = [];

      newData = tableOption.data.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return sortDirection === "asc" ? -1 : 1;
        }

        if (nameA > nameB) {
          return sortDirection === "asc" ? 1 : -1;
        }

        return 0;
      });

      setTableOption({
        ...tableOption,
        data: newData,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const onSort = (column, sortDirection) => {
    if (selectedProject?.ID === 0) return sortArray(column, sortDirection);

    setTableOption({
      ...tableOption,
      sort: {
        field: column.identifier,
        direction: sortDirection,
      },
    });
  };

  const getSelectorOptions = (rawOptions) => {
    if (rawOptions?.length !== 0) {
      const options =
        rawOptions?.map((select) => ({
          ID: select?.id,
          Name: select?.name,
          acronym: select?.acronym,
          label: (
            <h5 className="font-weight-bold mb-0">{`${select?.name} (${select?.acronym})`}</h5>
          ),
        })) || [];

      options.unshift({
        ID: 0,
        Name: "All Agencies",
        acronym: "all",
        label: <h5 className="font-weight-bold mb-0">{"All Agencies"}</h5>,
      });

      setSelectorOptions(options);

      // eslint-disable-next-line array-callback-return
      options?.map((op, index) => {
        if (op?.ID === Number(id)) {
          setSelectedProject(options[index]);
        }
      });
    }
  };

  const fetchAgencies = async () => {
    await fetchAllAgencies({
      callback: (data) => {
        setAgencyList(data);
        getSelectorOptions(data);
      },
    });
  };

  const fetchData = async () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    await fetchTable({
      url: `/agency/${selectedProject?.ID}/`,
      params: {
        limit: tableOption.currentRowsPerPage,
        offset: tableOption.offset,
        search: tableOption.searchValue,
        column: tableOption.sort.field,
        order: tableOption.sort.direction,
        ...dateParams,
      },
      callback: (data) => {
        setTableOption({
          ...tableOption,
          data: data?.Projects,
          isLoading: false,
          totalRows: data?.projectCount,
        });
        setAgencyDetails(data);
        setInitialProjects(data?.Projects);
      },
    });
  };

  const handleFailedRowEdit = () => {
    setTableOption({
      ...tableOption,
      data: initialProjects,
    });
  };

  const fetchBudgetAllocation = async () => {
    if (selectedProject?.ID !== 0) {
      await fetchProjectAllocation({
        url: `/agency/${selectedProject?.ID}/allocatedBudget`,
        callback: (data) => {
          const agencyProjs = data?.projects;

          const totalAgencyBudget =
            data?.totalBudget !== null ? data.totalBudget : 0;

          const amountSpent =
            data?.allocatedBudget !== null ? data?.allocatedBudget : 0;

          const remainingBudget =
            data?.remainingBalance !== null ? data?.remainingBalance : 0;
          let projs = [];

          setTotalBudget(totalAgencyBudget);
          setTotalAmountSpent(amountSpent);
          setRemainingBalance(remainingBudget);

          if (agencyProjs?.length !== 0) {
            projs = agencyProjs?.map((proj) => {
              return {
                id: proj?.id,
                name: proj?.name,
                value: proj?.allocatedBudget,
                budget:
                  proj.allocatedBudget !== 0
                    ? (proj.allocatedBudget / totalAgencyBudget) * 100
                    : 0,
              };
            });
          }

          projs.push({
            id: 0,
            name: "Remaining Balance",
            value: remainingBudget,
            budget:
              remainingBudget !== 0
                ? (remainingBudget / totalAgencyBudget) * 100
                : 0,
          });

          setProjects(projs);
        },
      });
    }
  };

  const updateProjectDetails = async (formData, projectId) => {
    await updateRow({
      url: `/project/${projectId}`,
      formData,
      callback: ({ isSuccess }) => {
        if (isSuccess) {
          setTimeout(() => {
            Toast({
              type: 1,
              content: "Successfully updated row",
            });
            fetchData();
            fetchBudgetAllocation();
          }, 1500);
        } else {
          handleFailedRowEdit();
        }
      },
    });
  };

  const handleSaveClicked = (row, index) => {
    setEditingRowId("");

    const projectId = row?.id;
    const initialData = initialProjects[index];

    const newData = {};

    if (!_.isEqual(row, initialData)) {
      // eslint-disable-next-line array-callback-return
      Object.keys(initialData)?.map((obj) => {
        if (initialData[obj] !== row[obj]) {
          newData[obj] = row[obj];
        }
      });

      updateProjectDetails(newData, projectId);
    }
  };

  const toggle = () => setIsBudgetEditing(!isBudgetEditing);

  const handleOverallBudgetChange = async (budget) => {
    await editTotalBudget({
      formData: {
        totalBudget: budget,
      },
      url: `/agency/${id}`,
      callback: (response) => {
        const status = response?.status;

        if (status) {
          Toast({
            type: 1,
            content: "Successfully updated total budget",
          });
          fetchBudgetAllocation();
        } else {
          setNewTotalBudget(totalBudget);
        }
      },
    });
  };

  const handleDeleteRow = (row) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to remove ${row?.name} from ${selectedProject?.Name}?`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteProject({
          param: row?.id,
          callback: ({ isSuccess }) => {
            if (isSuccess) {
              if (isSuccess) {
                Toast({
                  type: 1,
                  content: "Successfully deleted program.",
                });
                fetchData();
              } else {
                Toast({
                  type: 4,
                  content: "An error occured.",
                });
              }
            }
          },
        });
      }
    });
  };

  const newColumns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      cell: () => null,
    },
    {
      name: "",
      selector: (row) => row?.pendingCount,
      sortable: false,
      width: "50px",
      id: "badge",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ pendingCount, requestedCount }) => {
        let totalCount = 0;
        totalCount = pendingCount + requestedCount;

        return (
          <Badge
            className={`badge ${totalCount === 0 ? "is-hidden" : ""}`}
            pill
          >
            <div>{totalCount}</div>
          </Badge>
        );
      },
    },
    {
      name: "Program",
      selector: (row) => row?.name,
      identifier: "name",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) =>
        editingRowId !== index ? (
          <p className="mb-0">{row?.name}</p>
        ) : (
          <FormGenertor
            type="text"
            onChange={(e) => {
              const { value } = e.target;

              const newData = [...tableOption.data];

              newData[index] = {
                ...row,
                name: value,
              };

              setTableOption({
                ...tableOption,
                data: newData,
              });
            }}
            value={row?.name}
          />
        ),
    },
    {
      name: "Date",
      selector: (row) => row?.implemented,
      identifier: "implemented",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) =>
        editingRowId !== index ? (
          <p className="mb-0">
            {getFormattedDateString(row?.implemented, "MMM-yyyy")}
          </p>
        ) : (
          <FormGenertor
            type="date"
            value={row?.implemented}
            className="form-control"
            dateFormat="M-Y"
            onChange={(date) => {
              const newData = [...tableOption.data];

              newData[index] = {
                ...row,
                implemented: date[0],
              };

              setTableOption({
                ...tableOption,
                data: newData,
              });
            }}
          />
        ),
    },
    {
      name: "Budget",
      selector: (row) => row?.budgetAllocation,
      identifier: "budgetAllocation",
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <p className="mb-0">{`PHP ${Number(
          row?.budgetAllocation
        )?.toLocaleString("en-US")}`}</p>
      ),
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      grow: 2,
      sortable: false,
      width: "100px",
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, index) => {
        return (
          <>
            {editingRowId !== index && hasPermission("project-update") && (
              <>
                <Edit2
                  size={16}
                  id={`edit-row--${index}`}
                  onClick={() => {
                    setEditingRowId(index);
                  }}
                  className="mr-05"
                  color="#4252C9"
                />
                <UncontrolledTooltip
                  placement="auto"
                  target={`edit-row--${index}`}
                >
                  {"Edit"}
                </UncontrolledTooltip>

                <Eye
                  size={16}
                  color="#4252C9"
                  className="mr-05"
                  onClick={() =>
                    navigate(
                      `/projects/${selectedProject?.ID}/program/${row.id}`
                    )
                  }
                  id={`view-row--${index}`}
                />
                <UncontrolledTooltip
                  placement="right"
                  target={`view-row--${index}`}
                >
                  {"View"}
                </UncontrolledTooltip>
              </>
            )}
            {editingRowId !== index && hasPermission("project-delete") && (
              <>
                <Trash2
                  size={16}
                  color="#4252C9"
                  onClick={() => handleDeleteRow(row)}
                  id={`delete-row--${index}`}
                />
                <UncontrolledTooltip
                  placement="right"
                  target={`delete-row--${index}`}
                >
                  {"Delete"}
                </UncontrolledTooltip>
              </>
            )}
            {editingRowId === index && hasPermission("project-view") && (
              <>
                <FontAwesomeIcon
                  id={`save-row--${index}`}
                  size="xl"
                  className="mr-1"
                  color="#4252C9"
                  icon={faCircleCheck}
                  onClick={() => handleSaveClicked(row, index)}
                />

                <UncontrolledTooltip
                  placement="auto"
                  target={`save-row--${index}`}
                >
                  {"Save"}
                </UncontrolledTooltip>

                <FontAwesomeIcon
                  id={`cancel-row--${index}`}
                  size="xl"
                  color="#4252C9"
                  icon={faCircleXmark}
                  onClick={() => handleCancelClicked(index)}
                />
                <UncontrolledTooltip
                  placement="right"
                  target={`cancel-row--${index}`}
                >
                  {"Cancel"}
                </UncontrolledTooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  const hasProjectAccess = async (projId) => {
    await genericGet({
      url: `auth/has-access?project=${projId}`,
      callback: (response) => {
        setHasAccess(response.data);
      },
    });
  };

  useEffect(() => {
    hasProjectAccess(id);
  }, [id]);

  useEffect(() => {
    if (selectedProject !== null) {
      if (selectedProject?.value !== null) {
        fetchBudgetAllocation();
      }
    }
  }, [selectedProject]);

  useEffect(() => {
    fetchAgencies();
  }, []);

  useEffect(() => {
    if (selectedProject !== null) {
      if (selectedProject?.ID === 0) {
        // filter agencies who are under the singed in user
        setAgencyDetails({});

        setTableOption({
          ...tableOption,
          totalRows: agencyList?.length,
          data: agencyList,
          isLoading: false,
        });
        setColumns(defaultColumns);
      } else {
        fetchData();
        setColumns(newColumns);
      }
    }
  }, [
    selectedProject,
    tableOption.currentRowsPerPage,
    tableOption.offset,
    tableOption.searchValue,
    tableOption.sort.field,
    tableOption.sort.direction,
  ]);

  useEffect(() => {
    setColumns(newColumns);
  }, [editingRowId]);

  if (!hasAccess) return navigate("/404");

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      previousDateFrom.current = dateRange.dateFrom;

      return;
    }

    if (dateRange.dateFrom && dateRange.dateFrom !== previousDateFrom.current) {
      previousDateFrom.current = dateRange.dateFrom;

      setTimeout(() => {
        navigate(0);
      }, 1);
    }
  }, [dateRange.dateFrom, navigate]);

  return (
    <div className="p-0 m-0 project-details">
      <Row className="d-flex justify-content-end mt-5 mx-5">
        <Col
          xs="auto"
          className="custom-breadcrumb--comtainer"
        >
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "Programs",
                redirect: "/projects",
              },
              {
                name: agencyDetails?.name || "All Agencies",
                isActive: true,
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="p-0 mb-4 mx-5 d-flex align-items-center select-container">
        <Col>
          <Row className="d-flex align-items-center">
            <Col
              xs="auto"
              className="p-0 project-logo"
            >
              {/* agency logo here */}
              <ImgContainer
                src={agencyDetails?.photo}
                height="110px"
              />
            </Col>
            <Col
              xs="12"
              sm="8"
              md="7"
              lg="7"
              xl="8"
            >
              {/* agency select dropwdown here */}
              <CustomSelect
                isLoading={tableOption.isLoading}
                isDisabled={tableOption.isLoading}
                onChange={(option) => {
                  setSelectedProject(option);
                }}
                value={selectedProject}
                options={selectorOptions}
                styles={customStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                // eslint-disable-next-line consistent-return
                filterOption={(option, searchValue) => {
                  if (
                    option.data.acronym
                      .toLowerCase()
                      .includes(searchValue?.toLowerCase())
                  ) {
                    return option;
                  }
                }}
                // menuIsOpen
              />
            </Col>
          </Row>
        </Col>
        <Col
          lg="4"
          className="d-flex align-items-center justify-content-end d-none d-lg-flex"
        >
          {/* searchbar here */}
          {selectedProject?.ID !== 0 && (
            <div className="position-relative">
              <SearchBarComponent
                onChangeSearch={onChangeSearch}
                isDisabled={tableOption?.isLoading}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row className="p-0 d-flex mx-5 row-section">
        <Col xs="auto" />
        <Col>
          {selectedProject?.ID !== 0 && (
            <div className="budget-container">
              <div className="d-flex justify-content-between align-items-center p-0">
                <Col className="pl-0">
                  <h5 className="font-weight-bold">{"Budget Allocation"}</h5>
                </Col>
                <Col>
                  <div className="budget-allocation">
                    <div className="d-flex flex-row">
                      {!isBudgetEditing && (
                        <Edit2
                          size={16}
                          onClick={() => toggle()}
                          className="mr-1"
                          color="#4252C9"
                        />
                      )}

                      <h6>{"Total Budget"}</h6>
                    </div>
                    <h6 className="font-weight-bold">
                      {getFormattedNumber(totalBudget || "0.00")}
                    </h6>
                  </div>
                  <BudgetComponent
                    value={totalBudget || 0}
                    newValue={newTotalBudget}
                    isOpen={isBudgetEditing}
                    handleToggle={toggle}
                    totalAmountSpent={totalAmountSpent}
                    handleOnChange={handleOverallBudgetChange}
                  />
                </Col>
              </div>

              <div className="p-0 mb-3 mt-2">
                <StackedProgressBar data={projects} />
              </div>

              <div className="p-0">
                <div className="pl-0 d-flex flex-row">
                  <h6>{"Amount Spent: \r"}</h6>
                  <h6 className="font-weight-bold ml-25">
                    {getFormattedNumber(totalAmountSpent || "0.00")}
                  </h6>
                </div>
              </div>
              <div className="p-0">
                <div className="pl-0 d-flex flex-row">
                  <h6>{"Remaining Balance: \r"}</h6>
                  <h6 className="font-weight-bold ml-25">
                    {getFormattedNumber(remainingBalance || "0.00")}
                  </h6>
                </div>
              </div>
            </div>
          )}
          {/* table here */}
          <Card className="mb-5">
            <CustomDataTable
              columns={columns}
              data={tableOption.data}
              noHeader
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onSort={onSort}
              paginationPerPage={tableOption.currentRowsPerPage}
              paginationServer={selectedProject?.ID !== 0}
              paginationTotalRows={tableOption.totalRows}
              progressPending={tableOption.isLoading}
              sortServer
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Main;
