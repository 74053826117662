import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import {
  getFormattedContactNumber,
  getFormattedOptions,
  getModifiedObject,
  Toast,
} from "utility/helpers";
import {
  addCongressman,
  editCongDetails,
  fetchDistricts,
} from "services/services.congressman";
import { fetchPartylists } from "services/services.affiliation";
import { APP_URL } from "config";
import CongressmanForm from "components/forms/Congressman";
import { uploadFile } from "services/services.general";

import ImgContainer from "components/common/img";
import defaultUserImage from "assets/img/default_user.png";
import "./styles.scss";

const EditCongressman = () => {
  const location = useLocation();
  const cong = location.state?.cong || null;

  const [districtError, setDistrictError] = useState("");
  const [partylistError, setPartylistError] = useState("");
  const [districtOptions, setdistrictOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [partylists, setPartylists] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedPartylist, setSelectedPartylist] = useState(null);

  const [formFields, setFormFields] = useState({
    lastname: "",
    firstname: "",
    middleInitial: "",
    mobile: "",
    district: "",
    party: "",
    cos: "",
    cosNumber: "",
    photo: "",
    photoName: "",
    cosFirstName: "",
    cosLastName: "",
  });

  const navigate = useNavigate();

  const validate = () => {
    if (!selectedDistrict) {
      setDistrictError("Required");

      return "Required district";
    }
    setDistrictError("");

    if (!selectedPartylist) {
      setPartylistError("Required");

      return "Required party";
    }
    setPartylistError("");

    if (formFields.photo === "") {
      return "Required photo.";
    }

    if (
      formFields.cosNumber?.length > 2 &&
      !/^(639)\d{9}$/.test(formFields?.cosNumber)
    ) {
      return "Invalid cos contact number.";
    }

    return true;
  };

  const setData = () => {
    const selected = districtOptions.find(
      (district) => district.id === cong.DistrictId
    );

    if (selected) setSelectedDistrict(selected);

    const option = partylists.find(
      (partylist) => partylist.id === cong.PartyId
    );

    if (option) setSelectedPartylist(option);

    setIsLoading(false);
  };

  const setCongDetails = () => {
    setFormFields({
      ...formFields,
      lastname: cong.lastName,
      firstname: cong.firstName,
      middleInitial: cong.middleInitial,
      mobile: getFormattedContactNumber(cong.contact),
      district: cong.DistrictId,
      party: cong.PartyId,
      cos: cong.cosName,
      cosNumber: getFormattedContactNumber(cong.cosContact),
      photo: cong.photo,
      cosFirstName: cong.cosFirstName || cong.cosName,
      cosLastName: cong.cosLastName,
    });

    if (cong.photo) {
      const imgContainer = document.querySelector(".cong-img");
      imgContainer.src = cong.photo;
    }
  };

  const sendData = async (formData) => {
    setIsLoading(true);

    if (cong) {
      await editCongDetails({
        url: `${APP_URL}/congressman/${cong.id}`,
        formData,
        callback: ({ isSuccess, msg }) => {
          setIsLoading(false);

          if (isSuccess) {
            navigate("/directory");

            Toast({
              type: 1,
              content: "Successfully edited cong details",
            });
          } else {
            Toast({
              type: 4,
              content: msg,
            });
          }
        },
      });
    } else {
      addCongressman({
        formData,
        callback: ({ isSuccess, msg }) => {
          setIsLoading(false);

          if (isSuccess) {
            navigate("/directory");

            Toast({
              type: 1,
              content: "Successfully added congressman.",
            });
          } else {
            Toast({
              type: 4,
              content: msg,
            });
          }
        },
      });
    }
  };

  const uploadImage = async () => {
    let fileName = "";

    await uploadFile({
      file: formFields.photo,
      fileName: formFields.photoName,
      onProgress: () => {
        //
      },
      callback: (data) => {
        if (data) fileName = data;
      },
    });

    return fileName;
  };

  const handleOnSumbmit = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const data = {
        // eslint-disable-next-line max-len
        fullName: `HON. ${formFields.firstname} ${formFields.middleInitial} ${formFields.lastname}`,
        middleInitial: formFields.middleInitial,
        firstName: formFields.firstname,
        lastName: formFields.lastname,
        contact: formFields.mobile?.slice(2),
        DistrictId: selectedDistrict.value,
        PartyId: selectedPartylist.value,
        cosName: `${formFields.cosFirstName} ${formFields.cosLastName}`,
        cosFirstName: formFields.cosFirstName,
        cosLastName: formFields.cosLastName,
      };

      if (formFields.cosNumber) {
        data.cosContact = formFields.cosNumber?.slice(2);
      }

      let initialData = {};

      if (cong) {
        initialData = {
          middleInitial: cong.middleInitial,
          firstName: cong.firstName,
          lastName: cong.lastName,
          fullName: cong.fullName,
          contact: cong.contact,
          DistrictId: cong.DistrictId,
          PartyId: cong.PartyId,
          cosName: cong.cosName,
          cosContact: cong.cosContact,
          cosFirstName: cong.cosFirstName,
          cosLastName: cong.cosLastName,
          photo: cong.photo,
        };
      }

      setIsLoading(true);

      if (typeof formFields.photo !== "string") {
        const fileName = await uploadImage();
        data.photo = fileName;
      }

      const formData = cong ? await getModifiedObject(initialData, data) : data;

      await sendData(formData);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const fetchData = async () => {
    await fetchDistricts({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);

        setdistrictOptions(newData);
      },
    });

    await fetchPartylists({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);

        setPartylists(newData);
      },
    });

    setIsLoading(false);
  };

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    const fileTye = file.type.split("/")[0];

    if (fileTye === "image") {
      reader.onloadend = () => {
        const imgContainer = document.querySelector(".cong-img");

        const fileName = cong
          ? `${
              cong.id
            }-${formFields.lastname.toLocaleLowerCase()}-${Date.now()}.${
              file.type.split("/")[1]
            }`
          : `${formFields.lastname.toLocaleLowerCase()}-${Date.now()}.${
              file.type.split("/")[1]
            }`;

        setFormFields({
          ...formFields,
          photo: file,
          photoName: fileName,
        });

        imgContainer.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      Toast({
        type: 4,
        content: "File uploaded must be an image.",
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    //  fetch all district, partylist, region
    fetchData();
  }, []);

  useEffect(() => {
    if (cong !== null && partylists.length && districtOptions.length) {
      setData();
      setCongDetails();
    }
  }, [districtOptions, partylists, cong]);

  return (
    <div className="add-project-container directory">
      <Col className="header">
        <Row className="d-flex justify-content-end w-100 m-0">
          <Col
            xs="6"
            className="d-flex mt-3 justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Directory",
                  redirect: "/directory",
                },
                {
                  name: !cong ? "Add Congressman" : "Congressman Details",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header-actions h-75 d-flex m-0 mt-4 justify-content-between align-items-end">
          <h2 className="text-danger">
            {!cong ? "Add Congressman" : "Congressman Details"}
          </h2>
        </Row>
        <div className="form-section">
          <Row>
            <Col
              xs="12"
              sm="6"
              lg="4"
              className="col--img-container"
            >
              <div className="img-container">
                <ImgContainer
                  alt="cong-img"
                  className="cong-img"
                  src={defaultUserImage}
                  width="196px"
                  height="196px"
                />

                <label
                  htmlFor="file-upload"
                  className="upload-btn"
                >
                  {"Upload Photo"}
                  <input
                    id="file-upload"
                    className="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </label>
              </div>
            </Col>
            <Col
              xs="12"
              sm="6"
              lg="8"
            >
              <CongressmanForm
                handleOnSumbmit={handleOnSumbmit}
                formFields={formFields}
                setFormFields={setFormFields}
                districtOptions={districtOptions}
                selectedDistrict={selectedDistrict}
                setSelectedDistrict={setSelectedDistrict}
                districtError={districtError}
                partylists={partylists}
                selectedPartylist={selectedPartylist}
                setSelectedPartylist={setSelectedPartylist}
                partylistError={partylistError}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default EditCongressman;
