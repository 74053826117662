import React from "react";
import BreadCrumbComponent from "components/customs/breadCrumb";
import RecipientForm from "components/forms/Recipient";
import {
  BreadCrumbSection,
  FormSection,
  HeaderTextSection,
  MainContainer,
  Container,
} from "./style";

const RecipientDetails = () => {
  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "Recipient Details",
                isActive: true,
              },
            ]}
          />
        </BreadCrumbSection>

        <HeaderTextSection>
          <h2 className="text-danger">{"Recipient Details"}</h2>
        </HeaderTextSection>

        <FormSection>
          <RecipientForm />
        </FormSection>
      </Container>
    </MainContainer>
  );
};

export default RecipientDetails;
