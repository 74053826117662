import React, { useEffect, useState } from "react";
import { Toast, getFormattedOptions } from "utility/helpers";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Settings } from "react-feather";
import PropTypes from "prop-types";
import CustomSelect from "components/customs/dropdown";
import { DropdownIndicator, addProjectDropdownStyles } from "utility/constants";
import { fetchAllAgencyProjects } from "services/services.projects";
import {
  fetchUtilizationSetting,
  updateUtilizationSetting,
} from "services/services.utilization";
import { NumericFormat } from "react-number-format";
import DatePicker from "react-datepicker";

import "./style.scss";
import { StyledRed, BtnSave } from "./styled";

const SettingsModal = ({ isOpen, handleClose, agency, handleFetchTable }) => {
  const [projectOptions, setProjectOptions] = useState();
  const [agencyOptions, setAgencyOptions] = useState();
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [utilPercent, setUtilPercent] = useState();
  const [startDate, setStartDate] = useState();
  const [settingsId, setSettingsId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isAgencyChanged, setIsAgencyChanged] = useState(false);

  const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

  const fetchSettings = async (proj) => {
    await fetchUtilizationSetting({
      url: "/utilizationsetting",
      callback: (data) => {
        const settings = data.find(
          (setting) => setting.ProjectId === proj.value
        );
        setUtilPercent(settings.utilizationPercent);
        setStartDate(new Date(settings.reportingStartDate));
        setSettingsId(settings.id);
      },
    });
  };

  const validate = () => {
    if (utilPercent === "") {
      return "Utility Percentage cannot be empty";
    }

    if (!startDate) {
      return "Start Date cannot be empty";
    }

    setIsLoading(false);

    return true;
  };

  const updateSettings = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      setIsLoading(true);

      const formData = {
        utilizationPercent: utilPercent,
        reportingStartDate: startDate,
      };

      await updateUtilizationSetting({
        url: `/utilizationsetting/${settingsId}`,
        formData,
        callback: ({ isSuccess }) => {
          setIsLoading(false);

          if (isSuccess) {
            setTimeout(() => {
              Toast({
                type: 1,
                content: "Successfully updated utilization setting.",
              });
              handleClose();
            }, 1500);
          } else {
            Toast({
              type: 4,
              content: "An error occured.",
            });
          }
          handleFetchTable();
        },
      });
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const fetchProjects = async () => {
    await fetchAllAgencyProjects({
      url: `/agency/${selectedAgency?.value}`,
      callback: (data) => {
        let projects = data?.Projects;

        if (selectedAgency?.value === 14) {
          projects = data?.Projects.filter(
            (project) => project.name === "Infrastructure"
          );
        }

        const options = getFormattedOptions("name", projects);
        if (selectedAgency?.value === 14) setSelectedProject(options[0]);
        setProjectOptions(options);
      },
    });
  };

  useEffect(() => {
    if (agency.length !== 0) {
      setAgencyOptions(agency);
    }
  }, [agency]);

  useEffect(() => {
    if (agencyOptions) {
      setSelectedAgency(agencyOptions[0]);
      fetchProjects();
    }
  }, [agencyOptions]);

  useEffect(() => {
    if (selectedProject && !isAgencyChanged) fetchSettings(selectedProject);
  }, [selectedProject]);

  useEffect(() => {
    if (selectedProject && isAgencyChanged) setSelectedProject([]);
    fetchProjects();
  }, [selectedAgency]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        handleClose();
      }}
      className="comments-modal"
    >
      <ModalHeader
        toggle={() => {
          handleClose();
        }}
      >
        <div className="d-flex flex-row align-items-center">
          <Settings /> <p className="comments-text">{"Settings"}</p>
        </div>
      </ModalHeader>
      <Row className="d-flex w-100 align-items-center m-0">
        <Col
          xs="12"
          className="header-left"
        />
      </Row>

      <ModalBody className="mx-2 mt-4">
        <div className="form-section">
          <div className="row-section">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Agency"}</h5>
              <p>{"Provide the Agency"}</p>
            </Col>
            <div className="form-col-right">
              <CustomSelect
                placeholder="Agency"
                styles={addProjectDropdownStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={agencyOptions}
                value={selectedAgency}
                onChange={(option) => {
                  setSelectedAgency(option);
                  setIsAgencyChanged(true);

                  setStartDate(null);
                  setUtilPercent("");
                }}
                // eslint-disable-next-line consistent-return
                filterOption={(option, searchValue) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchValue?.toLowerCase()) ||
                    option?.data?.acronym
                      ?.toLowerCase()
                      ?.includes(searchValue?.toLowerCase())
                  ) {
                    return option;
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="row-section">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Program"}</h5>
              <p>{"Provide the Program"}</p>
            </Col>
            <div className="form-col-right">
              <CustomSelect
                className="w-100"
                placeholder="Program"
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                styles={addProjectDropdownStyles}
                options={projectOptions}
                value={selectedProject}
                onChange={(option) => {
                  setSelectedProject(option);
                  setIsAgencyChanged(false);
                }}
                isDisabled={
                  selectedAgency === null || selectedAgency?.value === 14
                }
              />
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="row-section">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Start Date"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the start date"}</p>
            </Col>
            <div className="form-col-right">
              <DatePicker
                selected={startDate}
                placeholderText="Start Date"
                className="form-control input-date util-datepicker"
                dateFormat="MMM-dd-yyyy"
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="row-section">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Utilization Percentage"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide utilitization percentage"}</p>
            </Col>
            <div className="form-col-right">
              <NumericFormat
                className="input-date form-control util-percentage"
                placeholder="%"
                value={utilPercent}
                onValueChange={(event) => {
                  const { value } = event;

                  setUtilPercent(value);
                }}
              />
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <div>
          <BtnSave
            onClick={() => updateSettings()}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                animation="border"
                color="light"
                size="sm"
              />
            ) : (
              "Save"
            )}
          </BtnSave>
        </div>
      </ModalFooter>
    </Modal>
  );
};

SettingsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  agency: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  handleFetchTable: PropTypes.func,
};

SettingsModal.defaultProps = {
  agency: [],
};

export default SettingsModal;
