import React from "react";
import Navbar from "./navbar";
import { LayoutBackground } from "./style";

const Layout2 = ({ children }) => {
  return (
    <LayoutBackground>
      <Navbar />
      {children}
    </LayoutBackground>
  );
};

export default Layout2;
