import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import {
  getFormattedContactNumber,
  getFormattedOptions,
  getModifiedObject,
  Toast,
} from "utility/helpers";
import { fetchAllRegions } from "services/services.congressman";
import { fetchPartylists } from "services/services.affiliation";
import MayorForm from "components/forms/Mayor";
import { genericPost, genericPut, uploadFile } from "services/services.general";
import { fetchCandidates } from "services/services.official";

import ImgContainer from "components/common/img";
import defaultUserImage from "assets/img/default_user.png";
import "../styles.scss";

const EditMayor = () => {
  const location = useLocation();
  const mayor = location.state?.mayor || null;

  const [isLoading, setIsLoading] = useState(false);
  const [partylists, setPartylists] = useState([]);
  const [selectedPartylist, setSelectedPartylist] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [regionOptions, setRegionOptions] = useState([]);

  const [mayorOptions, setMayorOptions] = useState([]);
  const [selectedMayor, setSelectedMayor] = useState(null);

  const [selectedRegion, setSelectedRegion] = useState(null);

  const [formFields, setFormFields] = useState({
    lastName: "",
    firstName: "",
    fullName: "",
    omegaId: "",
    region: "",
    contact: "",
    province: "",
    municipality: "",
    party: "",
    cos: "",
    cosNumber: "",
    photo: "",
    photoName: "",
    cosFirstName: "",
    cosLastName: "",
  });

  const navigate = useNavigate();

  const validate = () => {
    if (
      formFields.cosNumber?.length > 2 &&
      !/^(639)\d{9}$/.test(formFields?.cosNumber)
    ) {
      return "Invalid cos contact number.";
    }

    if (
      formFields.contact?.length > 0 &&
      (formFields.contact?.length <= 2 ||
        !/^(639)\d{9}$/.test(formFields.contact))
    ) {
      return "Invalid Mayor contact number.";
    }

    if (formFields.fullName === "") {
      return "Required Name.";
    }

    if (formFields.region === "") {
      return "Required Region.";
    }

    if (formFields.province === "") {
      return "Required Province.";
    }

    return true;
  };

  const fetchMayors = async () => {
    await fetchCandidates({
      url: "/election/nominations/?position_id=5&is_winner=true&page_size=1700",
      callback: (data) => {
        if (data.results) {
          const rows = data.results;

          const mayors = rows.map((myr) => ({
            ...myr,
            value: myr.candidate.id,
            label: `${myr.candidate.first_name} ${myr.candidate.last_name}`,
          }));

          if (mayor) {
            const selectedMyr = mayors.find(
              (myr) => myr.candidate.id === mayor.omegaId
            );
            setSelectedMayor(selectedMyr);
          }

          setMayorOptions(mayors);
        }
      },
    });
  };

  const fetchRegion = async () => {
    await fetchAllRegions({
      callback: (data) => {
        if (data.length) {
          const options = data
            .filter((region) => region.name !== "Partylist")
            .map(({ acronym, id, omegaId }) => ({
              omegaId,
              label: acronym,
              value: id,
            }));

          setRegionOptions(options);

          const region = options?.find(
            (option) => option.value === mayor?.regionId
          );

          if (region) {
            setSelectedRegion(region);
          } else {
            setFormFields({
              ...formFields,
              region: null,
            });
          }
        }
      },
    });
  };

  const setData = () => {
    const option = partylists.find(
      (partylist) => partylist.id === mayor.partyId
    );

    if (option) setSelectedPartylist(option);

    setIsLoading(false);
  };

  const setMayorDetails = () => {
    const updatedFields = {
      ...formFields,
      lastName: mayor.lastName,
      firstName: mayor.firstName,
      fullName: mayor.fullName,
      contact: getFormattedContactNumber(mayor.contact),
      party: mayor.partyId,
      region: selectedRegion,
      provinceId: mayor.provinceId,
      municipalityId: mayor.municipalityId,
      cos: mayor.cosName,
      cosNumber: getFormattedContactNumber(mayor.cosContact),
      photo: mayor.photo || "",
      cosFirstName: mayor.cosFirstName || mayor.cosName,
      cosLastName: mayor.cosLastName,
    };

    if (mayor.photo) {
      const imgContainer = document.querySelector(".mayor-img");
      imgContainer.src = mayor.photo;
    }

    setFormFields(updatedFields);
  };

  const sendData = async (formData) => {
    setIsLoading(true);

    if (mayor) {
      await genericPut({
        url: `/official/${mayor.id}`,
        formData,
        callback: ({ isSuccess, msg }) => {
          setIsLoading(false);

          if (isSuccess) {
            navigate("/directory", {
              state: { view: "mayor" },
            });

            Toast({
              type: 1,
              content: "Successfully edited mayor details",
            });
          } else {
            Toast({
              type: 4,
              content: msg,
            });
          }
        },
      });
    } else {
      await genericPost({
        url: "/official",
        formData,
        callback: ({ isSuccess, msg }) => {
          setIsLoading(false);

          if (isSuccess) {
            navigate("/directory", {
              state: { view: "mayor" },
            });

            Toast({
              type: 1,
              content: "Successfully added mayor.",
            });
          } else {
            Toast({
              type: 4,
              content: msg,
            });
          }
        },
      });
    }
  };

  const uploadImage = async () => {
    let fileName = "";

    await uploadFile({
      file: formFields.photo,
      fileName: formFields.photoName,
      callback: (data) => {
        if (data) fileName = data;
      },
    });

    return fileName;
  };

  const handleOnSumbmit = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const data = {
        // eslint-disable-next-line max-len
        fullName: `MAYOR ${formFields.firstName} ${formFields.lastName}`,
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        contact: formFields.contact?.slice(2),
        regionId: formFields.region.value,
        provinceId: formFields.province.value,
        municipalityId: formFields.municipality.value,
        cosName: `${formFields.cosFirstName} ${formFields.cosLastName}`,
        cosFirstName: formFields.cosFirstName,
        cosLastName: formFields.cosLastName,
        omegaId: formFields.omegaId,
        position: "Mayor",
        ...(formFields.cosNumber ? { cosContact: formFields.cosNumber } : {}),
        ...(selectedPartylist ? { partyId: selectedPartylist.value } : {}),
      };

      if (formFields.cosNumber) {
        data.cosContact = formFields.cosNumber?.slice(2);
      }

      let initialData = {};

      if (mayor) {
        initialData = {
          firstName: mayor.firstName,
          lastName: mayor.lastName,
          fullName: mayor.fullName,
          contact: mayor.contact,
          regionId: mayor.regionId,
          provinceId: mayor.provinceId,
          municipalityId: mayor.municipalityId,
          partyId: mayor.partyId,
          cosName: mayor.cosName,
          cosContact: mayor.cosContact,
          cosFirstName: mayor.cosFirstName,
          cosLastName: mayor.cosLastName,
          photo: mayor.photo,
          omegaId: formFields.omegaId,
          position: "Mayor",
        };
      }

      setIsLoading(true);

      if (typeof formFields.photo !== "string") {
        const fileName = await uploadImage();
        data.photo = fileName;
      }

      const formData = mayor
        ? await getModifiedObject(initialData, data)
        : data;

      await sendData(formData);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const fetchData = async () => {
    await fetchPartylists({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);

        setPartylists(newData);
      },
    });

    setIsLoading(false);
  };

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    const fileTye = file.type.split("/")[0];

    if (fileTye === "image") {
      reader.onloadend = () => {
        const imgContainer = document.querySelector(".mayor-img");

        const fileName = mayor
          ? `${
              mayor.id
            }-${formFields.lastName.toLocaleLowerCase()}-${Date.now()}.${
              file.type.split("/")[1]
            }`
          : `${formFields.lastName.toLocaleLowerCase()}-${Date.now()}.${
              file.type.split("/")[1]
            }`;

        setFormFields({
          ...formFields,
          photo: file,
          photoName: fileName,
        });

        imgContainer.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      Toast({
        type: 4,
        content: "File uploaded must be an image.",
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    //  fetch all district, partylist, region
    fetchRegion();
    fetchData();
    fetchMayors();
  }, []);

  useEffect(() => {
    if (mayor !== null) {
      setData();
      setMayorDetails();
      setIsEdit(true);
    }
  }, [partylists, mayor]);

  return (
    <div className="add-project-container directory">
      <Col className="header">
        <Row className="d-flex justify-content-end w-100 m-0">
          <Col
            xs="6"
            className="d-flex mt-3 justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Directory",
                  redirect: "/directory",
                },
                {
                  name: !mayor ? "Add Mayor" : "Mayor Details",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header-actions h-75 d-flex m-0 mt-4 justify-content-between align-items-end">
          <h2 className="text-danger">
            {!mayor ? "Add Mayor" : "Mayor Details"}
          </h2>
        </Row>
        <div className="form-section">
          <Row>
            <Col
              xs="12"
              sm="6"
              lg="4"
              className="col--img-container"
            >
              <div className="img-container">
                <ImgContainer
                  alt="mayor-img"
                  className="mayor-img"
                  src={defaultUserImage}
                  width="196px"
                  height="196px"
                />

                <label
                  htmlFor="file-upload"
                  className="upload-btn"
                >
                  {"Upload Photo"}
                  <input
                    id="file-upload"
                    className="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </label>
              </div>
            </Col>
            <Col
              xs="12"
              sm="6"
              lg="8"
            >
              <MayorForm
                handleOnSumbmit={handleOnSumbmit}
                formFields={formFields}
                setFormFields={setFormFields}
                mayorOptions={mayorOptions}
                selectedMayor={selectedMayor}
                setSelectedMayor={setSelectedMayor}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                regionOptions={regionOptions}
                partylists={partylists}
                selectedPartylist={selectedPartylist}
                setSelectedPartylist={setSelectedPartylist}
                isLoading={isLoading}
                isEdit={isEdit}
                isMayor
              />
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default EditMayor;
