import React, { memo } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import PropTypes from "prop-types";

const CommonModal = ({
  isOpen,
  handleClose,
  className = "",
  title,
  renderBody,
  modalSize = "md",
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      centered
      className={className}
      size={modalSize}
    >
      {title && <ModalHeader toggle={handleClose}>{title}</ModalHeader>}

      <ModalBody>{renderBody}</ModalBody>
    </Modal>
  );
};

CommonModal.prototype = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  modalSize: PropTypes.string,
  renderBody: PropTypes.element,
};

export default memo(CommonModal);
