import React from "react";
import PropTypes from "prop-types";
import { Search } from "react-feather";
import { Input } from "reactstrap";

import "./style.scss";

const SearchBarComponent = ({ onChangeSearch, isDisabled = false }) => {
  return (
    <div className="custom-searchbar-container position-relative has-icon-left mb-1">
      <Input
        onChange={onChangeSearch}
        disabled={isDisabled}
        placeholder="Search"
      />
      <div className="form-control-position">
        <Search
          size="20"
          color="#6771BB"
        />
      </div>
    </div>
  );
};

export default SearchBarComponent;

SearchBarComponent.propTypes = {
  onChangeSearch: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
