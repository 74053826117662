import React from "react";
import Layout3 from "components/layout/Layout3";
import Senator from "./Senator";

const SenatorDetails = (props) => {
  return (
    <Layout3>
      <Senator {...props} />
    </Layout3>
  );
};

export default SenatorDetails;
