import React from "react";
import { Input } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";

import "flatpickr/dist/flatpickr.min.css";

const FormGenertor = ({
  type,
  placeholder = "",
  isDisabled = false,
  onChange,
  className = "",
  value,
  dateFormat = null,
  readOnly = false,
  defaultValue = "",
  ...rest
}) => {
  let formField = "";

  switch (type) {
    case "text":
      formField = (
        <Input
          type="text"
          readOnly={readOnly}
          placeholder={placeholder}
          disabled={isDisabled}
          onChange={onChange}
          className={className}
          value={value}
          autoComplete="off"
          {...rest}
        />
      );
      break;

    case "number":
      formField = (
        <NumericFormat
          className={className}
          defaultValue={defaultValue}
          value={value}
          readOnly={readOnly}
          decimalSeparator="."
          thousandSeparator=","
          onValueChange={onChange}
          placeholder={placeholder}
          disabled={isDisabled}
        />
      );
      break;

    case "date":
      formField = (
        <Flatpickr
          value={value}
          readOnly={readOnly}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={className}
          options={{
            enableTime: false,
            dateFormat,
          }}
          onChange={onChange}
        />
      );
      break;

    case "mobile":
      formField = (
        <Input
          value={value}
          type="text"
          className={className}
          placeholder={placeholder}
          disabled={isDisabled}
          onKeyDown={(event) => {
            if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
              event.preventDefault();
            }
          }}
          // eslint-disable-next-line consistent-return
          onChange={(val) => {
            const v = val.target.value;

            if (v.startsWith("0")) return onChange(v.replace(/^0/, ""));

            if (v === "") {
              return onChange("63");
            }

            if (v[2] !== "9") {
              return onChange(`63${v.substring(3)}`);
            }

            return onChange(v);
          }}
          autoComplete="off"
          maxLength={12}
          minLength={12}
          {...rest}
        />
      );
      break;

    case "textarea":
      formField = (
        <textarea
          readOnly={readOnly}
          placeholder={placeholder}
          disabled={isDisabled}
          onChange={onChange}
          className={className}
          value={value}
          autoComplete="off"
          {...rest}
        />
      );
      break;

    default:
      break;
  }

  return formField;
};

FormGenertor.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dateFormat: PropTypes.string,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
};

export default FormGenertor;
