import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pendingProjects: null,
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setPendingProjects: (state, action) => {
      return { ...state, pendingProjects: action.payload };
    },
  },
});

export const { setPendingProjects } = projectSlice.actions;

export default projectSlice.reducer;
