import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import BreadCrumbComponent from "components/customs/breadCrumb";
import { useParams } from "react-router-dom";
import DistrictCard from "components/customs/districtCard";
import {
  fetchAllRegions,
  fetchRegionalCongressman,
} from "services/services.congressman";

const Main = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [regionDetails, setRegionDetails] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const { id } = useParams();

  const getFormattedData = () => {
    let resultData = [];

    const groupByCategory = data.reduce((group, datum) => {
      const { name } = datum.District.Province;
      // eslint-disable-next-line no-param-reassign
      group[name] = group[name] ?? [];
      group[name].push(datum);

      return group;
    }, {});

    const getModifiedArray = (list) => {
      let finalArr = [];

      if (list?.length === 0) return finalArr;

      finalArr = list?.map((l) => {
        const matches =
          l?.District?.name !== null && l?.District?.name.match(/(\d+)/);

        return {
          ...l,
          District: {
            ...l?.District,
            districtNumber:
              matches !== null && matches?.length !== 0
                ? Number(matches[0])
                : null,
          },
          img: l?.photo,
          link: `/representatives/${id}/cong/${l?.id}`,
        };
      });

      finalArr.sort((a, b) =>
        a?.District?.districtNumber > b?.District?.districtNumber ? 1 : -1
      );

      return finalArr;
    };

    const groupArray = Object.entries(groupByCategory);

    resultData =
      groupArray &&
      groupArray?.length !== 0 &&
      groupArray?.map((arr) => {
        // eslint-disable-next-line no-param-reassign
        return [arr[0], (arr[1] = getModifiedArray(arr[1]))];
      });

    return resultData;
  };

  const fetchData = async () => {
    await fetchRegionalCongressman({
      params: id,
      callback: (response) => {
        setData(response);
      },
    });
  };

  const fetchRegions = async () => {
    await fetchAllRegions({
      callback: (response) => {
        setRegionList(response);
      },
    });
  };

  const getModifiedData = () => {
    return (
      data?.length !== 0 &&
      data?.map((datum) => {
        return {
          ...datum,
          img: datum.photo,
          link: `/representatives/${id}/cong/${datum?.id}`,
        };
      })
    );
  };

  useEffect(() => {
    if (data?.length !== 0) {
      // if region is partylist
      if (id === "18") {
        const result = getModifiedData();
        setFilteredData(result);
      } else {
        const result = getFormattedData();
        setFilteredData(result);
      }
    }
  }, [data]);

  useEffect(() => {
    if (regionList?.length !== 0) {
      // eslint-disable-next-line array-callback-return
      regionList?.map((op, index) => {
        if (op?.id === Number(id)) {
          setRegionDetails(regionList[index]);
        }
      });
    }
  }, [regionList]);

  useEffect(() => {
    fetchData();
    fetchRegions();
  }, []);

  return (
    <div className="m-0 p-0 district-list">
      <Row className="d-flex justify-content-between mx-5 mb-4 mt-5 header-container">
        <Col className="d-flex flex-column">
          <h3>{"District Representatives"}</h3>
          <span className="text-muted">{`${regionDetails?.acronym} - ${regionDetails?.name}`}</span>
        </Col>
        <Col className="custom-breadcrumb--comtainer d-flex justify-content-end">
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "House Members",
                redirect: "/representatives",
              },
              {
                name: regionDetails?.acronym,
                isActive: true,
              },
            ]}
          />
        </Col>
      </Row>

      <Row className="mx-5 d-flex mt-5 row-section">
        {id === "18"
          ? filteredData?.length !== 0 &&
            filteredData?.map((datum) => {
              return (
                <Col
                  key={datum?.id}
                  xs="auto"
                  className="my-4"
                >
                  <DistrictCard {...datum} />
                </Col>
              );
            })
          : filteredData?.length !== 0 &&
            filteredData?.map((datum) => {
              return (
                <Row
                  key={datum?.id}
                  className="mb-5 d-flex justify-content-start"
                >
                  <h5 className="text-bold">{datum[0]}</h5>
                  {datum[1]?.map((list) => {
                    return (
                      <Col
                        key={list?.id}
                        xs="auto"
                        className="my-3"
                      >
                        <DistrictCard {...list} />
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
      </Row>
    </div>
  );
};

export default Main;
