import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import {
  getFormattedContactNumber,
  getModifiedObject,
  Toast,
} from "utility/helpers";
import OthersForm from "components/forms/Others";
import { genericPost, genericPut, uploadFile } from "services/services.general";

import ImgContainer from "components/common/img";
import defaultUserImage from "assets/img/default_user.png";
import "../styles.scss";

const EditOthers = () => {
  const location = useLocation();
  const others = location.state?.others || null;

  const [isLoading, setIsLoading] = useState(false);

  const [formFields, setFormFields] = useState({
    lastName: "",
    firstName: "",
    fullName: "",
    contact: "",
    photo: "",
    photoName: "",
  });

  const navigate = useNavigate();

  const validate = () => {
    if (
      formFields.contact?.length > 0 &&
      (formFields.contact?.length <= 2 ||
        !/^(639)\d{9}$/.test(formFields.contact))
    ) {
      return "Invalid Others contact number.";
    }

    if (formFields.lastName === "") {
      return "Required Last Name.";
    }

    if (formFields.firstName === "") {
      return "Required First Name.";
    }

    return true;
  };

  const setOthersDetails = () => {
    const updatedFields = {
      ...formFields,
      lastName: others.lastName,
      firstName: others.firstName,
      fullName: others.fullName,
      contact: getFormattedContactNumber(others.contact),
      photo: others.photo || "",
    };

    if (others.photo) {
      const imgContainer = document.querySelector(".others-img");
      imgContainer.src = others.photo;
    }

    setFormFields(updatedFields);
  };

  const sendData = async (formData) => {
    setIsLoading(true);

    if (others) {
      await genericPut({
        url: `/official/${others.id}`,
        formData,
        callback: ({ isSuccess, msg }) => {
          setIsLoading(false);

          if (isSuccess) {
            navigate("/directory", {
              state: { view: "others" },
            });

            Toast({
              type: 1,
              content: "Successfully edited details",
            });
          } else {
            Toast({
              type: 4,
              content: msg,
            });
          }
        },
      });
    } else {
      await genericPost({
        url: "/official/create-others",
        formData,
        callback: ({ isSuccess, msg }) => {
          setIsLoading(false);

          if (isSuccess) {
            navigate("/directory", {
              state: { view: "others" },
            });

            Toast({
              type: 1,
              content: "Successfully added directory.",
            });
          } else {
            Toast({
              type: 4,
              content: msg,
            });
          }
        },
      });
    }
  };

  const uploadImage = async () => {
    let fileName = "";

    await uploadFile({
      file: formFields.photo,
      fileName: formFields.photoName,
      callback: (data) => {
        if (data) fileName = data;
      },
      onProgress: () => {
        //
      },
    });

    return fileName;
  };

  const handleOnSumbmit = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const data = {
        // eslint-disable-next-line max-len
        fullName: `${formFields.firstName} ${formFields.lastName}`,
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        contact: formFields.contact?.slice(2),
        position: "Others",
      };

      if (formFields.cosNumber) {
        data.cosContact = formFields.cosNumber?.slice(2);
      }

      let initialData = {};

      if (others) {
        initialData = {
          firstName: others.firstName,
          lastName: others.lastName,
          fullName: others.fullName,
          contact: others.contact,
          photo: others.photo,
          position: "Others",
        };
      }

      setIsLoading(true);

      if (typeof formFields.photo !== "string") {
        const fileName = await uploadImage();
        data.photo = fileName;
      }

      const formData = others
        ? await getModifiedObject(initialData, data)
        : data;

      await sendData(formData);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    const fileTye = file.type.split("/")[0];

    if (fileTye === "image") {
      reader.onloadend = () => {
        const imgContainer = document.querySelector(".others-img");

        const fileName = others
          ? `${
              others.id
            }-${formFields.lastName.toLocaleLowerCase()}-${Date.now()}.${
              file.type.split("/")[1]
            }`
          : `${formFields.lastName.toLocaleLowerCase()}-${Date.now()}.${
              file.type.split("/")[1]
            }`;

        setFormFields({
          ...formFields,
          photo: file,
          photoName: fileName,
        });

        imgContainer.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      Toast({
        type: 4,
        content: "File uploaded must be an image.",
      });
    }
  };

  useEffect(() => {
    if (others !== null) {
      setOthersDetails();
    }
  }, [others]);

  return (
    <div className="add-project-container directory">
      <Col className="header">
        <Row className="d-flex justify-content-end w-100 m-0">
          <Col
            xs="6"
            className="d-flex mt-3 justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Directory",
                  redirect: "/directory",
                },
                {
                  name: !others ? "Add Others" : "Others Details",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header-actions h-75 d-flex m-0 mt-4 justify-content-between align-items-end">
          <h2 className="text-danger">
            {!others ? "Add Others" : "Others Details"}
          </h2>
        </Row>
        <div className="form-section">
          <Row>
            <Col
              xs="12"
              sm="6"
              lg="4"
              className="col--img-container"
            >
              <div className="img-container">
                <ImgContainer
                  alt="others-img"
                  className="others-img"
                  src={defaultUserImage}
                  width="196px"
                  height="196px"
                />

                <label
                  htmlFor="file-upload"
                  className="upload-btn"
                >
                  {"Upload Photo"}
                  <input
                    id="file-upload"
                    className="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </label>
              </div>
            </Col>
            <Col
              xs="12"
              sm="6"
              lg="8"
            >
              <OthersForm
                handleOnSumbmit={handleOnSumbmit}
                formFields={formFields}
                setFormFields={setFormFields}
                isLoading={isLoading}
                isModal={false}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default EditOthers;
