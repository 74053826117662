import React from "react";
import styled from "styled-components";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import classNames from "classnames";
import FormGenertor from "components/customs/form-generator/input";
import CustomSelect from "components/customs/dropdown";
import {
  DropdownIndicator,
  accountCustomStyle,
  addProjectDropdownStyles,
} from "utility/constants";
import {
  fetchMunicipalities,
  fetchProvinces,
} from "services/services.projects";
import { useNavigate } from "react-router";

const StyledRed = styled.span`
  color: red;
`;
const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const SenatorForm = ({
  handleOnSumbmit,
  formFields,
  setFormFields,
  senatorOptions,
  selectedSenator,
  setSelectedSenator,
  selectedRegion,
  setSelectedRegion,
  regionOptions,
  districtError,
  partylists,
  selectedPartylist,
  setSelectedPartylist,
  partylistError,
  isLoading,
  isEdit,
}) => {
  const [selectedProvince, setSelectedProvince] = React.useState(null);
  const [provinceOptions, setProvinceOptions] = React.useState([]);
  const [selectedMunicipality, setSelectedMunicipality] = React.useState(null);
  const [municipalityOptions, setMunicipalityOptions] = React.useState([]);

  const navigate = useNavigate();

  const fetchAllProvinces = async () => {
    await fetchProvinces({
      param: formFields.region?.value,
      callback: (data) => {
        if (data.length) {
          const options = data.map(({ id, name, omegaId }) => ({
            label: name,
            value: id,
            omegaId,
          }));

          options.unshift({
            label: "All Provinces",
            value: "All Provinces",
          });

          setProvinceOptions(options);

          const option = options?.find(
            (op) => op.value === formFields?.provinceId
          );

          if (option) {
            setSelectedProvince(option);

            setFormFields({
              ...formFields,
              province: option,
            });
          } else {
            setSelectedProvince(options[0]);

            setFormFields({
              ...formFields,
              province: options[0],
            });
          }
        } else {
          setProvinceOptions([]);
        }
      },
    });
  };

  const fetchAllMunicipalities = async () => {
    await fetchMunicipalities({
      param: selectedProvince?.value,
      callback: (data) => {
        if (data?.length) {
          const options = data.map(({ id, name, omegaId }) => ({
            label: name,
            value: id,
            omegaId,
          }));

          options.unshift({
            label: "All Municipalities",
            value: "All Municipalities",
          });

          setMunicipalityOptions(options);

          const option = options?.find(
            (op) => op.value === formFields?.municipalityId
          );

          if (option) {
            setSelectedMunicipality(option);

            setFormFields({
              ...formFields,
              municipality: option,
            });
          } else {
            setSelectedMunicipality(options[0]);

            setFormFields({
              ...formFields,
              municipality: options[0],
            });
          }
        } else {
          setMunicipalityOptions([]);
        }
      },
    });
  };

  React.useEffect(() => {
    if (formFields.region) {
      fetchAllProvinces();

      setSelectedMunicipality(null);
      setSelectedProvince(null);
    }
  }, [formFields.region]);

  React.useEffect(() => {
    if (selectedProvince) {
      fetchAllMunicipalities();

      if (selectedProvince.value === "All Provinces") {
        const allMunicipalities = {
          label: "All Municipalities",
          value: "All Municipalities",
        };

        setSelectedMunicipality(allMunicipalities);

        // Directly update formFields with the new municipality value
        setFormFields((prevFields) => ({
          ...prevFields,
          municipality: allMunicipalities,
        }));
      }
    }
  }, [selectedProvince]);

  return (
    <Formik
      onSubmit={handleOnSumbmit}
      initialValues={formFields}
      enableReinitialize
    >
      {({ handleChange, values }) => (
        <Form id="add-account-form">
          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <h5 className="">
                {"Name"}
                {RequiredIndicator}
              </h5>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <CustomSelect
                placeholder=""
                name="district"
                styles={accountCustomStyle}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={senatorOptions}
                value={selectedSenator}
                onChange={(option) => {
                  setSelectedSenator(option);

                  setFormFields({
                    ...formFields,
                    lastName: option.candidate.last_name,
                    firstName: option.candidate.first_name,
                    fullName: option.label,
                    omegaId: option.value,
                  });
                }}
                isDisabled={isEdit}
              />
              <small className="field-error text-danger">{districtError}</small>
            </Col>
          </Row>

          <Field name="contact">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"Contact Number"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <FormGenertor
                    name="contact"
                    type="mobile"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.contact && touched?.contact,
                    })}
                    value={values.contact || "63"}
                    onChange={(value) => {
                      handleChange(value);

                      setFormFields({
                        ...formFields,
                        contact: value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="contact">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <h5 className="">
                {"Implementing Agency"}
                {RequiredIndicator}
              </h5>
            </Col>

            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <CustomSelect
                placeholder=""
                styles={addProjectDropdownStyles}
                components={{ DropdownIndicator }}
                className="asyc-dropdown"
                classNamePrefix="select"
                options={regionOptions}
                onChange={(option) => {
                  setSelectedRegion(option);

                  setFormFields({
                    ...formFields,
                    region: option,
                  });
                }}
                value={selectedRegion}
              />
            </Col>
          </Row>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <h5 className="">
                {"Province"}
                {RequiredIndicator}
              </h5>
            </Col>

            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <CustomSelect
                placeholder=""
                styles={addProjectDropdownStyles}
                components={{ DropdownIndicator }}
                className="asyc-dropdown"
                classNamePrefix="select"
                options={provinceOptions}
                onChange={(option) => {
                  setSelectedProvince(option);

                  setFormFields({
                    ...formFields,
                    province: option,
                  });
                }}
                value={selectedProvince}
              />
            </Col>
          </Row>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <h5 className="">
                {"Municipality"}
                {RequiredIndicator}
              </h5>
            </Col>

            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <CustomSelect
                placeholder=""
                styles={addProjectDropdownStyles}
                components={{ DropdownIndicator }}
                className="asyc-dropdown"
                classNamePrefix="select"
                options={municipalityOptions}
                onChange={(option) => {
                  setSelectedMunicipality(option);

                  setFormFields({
                    ...formFields,
                    municipality: option,
                  });
                }}
                value={selectedMunicipality}
                isDisabled={!selectedProvince}
              />
            </Col>
          </Row>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <h5 className="">{"Party"}</h5>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <CustomSelect
                placeholder=""
                name="party"
                styles={accountCustomStyle}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={partylists}
                value={selectedPartylist}
                onChange={(option) => setSelectedPartylist(option)}
              />
              <small className="field-error text-danger">
                {partylistError}
              </small>
            </Col>
          </Row>

          <Field name="cosFirstName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">{"Chief of Staff First Name"}</h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="cosFirstName"
                    className={classNames("form-control input", {
                      "is-invalid":
                        errors?.cosFirstName && touched?.cosFirstName,
                    })}
                    value={values.cosFirstName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        cosFirstName: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="cosFirstName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="cosLastName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">{"Chief of Staff Last Name"}</h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="cosLastName"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.cosLastName && touched?.cosLastName,
                    })}
                    value={values.cosLastName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        cosLastName: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="cosLastName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="cosNumber">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">{"Contact Number"}</h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <FormGenertor
                    type="mobile"
                    name="cosNumber"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.cosNumber && touched?.cosNumber,
                    })}
                    value={values.cosNumber || "63"}
                    onChange={(value) => {
                      handleChange(value);

                      setFormFields({
                        ...formFields,
                        cosNumber: value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="cosNumber">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-5  d-flex justify-content-end">
            <Button
              className="btn-cancel mr-1"
              disabled={isLoading}
              onClick={() =>
                navigate("/directory", {
                  state: { view: "senator" },
                })
              }
            >
              {"Back"}
            </Button>

            <Button
              type="submit"
              className="btn-save"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="light"
                  size="sm"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SenatorForm;
