import React from "react";
import Layout3 from "components/layout/Layout3";
import Congressman from "./cong";

const CongressmanDetails = (props) => {
  return (
    <Layout3>
      <Congressman {...props} />
    </Layout3>
  );
};

export default CongressmanDetails;
