import React, { useEffect, useState } from "react";
import BreadCrumbComponent from "components/customs/breadCrumb";
import { Eye, RotateCcw, XCircle } from "react-feather";
import { defaultTableOption } from "utility/constants";
import CustomDataTable from "components/customs/table";
import { fetchAllAgencies, fetchTable } from "services/services.projects";
import { Toast, getFormattedDateString } from "utility/helpers";
import { Col, UncontrolledTooltip } from "reactstrap";
import { genericPost } from "services/services.general";
import Swal from "sweetalert2";
import SearchBarComponent from "components/customs/searchbarV2";
import CommonModal from "components/common/modal";
import FormGenertor from "components/customs/form-generator/input";
import { useNavigate } from "react-router";
import {
  BreadCrumbSection,
  HeaderTextSection,
  MainContainer,
  Container,
  TableSection,
  SearchSection,
  ModalHeader,
  ModalBody,
  ModalContainer,
} from "./styled";

const LogsTable = () => {
  const [tableOption, setTableOption] = useState({
    ...defaultTableOption,
    currentRowsPerPage: 100,
  });
  const [isModalShown, setIsModalShown] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [renderBody, setRenderBody] = useState("");

  const navigate = useNavigate();

  const toggleModal = () => setIsModalShown(!isModalShown);

  let changeSearchTimeoutID;

  const fetchDataTable = () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    fetchTable({
      url: "/logs",
      params: {
        limit: tableOption.currentRowsPerPage,
        offset: tableOption.offset,
        search: tableOption.searchValue,
        column: tableOption.sort.field,
        order: tableOption.sort.direction,
      },
      callback: (data) => {
        setTableOption({
          ...tableOption,
          isLoading: false,
          data: data.rows,
          totalRows: data.count,
        });
      },
    });
  };

  const handleResendClicked = (row) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to resend #${row.id}`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await genericPost({
          url: "/logs/resend-sms",
          formData: {
            notificationIds: row.id.toString(),
          },
          callback: ({ isSuccess }) => {
            if (isSuccess) {
              Toast({
                type: 1,
                content: "Successfully resend sms.",
              });

              navigate(0);
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });
            }
          },
        });
      }
    });
  };

  const handleViewModal = (row) => {
    const agency = agencies?.find((ag) => ag.name === row.logInfo.agency);

    const body = (
      <>
        <div className="d-flex justify-content-end">
          <a
            href="!#"
            onClick={(e) => {
              toggleModal();
              setIsModalShown(false);
              e.preventDefault();
            }}
          >
            <XCircle />
          </a>
        </div>
        <ModalContainer>
          <ModalHeader>
            <h3>{"Message"}</h3>
            <h5>{`#${row.id}`}</h5>
          </ModalHeader>
          <ModalBody>
            <Col
              xs={12}
              md={6}
            >
              <div className="row-details">
                <p>{"Recipient:"}</p>
                <p>{row.logInfo.recipient}</p>
              </div>
              <div className="row-details">
                <p>{"Contact No:"}</p>
                <p>{row.to}</p>
              </div>
              <div className="row-details">
                <p>{"Agency:"}</p>
                <p>{agency?.acronym}</p>
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
            >
              <div className="row-details">
                <p>{"Date:"}</p>
                <p>{getFormattedDateString(row.sentAt, "MMM dd, yyyy")}</p>
              </div>
              <div className="row-details">
                <p>{"Time:"}</p>
                <p>{getFormattedDateString(row.sentAt, "HH:mm a")}</p>
              </div>
              <div className="row-details">
                <p>{"Amount:"}</p>
                <p>{row.logInfo.amount}</p>
              </div>
            </Col>
          </ModalBody>

          <div>
            <FormGenertor
              isDisabled
              value={row.message}
              type="textarea"
              className="w-100"
              rows={20}
            />
          </div>
        </ModalContainer>
      </>
    );
    setRenderBody(body);
    toggleModal();
  };

  const columns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      style: { paddingLeft: 0, paddingRight: 0 },
      cell: () => null,
    },
    {
      name: "Notif #",
      selector: ({ id }) => id,
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ id }) => <p className="mb-0 ">{id}</p>,
    },
    {
      name: "Allocation #",
      selector: ({ logInfo }) => logInfo?.allocationId,
      sortable: true,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ logInfo }) => <p className="mb-0 ">{logInfo?.allocationId}</p>,
    },
    {
      name: "Recipient Name",
      selector: ({ logInfo }) => logInfo?.recipient,
      sortable: true,
      minWidth: "200px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ logInfo }) => <p className="mb-0 ">{logInfo?.recipient}</p>,
    },
    {
      name: "Recipient Contact",
      selector: ({ to }) => to,
      sortable: true,
      minWidth: "130px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ to }) => <p className="mb-0 ">{to}</p>,
    },
    {
      name: "Agency",
      selector: ({ logInfo }) => logInfo?.agency,
      sortable: true,
      minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ logInfo }) => {
        const agency = agencies?.find((ag) => ag.name === logInfo.agency);

        return <p className="mb-0 ">{agency?.acronym}</p>;
      },
    },
    {
      name: "Amount",
      selector: ({ logInfo }) => logInfo?.amount,
      sortable: true,
      minWidth: "180px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ logInfo }) => <p className="mb-0 ">{logInfo?.amount}</p>,
    },
    {
      name: "Region",
      selector: ({ logInfo }) => logInfo?.region,
      sortable: true,
      minWidth: "200px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ logInfo }) => (
        <p className="mb-0 ">{logInfo?.region?.name ?? logInfo?.region}</p>
      ),
    },
    {
      name: "Date Sent",
      selector: null,
      sortable: false,
      minWidth: "150px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ sentAt }) => (
        <p className="mb-0 ">
          {getFormattedDateString(sentAt, "MMM dd, yyyy HH:mm")}
        </p>
      ),
    },
    {
      name: "Actions",
      selector: null,
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => {
        return (
          <>
            <Eye
              size={16}
              color="#4252C9"
              onClick={() => handleViewModal(row)}
              id={`view-row--${row.id}`}
            />
            <UncontrolledTooltip
              placement="left"
              target={`view-row--${row.id}`}
            >
              {"View"}
            </UncontrolledTooltip>

            <RotateCcw
              size={16}
              color="#4252C9"
              className="ml-05"
              onClick={() => handleResendClicked(row)}
              id={`resend-row--${row.id}`}
            />
            <UncontrolledTooltip
              placement="right"
              target={`resend-row--${row.id}`}
            >
              {"Resend"}
            </UncontrolledTooltip>
          </>
        );
      },
    },
  ];

  const onChangePage = (page) => {
    setTableOption({
      ...tableOption,
      page,
      offset: tableOption.currentRowsPerPage * page - 100,
    });
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setTableOption({
      ...tableOption,
      currentRowsPerPage,
      page: currentPage,
    });
  };

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value;

      setTableOption({
        ...tableOption,
        page: 1,
        searchValue: eventValue.trim(),
      });
    }, 1000);
  };

  const onSort = (column, sortDirection) => {
    setTableOption({
      ...tableOption,
      sort: {
        field: column.identifier,
        direction: sortDirection,
      },
    });
  };

  const fetchAgencies = () => {
    fetchAllAgencies({
      callback: (data) => {
        setAgencies(data);
      },
    });
  };

  useEffect(() => {
    fetchAgencies();
  }, []);

  useEffect(() => {
    fetchDataTable();
  }, [
    tableOption.currentRowsPerPage,
    tableOption.offset,
    tableOption.searchValue,
    tableOption.sort.field,
    tableOption.sort.direction,
  ]);

  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "Notification Logs",
                isActive: true,
              },
            ]}
          />
        </BreadCrumbSection>

        <HeaderTextSection>
          <h2 className="text-danger">{"Notification Logs"}</h2>
        </HeaderTextSection>

        <SearchSection>
          <div className="position-relative">
            <SearchBarComponent
              onChangeSearch={onChangeSearch}
              isDisabled={tableOption?.isLoading}
            />
          </div>
        </SearchSection>

        <TableSection>
          <CustomDataTable
            columns={columns}
            data={tableOption.data}
            noHeader
            progressPending={tableOption.isLoading}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSort={onSort}
            height="calc(100vh - 200px)"
            paginationServer
            sortServer
            paginationPerPage={tableOption.currentRowsPerPage}
            paginationTotalRows={tableOption.totalRows}
          />
        </TableSection>
      </Container>
      <CommonModal
        isOpen={isModalShown}
        handleClose={toggleModal}
        renderBody={renderBody}
        modalSize="lg"
      />
    </MainContainer>
  );
};

export default LogsTable;
