import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  fetchNotification,
  resendNotification,
} from "services/services.projects";
import { Toast } from "utility/helpers";
import { InputTags } from "react-bootstrap-tagsinput";
import PropTypes from "prop-types";
import { getUserDetails } from "config";
import CustomCheckbox from "../checkbox";

import "react-bootstrap-tagsinput/dist/index.css";
import "./styles.scss";

const ResendModal = ({ isOpen, data, handleClose, type, messageType }) => {
  const [recipients, setRecipients] = useState([]);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sendToCOS, setSendToCOS] = useState(true);

  const renderRecipients = () => {
    let element = "";

    if (type === "single") {
      element = recipients.map((recipient) => {
        return (
          <>
            <label htmlFor={recipient.id}>{"Recipients"}</label>

            <Input
              className="form-control single"
              key={recipient.id}
              disabled
              value={recipient.name}
            />
          </>
        );
      });
    } else {
      const defaultValue = [
        ...new Set(recipients.map((recipient) => recipient.name)),
      ];

      element = (
        <>
          <label htmlFor="multiple-badges">{"Recipients"}</label>
          <div className="input-group tags">
            <InputTags
              id="multiple-badges"
              values={defaultValue}
              onTags={({ values }) => {
                const newData = [];

                // eslint-disable-next-line array-callback-return
                recipients.map((recipient) => {
                  if (values.includes(recipient.name)) newData.push(recipient);
                });

                setRecipients(newData);
              }}
              disabled
            />
            <Button
              className="btn btn-outline-secondary btn-light"
              type="button"
              data-testid="button-clearAll"
              disabled={isLoading}
              onClick={() => {
                setRecipients([]);
              }}
            >
              {"Delete"}
            </Button>
          </div>
        </>
      );
    }

    return element;
  };

  const fetchSentNotification = async () => {
    await fetchNotification({
      url: `/recipient/${data[0]?.id}/latest-sent-notification`,
      callback: (response) => {
        if (response.notification && response.notification.message) {
          const { notification } = response;
          const { message } = notification;
          setDescription(message);
        }

        setIsLoading(false);
      },
    });
  };

  const sendData = async (formData) => {
    await resendNotification({
      formData,
      callback: ({ isSuccess }) => {
        if (isSuccess) {
          handleClose();
          setSendToCOS(true);

          Toast({
            type: 1,
            content: "Successfully resent message!.",
          });
        } else {
          Toast({
            type: 4,
            content: "An error occured when resending messages.",
          });
        }

        setIsLoading(false);
      },
    });
  };

  // eslint-disable-next-line consistent-return
  const handleConfirmClicked = async () => {
    if (recipients.length === 0) {
      return Toast({
        type: 4,
        content: "Recipients required.",
      });
    }

    if (description === "" && messageType !== "approval") {
      return Toast({
        type: 4,
        content: "Message required.",
      });
    }

    setIsLoading(true);
    const contact = recipients.map((recipient) => recipient.contact).toString();
    const { status } = recipients[0];
    const congs = recipients.map((recipient) => recipient.id);

    const notification = {
      message: description,
      contact,
      status,
      recipients: congs,
    };

    const formData = {
      notification,
      userId: getUserDetails()?.userId,
      messageType:
        messageType === "approval" ? "Approval Message" : "General Message",
      includeCOS: sendToCOS,
    };

    await sendData(formData);
  };

  useEffect(() => {
    if (data.length !== 0) {
      const recipientsData = data.map((recipient) => {
        return {
          // eslint-disable-next-line max-len
          name: `${recipient.recipient.lastName}, ${recipient.recipient.firstName}`,
          id: recipient.id,
          contact: recipient.recipient.contact,
          status: recipient.status,
        };
      });

      setRecipients(recipientsData);
      setDescription("");

      if (type === "single") {
        setIsLoading(true);
        fetchSentNotification();
      }
    }
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      centered
      className="resend-modal"
    >
      <ModalHeader toggle={handleClose}>{"Resend Message"}</ModalHeader>
      <ModalBody>
        <div className="mx-2">
          <div className="resend-modal--container">
            {renderRecipients()}
            <div className="mt-2">
              <Label check>
                <CustomCheckbox
                  checked={sendToCOS}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setSendToCOS(checked);
                  }}
                  type="checkbox"
                  label="Send to Chief of Staff"
                />
              </Label>
            </div>

            {(type === "single" || messageType !== "approval") && (
              <div className="msg-body">
                <textarea
                  rows={5}
                  value={description}
                  disabled={isLoading}
                  onChange={(e) => {
                    const { value } = e.target;

                    setDescription(value);
                  }}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <div className="mb-2 d-flex justify-content-end">
            <Button
              className="btn-save"
              onClick={() => handleConfirmClicked()}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="light"
                  size="sm"
                />
              ) : (
                "Confirm"
              )}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ResendModal.prototype = {
  isOpen: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.string]),
  handleClose: PropTypes.func,
  type: PropTypes.string,
  messageType: PropTypes.string,
};

export default memo(ResendModal);
