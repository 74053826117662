import React from "react";
import Navbar from "./navbar";
import { CongLayout } from "./style";

const Layout3 = ({ children }) => {
  return (
    <CongLayout>
      <Navbar />
      {children}
    </CongLayout>
  );
};

export default Layout3;
