import React from "react";
import CreatableSelect from "react-select/creatable";
import { CreatableSelectContainer } from "./styled";

const CustomCreatableSelect = (props) => {
  return (
    <CreatableSelectContainer>
      <CreatableSelect {...props} />
    </CreatableSelectContainer>
  );
};

export default CustomCreatableSelect;
