import BreadCrumbComponent from "components/customs/breadCrumb";
import CustomSelect from "components/customs/dropdown";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import {
  DropdownIndicator2,
  MultiSelectorStyle,
  statusOptions,
} from "utility/constants";
import {
  fetchAllAgencies,
  fetchAllAgencyProjects,
} from "services/services.projects";
import {
  Toast,
  getFormattedDateString,
  getFormattedOptions,
} from "utility/helpers";
import { fetchRawReport } from "services/services.summary";
import { fetchAllRegions } from "services/services.congressman";
import jsPDF from "jspdf";
import "jspdf-autotable";
import pdfBG from "assets/img/BG_4.png";
import logoLeft from "assets/img/HOR.png";
import logoRight from "assets/img/HOR_1.png";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUserDetails } from "config";
import * as XLSX from "xlsx";
import { format } from "date-fns";

import "./styles.scss";

const modifiedStatuses = {
  Approved: "Endorsed",
  Disapproved: "Pls See Comment",
};

const SummaryExport = () => {
  const [agencyOptions, setAgencOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRecipients, setSelectRecipients] = useState(null);
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statuses, setStatuses] = useState(statusOptions);

  const [selectOptions, setSelectOptions] = useState({
    agency: [],
    project: [],
    recipient: [],
  });

  // eslint-disable-next-line no-unused-vars
  const generateReport = (data) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF("a4");

    // Get the page width in points
    const pageWidth = doc.internal.pageSize.getWidth();
    // Get the height of the page
    const pageHeight = doc.internal.pageSize.height;
    const imageX = doc.internal.pageSize.getWidth() - 78;

    doc.addImage(pdfBG, "PNG", 0, 0, pageWidth, 45);
    doc.addImage(logoLeft, "PNG", 12.5, 10, 25, 25);
    doc.addImage(logoRight, "PNG", imageX, 10, 65, 25);

    doc.setFontSize(24);
    doc.setTextColor("#4252C9");
    doc.setFont("Montserrat, sans-serif", "bold");
    doc.text("SUMMARY REPORT", pageWidth / 2, 60, { align: "center" });

    const projectNames = [];
    const agencyNames = [];

    // eslint-disable-next-line array-callback-return
    data?.map((agency) => {
      // eslint-disable-next-line array-callback-return
      agency?.projects?.map((project) => {
        projectNames.push(project?.name);
      });
    });

    const totalBudgetAllocation = data.reduce(
      (acc, project) => acc + project.allocation,
      0
    );

    // eslint-disable-next-line array-callback-return
    data?.map((agency) => {
      agencyNames.push(agency?.name);
    });

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const agencyBody = [
      ["Agency", agencyNames?.toString(), "Date", `${year}-${month}-${day}`],
      [
        "Date Period",
        `${moment(startDate).format("YYYY-MM-DD")} to ${moment(endDate).format(
          "YYYY-MM-DD"
        )}`,
        { content: "Projects", rowSpan: 2 },
        { content: projectNames?.toString(), rowSpan: 2 },
      ],
      ["Total Allocation", totalBudgetAllocation?.toLocaleString("en-US")],
    ];

    doc.autoTable({
      body: agencyBody,
      startY: 70,
      theme: "grid",
      bodyStyles: {
        lineWidth: 0.25,
        lineColor: "#000000",
      },
    });

    // eslint-disable-next-line array-callback-return
    data?.map((agency) => {
      const projects = agency?.projects;

      // eslint-disable-next-line array-callback-return
      projects.map((project) => {
        doc.setFontSize(16);

        let lastY = doc.autoTable.previous.finalY;

        if (lastY + 12 > 250) {
          doc.addPage();
          lastY = 15;
        }

        const projectName = project.name;

        doc.text(
          projectName,
          pageWidth / 2,
          lastY !== 15 ? lastY + 12 : lastY,
          { align: "center" }
        );

        const headers = [
          [
            "Date",
            "Recipient / Name of Partylist",
            "District",
            "Amount",
            "Status",
          ],
        ];

        const recipients = project?.recipients;
        const recipientBody = [];

        if (recipients.length !== 0) {
          // eslint-disable-next-line array-callback-return
          recipients.map((recipient) => {
            recipientBody.push([
              new Date(recipient.createdAt).toISOString().split("T")[0],
              recipient.congressman.fullName,
              recipient.implementing.id === 19
                ? recipient?.receivingEntity
                : recipient.implementing.name,
              recipient.budget?.toLocaleString("en-US"),
              recipient.status,
            ]);
          });
        }

        doc.autoTable({
          body: recipientBody,
          head: headers,
          startY: lastY === 15 ? 25 : lastY + 20,
          theme: "grid",
          bodyStyles: {
            lineWidth: 0.25,
            lineColor: "#000000",
          },
          headStyles: {
            textColor: "#000000",
            fillColor: "#FFFFFF",
            lineWidth: 0.25,
            lineColor: "#000000",
            fontStyle: "normal",
          },
        });
      });
    });

    doc.setFontSize(12);
    doc.setTextColor("#000000");
    doc.setFont("Montserrat", "normal");

    if (doc.autoTable.previous.finalY > 250) doc.addPage();

    doc.text("Prepared by:", 15, pageHeight - 40);

    const { firstName, lastName, type } = getUserDetails();
    const name = `${firstName} ${lastName}`;
    doc.setTextColor("#4252C9");
    doc.setFont("Montserrat", "bold");
    doc.text(name, 15, pageHeight - 28);
    doc.setTextColor("#000000");
    doc.setFont("Montserrat", "normal");
    doc.text(type, 15, pageHeight - 23);

    const pages = doc.internal.getNumberOfPages();

    if (pages > 1) {
      // eslint-disable-next-line no-plusplus
      for (let index = 1; index <= pages; index++) {
        doc.setPage(index);
        doc.text(`${index}/${pages}`, 190, 285);
      }
    }

    setIsLoading(false);
    doc.save("report.pdf");
  };

  const formatData = (data) => {
    const formattedData = [];

    if (data?.length) {
      data.forEach((agency) => {
        const agen = agency.id;

        if (agency?.projects?.length) {
          agency?.projects?.forEach((project) => {
            if (project?.recipients?.length) {
              project?.recipients?.forEach((recipient) => {
                const supporting = recipient?.documents?.supporting || [];

                const files = supporting?.join("\n");

                // const files = supporting
                //   ?.map((file) => {
                //     const fileArray = file.split("/");
                //     const len = fileArray.length;

                //     // eslint-disable-next-line max-len
                //     return `https://${S3_BUCKET}.s3.ap-southeast-1.amazonaws.com/documents/${decodeURIComponent(
                //       fileArray[len - 1]
                //     )}`;
                //   })
                //   .join("\n");

                formattedData.push({
                  ID: recipient.id,
                  Congressman: recipient?.congressman?.fullName,
                  "Congressman Contact": recipient?.congressman?.contact,
                  Agency: agency?.name,
                  Project: project?.name,
                  Budget: recipient?.budget,
                  "Date Requested": getFormattedDateString(
                    recipient?.createdAt,
                    "MMM-dd-yyy"
                  ),
                  "Date Approved": getFormattedDateString(
                    recipient?.dateApproved,
                    "MMM-dd-yyy"
                  ),
                  Status:
                    modifiedStatuses[recipient?.status] ?? recipient?.status,
                  "Allocation Type": recipient?.allocationType,
                  // eslint-disable-next-line max-len
                  COS: `${recipient?.congressman?.cosFirstName || ""} ${
                    recipient?.congressman?.cosLastName || ""
                  }`,
                  "COS Contact": recipient?.congressman?.cosContact,
                  Allocation: recipient?.allocationName,
                  "Allocation Contact Person": recipient?.allocationContactName,
                  "Allocation Contact Number":
                    recipient?.allocationContactNumber,
                  "Implementing Agency": recipient?.implementing?.acronym,
                  "Implementing Office": recipient?.implementingOffice,
                  Province: recipient?.province?.name,
                  Municipality: recipient?.municipality?.name,
                  Facility: agen === 8 ? recipient?.institution?.name : null,
                  "Fund Source": recipient?.fundSource,
                  Justification: recipient?.justification,
                  "LGSF Tracking Code": recipient?.otherInfo,
                  "Project Title":
                    agen === 3 || agen === 14
                      ? recipient?.institution?.name
                      : null,
                  "Name of TVI":
                    agen === 27 ? recipient?.institution?.name : null,
                  "Number of Slots": recipient?.slots,
                  "Supporting Documents": files,
                  "Editor Notes": recipient?.editorNotes,
                });
              });
            }
          });
        }
      });
    }

    // SORT BY dateApproved DESCENDING
    formattedData.sort((a, b) => {
      const dateA = a["Date Approved"] ? new Date(a["Date Approved"]) : null;
      const dateB = b["Date Approved"] ? new Date(b["Date Approved"]) : null;

      if (dateA === null && dateB === null) return 0;
      if (dateA === null) return 1;
      if (dateB === null) return -1;

      return dateB - dateA;
    });

    if (formattedData.length !== 0) {
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      worksheet["!cols"] = [
        { wpx: 50 },
        { wpx: 275 },
        { wpx: 157 },
        { wpx: 348 },
        { wpx: 72 },
        { wpx: 134 },
        { wpx: 145 },
        { wpx: 145 },
        { wpx: 100 },
        { wpx: 168 },
        { wpx: 275 },
        { wpx: 157 },
        { wpx: 219 },
        { wpx: 275 },
        { wpx: 157 },
        { wpx: 146 },
        { wpx: 160 },
        { wpx: 200 },
        { wpx: 190 },
        { wpx: 190 },
        { wpx: 190 },
        { wpx: 400 },
        { wpx: 190 },
        { wpx: 275 },
        { wpx: 275 },
        { wpx: 190 },
        { wpx: 400 },
        { wpx: 190 },
      ];

      for (let i = 2; i < formattedData.length + 2; i += 1) {
        worksheet[`F${i}`].z = "#,##0.00";
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Report 1");

      XLSX.writeFile(
        workbook,
        `Report (${format(new Date(), "uu-LL-dd HHmm")}).xlsx`
      );
      setIsLoading(false);
    }
  };

  const handleExportBtnCliked = () => {
    setIsLoading(true);

    // eslint-disable-next-line no-nested-ternary
    const projectIds = selectedProject.some(
      (project) => project.value === "All"
    )
      ? selectOptions.project?.slice(1)?.map((project) => project.id)
      : selectedProject?.map((project) => project.id) ?? [];

    const selectedStatues = selectedStatus.some(
      (status) => status.value === "All"
    )
      ? "all"
      : selectedStatus?.map((status) => status.value) ?? [];

    const selectedRegions = selectedRecipients.some(
      (status) => status.value === "All"
    )
      ? "all"
      : selectedRecipients.map((recipient) => recipient.label);

    const params = {
      projectIds:
        projectIds === "all" ? projectIds : JSON.stringify(projectIds), // change to array
      status:
        selectedStatues === "all"
          ? selectedStatues
          : JSON.stringify(selectedStatues), // change to array
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      receivingEntities:
        selectedRegions === "all"
          ? selectedRegions
          : JSON.stringify(selectedRegions),
    };

    fetchRawReport({
      params,
      callback: ({ isSuccess, data }) => {
        setIsLoading(false);

        if (isSuccess && data.length !== 0) {
          formatData(data);
        } else if (data.length === 0) {
          Toast({
            type: 4,
            content: "Empty data",
          });
        } else {
          Toast({
            type: 4,
            content: "An error occured!",
          });
        }
      },
    });
  };

  const fetchAgencies = async () => {
    await fetchAllAgencies({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);

        newData.unshift({
          value: "All",
          label: "All",
        });
        setAgencOptions(newData);
        setSelectOptions({ ...selectOptions, agency: newData });
      },
    });
  };

  const fetchAgencyProjects = (url) => {
    return new Promise((resolve, reject) => {
      fetchAllAgencyProjects({
        url,
        callback: (data) => {
          resolve(data);
        },
        errorCallback: (error) => {
          reject(error);
        },
      });
    });
  };

  const fetchProjects = async () => {
    try {
      let options = [];

      if (selectedAgency.some((agency) => agency.value === "All")) {
        const data = await fetchAgencyProjects("/project");
        options = getFormattedOptions("name", data);
      } else {
        const agencies = selectedAgency.map((agency) => agency.id).toString();

        await fetchAgencyProjects(`/project/?agencies=${agencies}`).then(
          (data) => {
            const newData = getFormattedOptions("name", data);
            options.push(...newData);
          }
        );
      }

      options?.unshift({
        value: "All",
        label: "All",
      });

      setProjectOptions(options);

      setSelectOptions({
        ...selectOptions,
        project: options,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchRegions = async () => {
    await fetchAllRegions({
      callback: (data) => {
        const newData = getFormattedOptions("acronym", data);

        newData.unshift(
          {
            value: "All",
            label: "All",
          },
          {
            value: "National",
            label: "National",
          }
        );

        setRecipientOptions(newData);
        setSelectOptions({ ...selectOptions, recipient: newData });
      },
    });
  };

  const isBtnDisabled =
    isLoading ||
    selectedProject === null ||
    selectedAgency === null ||
    selectedStatus === null ||
    startDate === null ||
    endDate === null ||
    selectedRecipients === null;

  useEffect(() => {
    //  fetch all agencies
    fetchAgencies();
  }, []);

  useEffect(() => {
    if (selectedAgency !== null) {
      // reset selected project on agency change
      setSelectedProject(null);
      setProjectOptions([]);
      // reset selected recipients on agency change
      setSelectRecipients(null);
      setRecipientOptions([]);
      // fetch projects belonging to the selected agency
      fetchProjects();
    }

    if (
      selectedAgency !== null &&
      selectedAgency?.some((agency) => agency.value === "All")
    ) {
      setAgencOptions([]);
    }
  }, [selectedAgency]);

  useEffect(() => {
    if (selectedProject !== null) {
      // reset selected recipients on agency change
      setSelectRecipients(null);

      // fetch congs belonging to selected agency and project
      if (recipientOptions.length === 0) fetchRegions();
    }

    if (
      selectedProject !== null &&
      selectedProject?.some((project) => project.value === "All")
    ) {
      setProjectOptions([]);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (
      selectedStatus !== null &&
      selectedStatus?.some((status) => status.value === "All")
    ) {
      setStatuses([]);
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (
      selectedRecipients !== null &&
      selectedRecipients?.some((recipient) => recipient.value === "All")
    ) {
      setRecipientOptions([]);
    }
  }, [selectedRecipients]);

  return (
    <div className="m-0 p-0 summary-report">
      <Col className="summary-report--container">
        <Row className="d-flex justify-content-end m-0 align-items-end">
          <Col
            xs="6"
            className="d-flex justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Reports",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header d-flex m-0 align-items-end">
          <Col
            md="6"
            xs="12"
            className="header-text"
          >
            <h2 className="text-danger">{"Summary Report"}</h2>
          </Col>
        </Row>
        <div className="main">
          <Row className="m-0 d-flex justify-content-between">
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <h5 className="font-weight-bold">{"Agency"}</h5>
              <p>{"Provide the name of the agency"}</p>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <CustomSelect
                placeholder="Agency"
                styles={MultiSelectorStyle}
                components={{ DropdownIndicator: DropdownIndicator2 }}
                classNamePrefix="select"
                options={agencyOptions}
                value={selectedAgency}
                onChange={(option) => {
                  const index = option.length - 1;

                  if (option[index]?.value === "All") {
                    setSelectedAgency([option[index]]);
                  } else {
                    setSelectedAgency(option);
                  }

                  if (option?.length === 0 && agencyOptions?.length === 0) {
                    setAgencOptions(selectOptions.agency);
                  }
                }}
                isMulti
                // eslint-disable-next-line consistent-return
                filterOption={(option, searchValue) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchValue?.toLowerCase()) ||
                    option?.data?.acronym
                      ?.toLowerCase()
                      ?.includes(searchValue?.toLowerCase())
                  ) {
                    return option;
                  }
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4 m-0 d-flex justify-content-between">
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <h5 className="font-weight-bold">{"Program"}</h5>
              <p>{"Provide the name of the program"}</p>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <CustomSelect
                placeholder="Program"
                styles={MultiSelectorStyle}
                components={{ DropdownIndicator: DropdownIndicator2 }}
                classNamePrefix="select"
                options={projectOptions}
                value={selectedProject}
                onChange={(option) => {
                  const index = option.length - 1;

                  if (option[index]?.value === "All") {
                    setSelectedProject([option[index]]);
                  } else {
                    setSelectedProject(option);
                  }

                  if (option?.length === 0 && projectOptions?.length === 0) {
                    setProjectOptions(selectOptions.project);
                  }
                }}
                isMulti
                isDisabled={selectedAgency === null}
                // eslint-disable-next-line consistent-return
                filterOption={(option, searchValue) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchValue?.toLowerCase())
                  ) {
                    return option;
                  }
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4 m-0 d-flex justify-content-between date-section">
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <h5 className="font-weight-bold">{"Date"}</h5>
              <p>{"Provide the date of implementation"}</p>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <Row>
                <Col>
                  <DatePicker
                    selected={startDate}
                    placeholderText="Start Date"
                    className="form-control input-date"
                    dateFormat="MMM-dd-yyyy"
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                  />
                </Col>
                <Col>
                  <DatePicker
                    selected={endDate}
                    placeholderText="End Date"
                    className="form-control input-date"
                    dateFormat="MMM-dd-yyyy"
                    maxDate={new Date()}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4 m-0 d-flex justify-content-between">
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <h5 className="font-weight-bold">{"Status"}</h5>
              <p>{"Provide the status of the program"}</p>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <CustomSelect
                placeholder="Status"
                styles={MultiSelectorStyle}
                components={{ DropdownIndicator: DropdownIndicator2 }}
                classNamePrefix="select"
                options={statuses}
                value={selectedStatus}
                onChange={(option) => {
                  const index = option.length - 1;

                  if (option[index]?.value === "All") {
                    setSelectedStatus([option[index]]);
                  } else {
                    setSelectedStatus(option);
                  }

                  if (option?.length === 0 && statuses?.length === 0) {
                    setStatuses(statusOptions);
                  }
                }}
                isMulti
                isDisabled={selectedProject === null}
                // eslint-disable-next-line consistent-return
                filterOption={(option, searchValue) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchValue?.toLowerCase())
                  ) {
                    return option;
                  }
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4 m-0 d-flex justify-content-between">
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <h5 className="font-weight-bold">{"Recipient"}</h5>
              <p>{"Provide the recipient of the program"}</p>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="6"
            >
              <CustomSelect
                placeholder="Recipient"
                styles={MultiSelectorStyle}
                components={{ DropdownIndicator: DropdownIndicator2 }}
                classNamePrefix="select"
                options={recipientOptions}
                value={selectedRecipients}
                onChange={(option) => {
                  const index = option.length - 1;

                  if (option[index]?.value === "All") {
                    setSelectRecipients([option[index]]);
                  } else {
                    setSelectRecipients(option);
                  }

                  if (option?.length === 0 && recipientOptions?.length === 0) {
                    setRecipientOptions(selectOptions.recipient);
                  }
                }}
                isMulti
                isDisabled={selectedProject === null}
                maxMenuHeight="150px"
                // eslint-disable-next-line consistent-return
                filterOption={(option, searchValue) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchValue?.toLowerCase())
                  ) {
                    return option;
                  }
                }}
              />
            </Col>
          </Row>
          <Row className="m-0 d-flex justify-content-end btn-export-section">
            <Col
              xs="auto"
              className="d-flex justify-content-end"
            >
              <Button
                className="btn-export"
                onClick={() => handleExportBtnCliked()}
                disabled={isBtnDisabled}
              >
                {isLoading ? (
                  <Spinner
                    animation="border"
                    color="light"
                    size="sm"
                  />
                ) : (
                  "Export Report"
                )}
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default SummaryExport;
