import { Toast } from "utility/helpers";
import api from "./api";

const editTotalBudget = ({ formData, url, callback }) => {
  api({
    Method: "PUT",
    Data: formData,
    Url: url,
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        const { data } = response;
        callback(data);
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: (err) => {
      Toast({
        type: 4,
        content: err?.message,
      });
    },
  });
};

const fetchAllAgencies = ({ callback, dateRange }) => {
  const { dateFrom, dateTo } = dateRange || {};

  const params =
    dateFrom && dateTo
      ? {
          Params: {
            startDate: dateFrom,
            endDate: dateTo,
          },
        }
      : {};

  api({
    Url: "/agency",
    Method: "GET",
    ...params,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];
        callback(data);
      }
    },
  });
};

const fetchAllAgencyProjects = ({ url, callback }) => {
  api({
    Url: url,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];
        callback(data);
      }
    },
  });
};

const assignProjectCongressman = ({ formData, callback }) => {
  api({
    Url: "/project/assign-congressmen/",
    Method: "POST",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response?.request?.status === 201) {
        callback({ isSuccess: true, msg: response.data.message });
      }
    },
    ResponseFailCallback: (err) => {
      callback({ isSuccess: false, msg: err.response.data.message });
    },
  });
};

const fetchTable = ({ url, params, callback }) => {
  api({
    Url: url,
    Params: params,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(data);
      }
    },
  });
};

const updateRow = ({ url, formData, callback }) => {
  api({
    Url: url,
    Method: "PUT",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const fetchProjectAllocation = ({ url, callback }) => {
  api({
    Url: url,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(data);
      }
    },
  });
};

const fetchProgramComments = ({ params, callback }) => {
  api({
    Method: "GET",
    Url: `/recipient/${params}/comments`,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];
        callback(data);
      }
    },
  });
};

const fetchAgencyDetails = ({ params, callback, dateRange }) => {
  const { dateFrom, dateTo } = dateRange || {};

  const newParamsValue =
    dateFrom && dateTo
      ? `${params}?startDate=${dateFrom}&endDate=${dateTo}`
      : params;

  api({
    Url: `/agency/${newParamsValue}`,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];
        callback(data);
      }
    },
  });
};

const recipientBatchUpdate = ({ url, formData, callback }) => {
  api({
    Url: url,
    Method: "PUT",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({ isSuccess: true, msg: response.data.message });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const deleteRecipient = ({ params, callback }) => {
  api({
    Url: `/recipient/${params}`,
    Method: "DELETE",
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const deleteProject = ({ param, callback }) => {
  api({
    Url: `/project/${param}`,
    Method: "DELETE",
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const deleteRecipients = ({ params, callback }) => {
  api({
    Url: `/recipient/?recipientIds=${params}`,
    Method: "DELETE",
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const fetchNotification = ({ url, callback }) => {
  api({
    Url: url,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const { data } = response;
        callback(data);
      }
    },
  });
};

const resendNotification = ({ formData, callback }) => {
  api({
    Url: "/recipient/send-notification",
    Method: "POST",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const deleteAgency = ({ param, callback }) => {
  api({
    Url: `/agency/${param}`,
    Method: "DELETE",
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const fetchInstitutions = async ({ param, callback }) => {
  await api({
    Url: `/institution${param}`,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const { data } = response;
        callback(data);
      }
    },
  });
};

const fetchProvinces = async ({ param, callback }) => {
  await api({
    Url: `/province?regionId=${param}`,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const { data } = response;
        callback(data);
      }
    },
  });
};

const fetchMunicipalities = async ({ param, callback }) => {
  await api({
    Url: `/municipality?provinceId=${param}`,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const { data } = response;
        callback(data);
      }
    },
  });
};

const fetchCongressmanPrograms = async ({ url, callback }) => {
  await api({
    Url: url,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const { data } = response;
        callback(data);
      }
    },
  });
};

const markComment = ({ param, formData, callback }) => {
  api({
    Url: `/comment/${param}/mark-as`,
    Method: "POST",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({ isSuccess: true });
      } else {
        callback({ isSuccess: false });
      }
    },
  });
};

export {
  editTotalBudget,
  fetchAllAgencies,
  fetchAllAgencyProjects,
  assignProjectCongressman,
  fetchTable,
  updateRow,
  fetchProjectAllocation,
  fetchProgramComments,
  fetchAgencyDetails,
  recipientBatchUpdate,
  deleteRecipient,
  deleteRecipients,
  deleteProject,
  fetchNotification,
  resendNotification,
  deleteAgency,
  fetchInstitutions,
  fetchProvinces,
  fetchMunicipalities,
  fetchCongressmanPrograms,
  markComment,
};
