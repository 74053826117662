import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import {
  getFormattedContactNumber,
  getFormattedOptions,
  Toast,
} from "utility/helpers";
import { fetchRegions } from "services/services.congressman";
import { fetchAllAgencies } from "services/services.projects";
import {
  createRegionalDirector,
  editRegionalDirector,
} from "services/services.directory";
import DirectorForm from "components/forms/Director";

const DirectorDetails = () => {
  const location = useLocation();
  const director = location.state?.director || null;
  const agencyDropdown = location.state?.agency || null;

  const [regionError, setRegionError] = useState("");
  const [agencyError, setAgencyError] = useState("");
  const [agencyId, setAgencyId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedAgency, setSelectedAgency] = useState(null);

  const [formFields, setFormFields] = useState({
    lastName: "",
    firstName: "",
    mobile: "",
    region: "",
    cosFirstName: "",
    cosLastName: "",
    cosNumber: "",
    agency: "",
  });

  const navigate = useNavigate();

  const validate = () => {
    if (!selectedRegion) {
      setRegionError("Required");

      return "Required region";
    }
    setRegionError("");

    if (!selectedAgency) {
      setAgencyError("Required");

      return "Required agency";
    }
    setAgencyError("");

    if (
      formFields.cosNumber?.length > 2 &&
      !/^(639)\d{9}$/.test(formFields?.cosNumber)
    ) {
      return "Invalid cos contact number.";
    }

    return true;
  };

  const setData = () => {
    const option = regionOptions.find(
      (region) => region.id === director.region.id
    );

    if (option) setSelectedRegion(option);
  };

  const setDirectorDetails = () => {
    setFormFields({
      ...formFields,
      lastName: director.lastName || director.name,
      firstName: director.firstName,
      agency: director.agency.id,
      mobile: getFormattedContactNumber(director.contact),
      region: director.region.id,
      cosFirstName: director.cosFirstName,
      cosLastName: director.cosLastName || director.cosName,
      cosNumber: getFormattedContactNumber(director.cosContact),
    });
  };

  const sendData = async (formData) => {
    if (director) {
      await editRegionalDirector({
        url: `/regional-director/${director.id}`,
        formData,
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            setIsLoading(false);

            navigate("/directory", {
              state: { view: "regional", agencySelect: agencyId },
            });

            Toast({
              type: 1,
              content: "Successfully updated regional director details.",
            });
          }
        },
      });
    } else {
      await createRegionalDirector({
        formData,
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            setIsLoading(false);

            navigate("/directory", {
              state: { view: "regional", agencySelect: agencyId },
            });

            Toast({
              type: 1,
              content: "Successfully created regional director.",
            });
          }
        },
      });
    }
  };

  const handleOnSumbmit = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const formData = {
        lastName: formFields.lastName,
        firstName: formFields.firstName,
        contact: formFields.mobile?.slice(2),
        agencyId: selectedAgency.value,
        regionId: selectedRegion.value,
        cosFirstName: formFields.cosFirstName,
        cosLastName: formFields.cosLastName,
      };

      if (formFields.cosNumber) {
        formData.cosContact = formFields.cosNumber?.slice(2);
      }
      setIsLoading(true);
      await sendData(formData);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);

    await fetchRegions({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);

        setRegionOptions(newData);
      },
    });

    await fetchAllAgencies({
      callback: (data) => {
        const options = getFormattedOptions("name", data);

        setAgencyOptions(options);
      },
    });

    setIsLoading(false);
  };

  const setAgencyDropdown = () => {
    const agency = agencyOptions.find((_agency) => _agency.id === agencyId);

    if (agency) setSelectedAgency(agency);
  };

  useEffect(() => {
    //  fetch all regions, agency
    fetchData();
  }, []);

  useEffect(() => {
    if (director !== null) {
      setDirectorDetails();
    }
  }, [director]);

  useEffect(() => {
    if (director) {
      setAgencyId(director.agency.id);
    } else {
      setAgencyId(agencyDropdown);
    }

    if (director !== null && regionOptions.length) {
      setData();
    }

    if (agencyOptions.length) {
      setAgencyDropdown();
    }
  }, [regionOptions, agencyOptions]);

  return (
    <div className="add-project-container directory">
      <Col className="header">
        <Row className="d-flex justify-content-end w-100 m-0">
          <Col
            xs="6"
            className="d-flex mt-3 justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Directory",
                  redirect: "/directory",
                },
                {
                  name: !director
                    ? "Add Regional Director"
                    : "Regional Director Details",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header-actions h-75 d-flex m-0 mt-4 justify-content-between align-items-end">
          <h2 className="text-danger">
            {!director ? "Add Regional Director" : "Regional Director Details"}
          </h2>
        </Row>
        <div className="form-section">
          <DirectorForm
            handleOnSumbmit={handleOnSumbmit}
            formFields={formFields}
            setFormFields={setFormFields}
            regionOptions={regionOptions}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            regionError={regionError}
            agencyOptions={agencyOptions}
            selectedAgency={selectedAgency}
            setAgencyId={setAgencyId}
            setSelectedAgency={setSelectedAgency}
            agencyError={agencyError}
            isLoading={isLoading}
            agencyId={agencyId}
          />
        </div>
      </Col>
    </div>
  );
};

export default DirectorDetails;
