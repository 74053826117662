import BreadCrumbComponent from "components/customs/breadCrumb";
import PasswordInput from "components/customs/password-input/PasswordInput";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { Button, Col, Row } from "reactstrap";

const ChangePasswordComponent = () => {
  const location = useLocation();
  const { action } = location.state;

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [formFields, setFormFields] = useState({
    newpassword: "",
    confirmpassword: "",
  });

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value;
    const passwordInputFieldName = evnt.target.name;

    // for password
    if (passwordInputFieldName === "newpassword") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);

      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";

      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }

    // for confirm password
    if (
      passwordInputFieldName === "confirmpassword" ||
      (passwordInputFieldName === "newpassword" &&
        formFields.confirmpassword.length > 0)
    ) {
      if (formFields.confirmpassword !== formFields.newpassword) {
        setConfirmPasswordError("Confirm password does not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const resetForm = () => {
    setFormFields({
      newpassword: "",
      confirmpassword: "",
    });
  };

  const handleSubmit = async () => {
    //
  };

  return (
    <div className="add-project-container">
      <Col className="header">
        <Row className="d-flex justify-content-end w-100 m-0">
          <Col
            xs="6"
            className="d-flex mt-3 justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: action === "reset" ? "Accounts" : "Profile",
                  redirect: action === "reset" ? "/accounts" : "/account",
                },
                {
                  name: "Change Password",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header-actions h-75 d-flex m-0 align-items-end">
          <h2 className="text-danger">{"Change Password"}</h2>
        </Row>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          className="header-actions mt-5"
        >
          <Row className="m-0 align-items-center mb-4">
            <Col>
              <h4 className="font-weight-bold">{"New Password"}</h4>
            </Col>
            <Col>
              <PasswordInput
                type="password"
                name="newpassword"
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    newpassword: e.target.value,
                  });
                  handleValidation(e);
                }}
                value={formFields.newpassword}
              />

              <small className="error text-danger">{passwordError}</small>
            </Col>
          </Row>
          <Row className="m-0 align-items-center">
            <Col>
              <h4 className="font-weight-bold">{"Confirm New Password"}</h4>
            </Col>
            <Col>
              <PasswordInput
                type="password"
                name="confirmpassword"
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    confirmpassword: e.target.value,
                  });
                  handleValidation(e);
                }}
                value={formFields.confirmpassword}
              />

              <small className="error text-danger">
                {confirmPasswordError}
              </small>
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-5">
            <Button
              className="btn-cancel mr-1"
              onClick={() => resetForm()}
            >
              {"Reset"}
            </Button>
            <Button
              type="submit"
              className="btn-save"
            >
              {"Submit"}
            </Button>
          </div>
        </form>
      </Col>
    </div>
  );
};

export default ChangePasswordComponent;
