import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useEffect, useState } from "react";
import { Card, Col, UncontrolledTooltip } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Edit2, Trash2, UserPlus } from "react-feather";
import CustomDataTable from "components/customs/table";
import { getUserDetails } from "config";
import { Toast, hasPermission } from "utility/helpers";
import { deleteAccount, fethAllAcounts } from "services/services.accounts";
import Swal from "sweetalert2";
import { canEditRoles, congRoles, defaultTableOption } from "utility/constants";
import SearchBarComponent from "components/customs/searchbarV2";
import { fetchAllAgencies } from "services/services.projects";
import {
  AccountsTableBackground,
  AccountsTableContainer,
  AddAccountBtn,
  AddAccountSection,
  BreadCrumbSection,
  HeaderActions,
  SearchSection,
  TableSection,
} from "../styled";

const AccountsTable = () => {
  const [tableOption, setTableOption] = useState(defaultTableOption);
  const [dataTable, setDataTable] = useState([]);
  const [agencies, setAgencies] = useState([]);

  const userDetails = getUserDetails();

  const cong = userDetails?.congressman || null;

  const isRoleCongressman = congRoles.includes(userDetails.type);

  const navigate = useNavigate();

  let changeSearchTimeoutID;

  const fetchData = () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    fethAllAcounts({
      url: "/auth/list-users",
      params: {
        limit: tableOption.currentRowsPerPage,
        offset: tableOption.offset,
      },
      callback: (data) => {
        let newData =
          data.filter((d) => d?.userId !== userDetails?.userId) || [];

        if (userDetails.type === "Editor") {
          newData = newData.filter((d) => d.type !== "Director");
        }

        setDataTable(newData);

        setTableOption({
          ...tableOption,
          data: newData,
          totalRows: newData?.length,
          isLoading: false,
        });
      },
    });
  };

  const handleDeleteAction = (row) => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure you want to delete ${row?.firstName} account?`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setTableOption({
          ...tableOption,
          isLoading: true,
        });

        deleteAccount({
          params: row?.username,
          callback: ({ isSuccess }) => {
            if (isSuccess) {
              Toast({
                type: 1,
                content: "Successfully deleted user.",
              });
              fetchData();
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });

              setTableOption({
                ...tableOption,
                isLoading: false,
              });
            }
          },
        });
      }
    });
  };

  const fetchAgencies = () => {
    fetchAllAgencies({
      callback: (data) => {
        setAgencies(data);
      },
    });
  };

  const columns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      cell: () => null,
    },
    {
      name: "Last Name",
      selector: (row) => row?.lastName,
      grow: 2,
      sortable: true,
      minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ lastName }) => <p className="mb-0">{lastName}</p>,
    },
    {
      name: "First Name",
      selector: (row) => row?.firstName,
      grow: 2,
      sortable: true,
      minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ firstName }) => <p className="mb-0">{firstName}</p>,
    },
    {
      name: "Role",
      selector: (row) => row?.type,
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ type }) => <p className="mb-0">{type}</p>,
    },
    {
      name: "Agency",
      selector: (row) => row?.agency,
      grow: 2,
      sortable: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ agency, agencyIds, type }) => {
        let renderAgency = null;

        if (agencyIds && agencyIds.length !== 0) {
          const ag = agencies?.filter((_agency) =>
            agencyIds.includes(_agency?.id)
          );

          renderAgency =
            ag.length === agencies.length
              ? "All Agencies"
              : ag.map((a) => a.acronym)?.join(", ");
        } else if (type === "Agency") {
          renderAgency = agency;
        } else {
          renderAgency = "All Agencies";
        }

        return <p className="mb-0">{renderAgency}</p>;
      },
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: false,
      width: "100px",
      style: { cursor: "pointer" },
      center: true,
      id: "action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row, { userId }) => (
        <>
          {userDetails.type === "Congressman" ||
            (canEditRoles.includes(userDetails.type) &&
              hasPermission("user-update") && (
                <>
                  <Edit2
                    size={16}
                    id={`edit-row--${userId}`}
                    onClick={() =>
                      navigate("/account", {
                        state: {
                          row,
                          action: "edit",
                        },
                      })
                    }
                    className="mr-05"
                    color="#4252C9"
                  />

                  <UncontrolledTooltip
                    placement="auto"
                    target={`edit-row--${userId}`}
                  >
                    {"Edit"}
                  </UncontrolledTooltip>
                </>
              ))}

          {hasPermission("user-delete") && (
            <>
              <Trash2
                size={16}
                id={`delete-row--${userId}`}
                onClick={() => handleDeleteAction(row)}
                color="#4252C9"
              />
              <UncontrolledTooltip
                placement="right"
                target={`delete-row--${userId}`}
              >
                {"Delete"}
              </UncontrolledTooltip>
            </>
          )}
        </>
      ),
    },
  ];

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value;

      const res = dataTable.filter(
        (data) =>
          data?.firstName?.toLowerCase()?.includes(eventValue) ||
          data?.lastName?.toLowerCase()?.includes(eventValue) ||
          data?.middleName?.toLowerCase()?.includes(eventValue) ||
          data?.cosFirstName?.toLowerCase()?.includes(eventValue) ||
          data?.cosLastName?.toLowerCase()?.includes(eventValue) ||
          data?.cosMiddleName?.toLowerCase()?.includes(eventValue) ||
          data?.cosName?.toLowerCase()?.includes(eventValue)
      );

      setTableOption({
        ...tableOption,
        data: res,
      });
    }, 1000);
  };

  useEffect(() => {
    fetchData();
    fetchAgencies();
  }, []);

  return (
    <div className="add-project-container accounts-list">
      <AccountsTableBackground>
        <AccountsTableContainer>
          <BreadCrumbSection>
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: isRoleCongressman
                    ? `/representatives/${cong.region.id}/cong/${cong.id}`
                    : "/home",
                },
                {
                  name: "Accounts",
                  isActive: true,
                },
              ]}
            />
          </BreadCrumbSection>
          <HeaderActions>
            <Col className="pl-0">
              <h2 className="pb-0 mb-0 text-danger">{"Accounts"}</h2>
            </Col>
            <AddAccountSection>
              {hasPermission("user-create") && (
                <Link
                  to="/add-account"
                  state={{
                    action: "add",
                  }}
                >
                  <AddAccountBtn>
                    <UserPlus />
                    <p>{"Add Account"}</p>
                  </AddAccountBtn>
                </Link>
              )}
            </AddAccountSection>
          </HeaderActions>
          <TableSection>
            <Card className="mb-5">
              <SearchSection>
                <div className="position-relative">
                  <SearchBarComponent
                    onChangeSearch={onChangeSearch}
                    isDisabled={tableOption?.isLoading}
                  />
                </div>
              </SearchSection>
              <CustomDataTable
                columns={columns}
                data={tableOption.data}
                noHeader
                progressPending={tableOption.isLoading}
                height="calc(100vh - 200px)"
              />
            </Card>
          </TableSection>
        </AccountsTableContainer>
      </AccountsTableBackground>
    </div>
  );
};

export default AccountsTable;
