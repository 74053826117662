import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import ImgContainer from "components/common/img";
import { useNavigate } from "react-router";

import "./styles.scss";

const PartyContainer = (props) => {
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate(props?.link);
  };

  return (
    <Row
      className="party-container py-2 px-0 m-0"
      onClick={handleOnClick}
    >
      <Col xs="auto">
        <ImgContainer
          alt="party-logo"
          height="90px"
          width="90px"
          src={props.photo}
        />
      </Col>
      <Col className="pl-0">
        <h6 className="party-container--label mb-0">{props?.label}</h6>
      </Col>
    </Row>
  );
};

PartyContainer.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
};

export default PartyContainer;
