import React from "react";
import Layout3 from "components/layout/Layout3";
import Agency from "./agency";

const AgencyComponent = (props) => {
  return (
    <Layout3>
      <Agency {...props} />
    </Layout3>
  );
};

export default AgencyComponent;
