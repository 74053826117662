import styled from "styled-components";
import { Button } from "reactstrap";

/* Assets */
import addHeaderBG from "../../../assets/img/BG_1.png";

const MainContainer = styled.div`
  background: url(${addHeaderBG}) no-repeat;
  background-position: bottom;
  height: 200px;
  background-size: cover;
`;

const BreadCrumbSection = styled.div`
  display: grid;
  justify-content: flex-end;
`;

const HeaderSection = styled.div``;

const HeaderTextSection = styled.div`
  margin-top: 1.5rem;
  height: 100px;
  display: grid;
  align-items: flex-end;
`;

const FormSection = styled.div`
  margin-top: 3rem;
  padding-bottom: 5rem;

  #recipient-form {
    .form-control[type="text"] {
      background: #f5f5f7;
      border-radius: 16px;
      height: 60px;
    }
  }
`;

const Container = styled.div`
  margin-right: 8rem;
  margin-left: 8rem;
`;

const CancelBtn = styled(Button)`
  background: #f3f3f3;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  height: 50px;
  width: 140px;
  color: black;
  font-weight: 600;
  border: 0.5px solid #656eb2;
`;

const SaveBtn = styled(Button)`
  background: #4252c9;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  height: 50px;
  width: 140px;
`;

const BtnSection = styled.div`
  display: grid;
  justify-content: flex-end;
  margin-top: 3rem;
  grid-template-columns: auto auto;
`;

export {
  MainContainer,
  BreadCrumbSection,
  HeaderSection,
  FormSection,
  HeaderTextSection,
  Container,
  CancelBtn,
  SaveBtn,
  BtnSection,
};
