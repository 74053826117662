import React from "react";
import Main from "./Main";
import Layout2 from "../../components/layout/Layout2";

const Representatives = (props) => {
  return (
    <Layout2>
      <Main {...props} />
    </Layout2>
  );
};

export default Representatives;
