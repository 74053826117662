/* eslint-disable camelcase */
const awsconfig = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  aws_app_access_id: process.env.REACT_APP_ACCESS_ID,
  aws_app_access_key: process.env.REACT_APP_ACCESS_KEY,
};

export default awsconfig;
