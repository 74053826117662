import React from "react";
import { Progress } from "reactstrap";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import { getFormattedNumber } from "utility/helpers";

import "./styles.scss";
import "react-tooltip/dist/react-tooltip.css";

const StackedProgressBar = ({ data }) => {
  const getColor = (budget) => {
    // if budget is remaining balance
    if (budget === null) return "#F0F0FF";

    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  return (
    <Progress
      multi
      className="custom-progress-bar"
    >
      {data.length !== 0 &&
        data?.map((bar) => {
          const color =
            bar?.name === "Remaining Balance" ? getColor(null) : getColor();

          return (
            <React.Fragment key={bar.id}>
              <Tooltip
                anchorSelect={`.bar--${bar?.id}`}
                opacity={1}
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#2E2828",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
                }}
              >
                <div className="d-flex justify-content-center font-weight-bold">
                  {bar?.name}
                </div>

                <div className="d-flex justify-content-center">
                  {getFormattedNumber(bar?.value)}
                </div>
              </Tooltip>
              <Progress
                bar
                className={`bar--${bar?.id}`}
                id={`bar--${bar?.id}`}
                style={{ backgroundColor: color }}
                value={bar?.budget}
              />
            </React.Fragment>
          );
        })}
    </Progress>
  );
};

export default React.memo(StackedProgressBar);

StackedProgressBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};
