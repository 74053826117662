import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    // primary: "#ED7C52",
    // secondary: "#1D5C87",
    // secondaryActive: "#174B6D",
    // text: "#1A6698",
    black: "#000",
    white: "#FFF",
    // disabled: "#C4C4C4",
  },
};

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
