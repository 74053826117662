import api from "./api";

const navbarSearch = async (inputValue, filter, callback) => {
  await api({
    Method: "GET",
    Url: `/dashboard/search?filter=${filter}&search=${inputValue}`,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(
          data?.map((item) => ({
            ...item,
            label: item?.name,
            value: item?.id,
          }))
        );
      }
    },
    ResponseFailCallback: () => {
      callback([]);
    },
  });
};

const congressmanSearch = async ({ inputValue, callback }) => {
  await api({
    Method: "GET",
    Url: `/congressman?search=${inputValue}`,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(
          data?.map((item) => ({
            ...item,
            label: item?.name,
            value: item?.id,
          }))
        );
      }
    },
    ResponseFailCallback: () => {
      callback([]);
    },
  });
};

export { navbarSearch, congressmanSearch };
