import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import { getFormattedNumber } from "utility/helpers";
import PropTypes from "prop-types";

const BudgetComponent = ({
  value,
  handleOnChange,
  isOpen,
  handleToggle,
  newValue,
  totalAmountSpent,
}) => {
  const [budget, setBudget] = useState();

  const handleSaveClicked = () => {
    if (budget < totalAmountSpent) {
      setBudget(value);

      Swal.fire({
        title: "Error",
        text: `New budget must be higher than ${getFormattedNumber(
          totalAmountSpent
        )}`,
        icon: "error",
        confirmButtonText: "OK",
      });

      return;
    }

    if (budget > totalAmountSpent) {
      handleOnChange(budget);
      handleToggle();
    }
  };

  const handleToggleClicked = () => {
    setBudget(value);
    handleToggle();
  };

  useEffect(() => {
    if (value !== budget) setBudget(value);
  }, [value]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleToggleClicked}
      centered
    >
      <ModalHeader toggle={handleToggleClicked}>{"Total Budget"}</ModalHeader>
      <ModalBody>
        <div className="mx-4">
          <NumericFormat
            className="form-control edit-budget mb-4"
            decimalSeparator="."
            thousandSeparator=","
            value={newValue || budget}
            onValueChange={(newBudget) => {
              const { floatValue } = newBudget;
              setBudget(floatValue);
            }}
          />
          <div className="mb-2 d-flex justify-content-end">
            <Button
              className="modal-save-btn "
              onClick={() => handleSaveClicked()}
            >
              {"Save\r"}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

BudgetComponent.prototype = {
  value: PropTypes.string,
  handleOnChange: PropTypes.func,
  isOpen: PropTypes.bool,
  handleToggle: PropTypes.func,
  newValue: PropTypes.string,
  totalAmountSpent: PropTypes.string,
};

export default BudgetComponent;
