import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dateFrom: null,
  dateTo: null,
};

export const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    changeDateRangeFrom: (state, action) => {
      return { ...state, dateFrom: action.payload };
    },

    changeDateRangeTo: (state, action) => {
      return { ...state, dateTo: action.payload };
    },
  },
});

export const { changeDateRangeFrom, changeDateRangeTo } =
  dateRangeSlice.actions;

export default dateRangeSlice.reducer;
