import React, { useState, useEffect } from "react";
import { Button, FormGroup, Spinner, Label, Input } from "reactstrap";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import { useFormInputValidation } from "react-form-input-validation";
import { getLocalSession, Toast } from "utility/helpers";
import {
  getAccessToken,
  APP_URL,
  setUserDetails,
  setUserPermissions,
} from "config";
import axios from "axios";
import { congRoles } from "utility/constants";

const LoginForm = ({ handleNewPasswordRequired }) => {
  const [fields, errors, form] = useFormInputValidation(
    {
      email: "",
      password: "",
    },
    {
      email: "required|email",
      password: "required",
    }
  );

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const navigate = useNavigate();

  const getAuthenticatedUser = async (token) => {
    try {
      const response = await axios.get(`${APP_URL}/auth/get-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response?.data) {
        setUserDetails(response.data.data);
        const cong = response.data.data?.congressman;
        const accountType = response.data.data.type;

        setTimeout(() => {
          setIsLoggingIn(false);

          if (accountType && congRoles.includes(accountType)) {
            // eslint-disable-next-line max-len
            window.location.href = `/representatives/${cong.region.id}/cong/${cong.id}`;
          } else {
            window.location.href = "/";
          }
        }, 1500);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      getLocalSession();
    }
  };

  const getPermissions = async (token) => {
    try {
      const response = await axios.get(`${APP_URL}/auth/list-permissions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response && response?.data) {
        const permissions = response.data.data[0]?.permissions;
        setUserPermissions(permissions);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      getLocalSession();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    localStorage.clear();
    setIsLoggingIn(!isLoggingIn);

    const isValid = form.validate(e);

    if (isValid) {
      Auth.signIn(fields.email, fields.password)
        .then(async (user) => {
          if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
            // show change password form
            handleNewPasswordRequired();
          } else {
            await getPermissions(user.signInUserSession.accessToken.jwtToken);

            await getAuthenticatedUser(
              user.signInUserSession.accessToken.jwtToken
            );
          }
        })
        .catch((Exception) => {
          setIsLoggingIn(false);

          Toast({
            type: 4,
            content: Exception?.message,
          });
        });
    }
  };

  useEffect(() => {
    // if user has user token, redirect to home
    if (window.location.pathname === "/login" && getAccessToken() !== null) {
      navigate("/home");
    }
  }, []);

  return (
    <form
      onSubmit={handleOnSubmit}
      className="login-form col-10 col-sm-6 col-lg-8"
    >
      <div className="login-card--header mb-5">
        <h1 className="login-card--header-text">{"USER LOGIN"}</h1>
        <small className="text-muted">{"Login to your account"}</small>
      </div>
      <FormGroup className="mb-4">
        <Label>{"Email"}</Label>
        <span className="form-control-feedback">
          <FontAwesomeIcon icon={faEnvelope} />
        </span>

        <Input
          type="email"
          className="form-control"
          name="email"
          onBlur={form.handleBlurEvent}
          onChange={form.handleChangeEvent}
        />

        <small className="error text-danger">
          {errors.email ? errors.email : ""}
        </small>
      </FormGroup>
      <FormGroup className="mb-4">
        <Label>{"Password"}</Label>
        <span className="form-control-feedback">
          <FontAwesomeIcon icon={faLock} />
        </span>
        <Input
          type="password"
          className="form-control"
          name="password"
          onBlur={form.handleBlurEvent}
          onChange={form.handleChangeEvent}
        />

        <small className="error text-danger">
          {errors.password ? errors.password : ""}
        </small>
      </FormGroup>

      <div className="d-flex justify-content-start mt-5">
        <Button
          type="submit"
          className="submit-btn"
        >
          {isLoggingIn ? (
            <Spinner
              animation="border"
              color="light"
              size="sm"
            />
          ) : (
            "Login"
          )}
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
