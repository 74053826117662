import React from "react";
import PropTypes from "prop-types";
import { Badge, Col, Row } from "reactstrap";
import ImgContainer from "components/common/img";
import { useNavigate } from "react-router";

import "./styles.scss";

const AgencyContainer = (props) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(props?.link);
  };

  return (
    <div className="m-0 p-0 project-container">
      <div className="badge-container">
        {props?.requestedCount !== 0 && (
          <Badge
            className={`requested-badge  ${
              props?.requestedCount === 0 ? "is-hidden" : ""
            }`}
            pill
          >
            <div>{props?.requestedCount}</div>
          </Badge>
        )}

        {props?.pendingCount !== 0 && (
          <Badge
            className="ml-25"
            pill
          >
            <div>{props?.pendingCount}</div>
          </Badge>
        )}
      </div>
      <Row
        className="program-container py-2 px-0 m-0"
        onClick={handleOnClick}
      >
        <Col xs="auto">
          <ImgContainer
            height="90px"
            src={props?.photo}
          />
        </Col>
        <Col className="pl-0">
          <h6 className="program-container--label mb-0">
            {`${props?.label} (${props?.acronym})`}
          </h6>
        </Col>
      </Row>
    </div>
  );
};

AgencyContainer.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
};

export default AgencyContainer;
