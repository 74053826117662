import Theme from "assets/js/Theme";
import GlobalStyle from "assets/js/GlobalStyles";
import React, { Suspense } from "react";
import { ToastContainer, Flip } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "routes/PrivateRoutes";
import { Auth } from "aws-amplify";
import awsconfig from "config/aws-config";
import { Spinner } from "reactstrap";
import { publicRoutes } from "routes/public";
import { privateRoutes } from "routes/private";
import NotFound from "views/404";
import { history } from "./history";

const App = () => {
  Auth.configure(awsconfig);

  const routes = [...publicRoutes, ...privateRoutes];

  return (
    <Theme>
      <GlobalStyle />
      <Suspense fallback={<Spinner />}>
        <ToastContainer
          transition={Flip}
          pauseOnFocusLoss={false}
        />
        <Router history={history}>
          <Routes>
            <Route
              exact
              element={<PrivateRoutes />}
            >
              {/* create page not found redirect to home button */}
              {routes.map(({ exact, path, element, HasPermisson }, index) => (
                <Route
                  exact={exact}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  path={path}
                  element={HasPermisson ? element : <NotFound />}
                />
              ))}
            </Route>
            <Route
              path="*"
              element={<NotFound />}
            />
          </Routes>
        </Router>
      </Suspense>
    </Theme>
  );
};

export default App;
