import React from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import FormGenertor from "components/customs/form-generator/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { Plus } from "react-feather";
import { NewsCustomFormStyle } from "utility/constants";
import { genericGet } from "services/services.general";
import {
  CustomForm,
  CustomFormAddRowSection,
  CustomFormContainer,
  CustomFormDeleteRowIcon,
} from "../styled";
import "react-perfect-scrollbar/dist/css/styles.css";

const defaultSelectedCongressmen = {
  name: null,
  area: "",
  contact: "63",
};

const CustomCongressmanSelection = ({ action, setRecipients, recipients }) => {
  const [selectedCongs, setSelectedCongs] = React.useState([
    defaultSelectedCongressmen,
  ]);

  const handleSearch = _.debounce((inputValue, callback) => {
    genericGet({
      url: "/congressman",
      params: {
        search: inputValue,
      },
      callback: (data) => {
        if (data?.length) {
          const options = data
            ?.filter((d) => !recipients.include.includes(d.id))
            ?.map((item) => ({
              ...item,
              label: item?.name,
              value: item?.id,
            }));

          callback(options);
        } else {
          callback([]);
        }
      },
    });
  }, 1000);

  const handleSearchOnChange = (option, idx) => {
    const newSelectedCongs = [...selectedCongs];

    newSelectedCongs[idx] = {
      name: {
        label: option.name,
        value: option.id,
      },
      contact: option.contact,
      area: option?.region?.acronym || "",
    };

    setSelectedCongs(newSelectedCongs);

    setRecipients({
      ...recipients,
      include: [...recipients.include, option.id],
    });
  };

  const handleDeleteRow = (index) => {
    const newSelectedCongs = [...selectedCongs];
    newSelectedCongs.splice(index, 1);
    setSelectedCongs(newSelectedCongs);
  };

  const handleAddRow = () => {
    const newSelectedCongs = [...selectedCongs];
    newSelectedCongs.push(defaultSelectedCongressmen);
    setSelectedCongs(newSelectedCongs);
  };

  return (
    <CustomFormContainer>
      {/* <PerfectScrollbar
        options={{
          wheelPropagation: false,
          suppressScrollX: true,
        }}
        style={{ maxHeight: "400px" }}
      > */}
      {selectedCongs.map((cong, idx) => (
        <CustomForm>
          <AsyncSelect
            className="asyc-dropdown w-100"
            classNamePrefix="select"
            placeholder="Name"
            styles={NewsCustomFormStyle}
            value={cong.name}
            onChange={(option) => {
              handleSearchOnChange(option, idx);
            }}
            loadOptions={handleSearch}
            isDisabled={action === "view"}
          />

          <FormGenertor
            type="text"
            placeholder="Area"
            value={cong.area}
            isDisabled
            className="news--custom-form"
            onChange={(event) => {
              const {
                target: { value },
              } = event;

              const newSelectedCongs = [...selectedCongs];

              newSelectedCongs[idx] = {
                ...newSelectedCongs[idx],
                area: value,
              };

              setSelectedCongs(newSelectedCongs);
            }}
          />

          <FormGenertor
            type="mobile"
            placeholder="Contact Number"
            value={cong.contact}
            className="news--custom-form"
            isDisabled
            onChange={(value) => {
              const newSelectedCongs = [...selectedCongs];

              newSelectedCongs[idx] = {
                ...newSelectedCongs[idx],
                contact: value,
              };

              setSelectedCongs(newSelectedCongs);
            }}
          />

          {idx !== 0 && (
            <CustomFormDeleteRowIcon
              aria-hidden
              onClick={() => handleDeleteRow(idx)}
            >
              <FontAwesomeIcon
                size="xl"
                color="#4252C9"
                icon={faCircleXmark}
                style={{
                  cursor: "pointer",
                }}
              />
            </CustomFormDeleteRowIcon>
          )}
        </CustomForm>
      ))}
      {/* </PerfectScrollbar> */}

      <CustomFormAddRowSection>
        <Button
          className="mt-4"
          onClick={handleAddRow}
        >
          <Plus className="mr-25" />
          {"Add Row"}
        </Button>
      </CustomFormAddRowSection>
    </CustomFormContainer>
  );
};

export default CustomCongressmanSelection;
