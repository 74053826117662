import React from "react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";

const DropzoneComponent = ({
  handleOnDrop,
  isDisabled = false,
  className,
  label = "",
  uploadIcon,
}) => {
  return (
    <Dropzone
      disabled={isDisabled}
      onDrop={handleOnDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <section
          className={className}
          {...getRootProps()}
        >
          <div className="dropzone upload-files">
            {uploadIcon}
            {label}
            <input {...getInputProps()} />
          </div>
        </section>
      )}
    </Dropzone>
  );
};

DropzoneComponent.prototype = {
  handleOnDrop: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  uploadIcon: PropTypes.element,
};

export default DropzoneComponent;
