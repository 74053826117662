import React, { useState } from "react";
import PropTypes from "prop-types";

import "./index.scss";

const SeeMoreOrLess = React.memo(
  ({
    text = "",
    min = 50,
    seeMore,
    seeLess,
    id = "",
    isTextExpanded,
    insertOrRemoveExpandedSeeMoreOrLessTexts,
    showSeeMoreOrLess = true,
  }) => {
    const [isExpanded, setIsExpanded] = useState(
      isTextExpanded !== undefined ? isTextExpanded(id) : false
    );
    let returnValue = text;

    const onClickExpand = () => {
      if (
        insertOrRemoveExpandedSeeMoreOrLessTexts !== undefined &&
        id !== undefined
      ) {
        const insertOrRemoveExpandedSeeMoreOrLessTextsValue =
          insertOrRemoveExpandedSeeMoreOrLessTexts(id);

        setIsExpanded(insertOrRemoveExpandedSeeMoreOrLessTextsValue);
      } else {
        setIsExpanded(!isExpanded);
      }
    };

    if (!isExpanded && text.length > min) {
      returnValue = `${text.slice(0, min)}...`;
    }

    return (
      <span className="text-see-more-or-less__MRJbjC">
        <span>{returnValue}</span>
        {text.length > min && showSeeMoreOrLess && (
          <>
            &nbsp;
            <span
              className="trigger__Nzngkk"
              aria-hidden="true"
              onClick={() => {
                onClickExpand();
              }}
            >
              {isExpanded ? seeLess || "See Less" : seeMore || "SeeMore"}
            </span>
          </>
        )}
      </span>
    );
  }
);

SeeMoreOrLess.prototype = {
  text: PropTypes.string.isRequired,
  min: PropTypes.number,
  seeMore: PropTypes.string,
  seeLess: PropTypes.string,
  id: PropTypes.string,
  isTextExpanded: PropTypes.bool,
  showSeeMoreOrLess: PropTypes.bool,
  insertOrRemoveExpandedSeeMoreOrLessTexts: PropTypes.string,
};

export default SeeMoreOrLess;
