import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ImgContainer from "components/common/img";
import BreadCrumbComponent from "components/customs/breadCrumb";
import SearchBarComponent from "components/customs/searchbarV2";
import CustomDataTable from "components/customs/table";
import CustomSelect from "components/customs/dropdown";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  fetchCongressmen,
  fetchPartylists,
} from "services/services.affiliation";
import {
  DropdownIndicator,
  defaultTableOption,
  customProjectStyles,
} from "utility/constants";

const Main = () => {
  const [tableOption, setTableOption] = useState(defaultTableOption);
  const [selectedProject, setSelectedProject] = useState(null);
  const [partyDetails, setPartyDetails] = useState([]);
  const [selectorOptions, setSelectorOptions] = useState([]);
  const [data, setData] = useState([]);
  const [partyList, setPartyList] = useState([]);

  const { id } = useParams();

  const navigate = useNavigate();

  let changeSearchTimeoutID;

  const columns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: () => null,
    },
    {
      name: "Name",
      selector: (row) => row?.fullName,
      grow: 2,
      sortable: true,
      minWidth: "130px",
      style: { cursor: "pointer" },
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => <p className="mb-0">{row?.fullName}</p>,
    },
    {
      name: "District",
      selector: (row) => row?.District,
      grow: 2,
      minWidth: "120px",
      sortable: true,
      style: { cursor: "pointer" },
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => <p className="mb-0">{row?.District?.name}</p>,
    },
  ];

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value;

      const newData = data.filter(
        (__data) =>
          __data.fullName
            .toLowerCase()
            .includes(eventValue.toLowerCase().trim()) ||
          __data.District.name
            .toLowerCase()
            .includes(eventValue.toLowerCase().trim())
      );

      setTableOption({
        ...tableOption,
        page: 1,
        data: newData,
      });
    }, 1000);
  };

  const handleSelectOnChange = (option) => {
    setSelectedProject(option);
  };

  const fetchTable = async () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    await fetchCongressmen({
      param: selectedProject.ID,
      callback: (Data) => {
        const newData = Data?.length !== 0 ? Data : [];
        setData(newData);

        setTableOption({
          ...tableOption,
          data: newData,
          isLoading: false,
          totalRows: newData?.length,
        });
      },
    });
  };

  const getSelectorOptions = (rawOptions) => {
    if (rawOptions?.length !== 0) {
      const options =
        rawOptions?.map((select) => ({
          ID: select?.id,
          Name: select?.name,
          label: select?.name,
        })) || [];

      setSelectorOptions(options);
    }
  };

  const fetchParties = async () => {
    await fetchPartylists({
      callback: (Data) => {
        const newData = Data?.length !== 0 ? Data : [];
        getSelectorOptions(newData);
        setPartyList(newData);
      },
    });
  };

  const handleOnRowClicked = (row) => {
    navigate(`/representatives/${row?.District?.RegionId}/cong/${row.id}`);
  };

  useEffect(() => {
    fetchTable();
    fetchParties();
  }, []);

  useEffect(() => {
    if (selectedProject !== null) fetchTable();
  }, [selectedProject]);

  useEffect(() => {
    if (selectorOptions?.length !== 0 && partyList?.length !== 0) {
      // eslint-disable-next-line array-callback-return
      partyList?.map((op, index) => {
        if (op?.id === Number(id)) {
          setSelectedProject(selectorOptions[index]);
          setPartyDetails(partyList[index]);
        }
      });
    }
  }, [partyList]);

  return (
    <div className="p-0 m-0 part-details">
      <Row className="d-flex justify-content-end mt-5 mx-5">
        <Col
          xs="auto"
          className="custom-breadcrumb--comtainer"
        >
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "Affiliation",
                redirect: "/parties",
              },
              {
                name: partyDetails?.name,
                isActive: true,
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="p-0 mb-4 mx-5 d-flex align-items-center select-container">
        <Col>
          <Row className="d-flex align-items-center">
            <Col
              xs="auto"
              className="p-0 party-logo"
            >
              {/* agency logo here */}
              <ImgContainer
                src={partyDetails.photo}
                height="150px"
              />
            </Col>
            <Col
              xs="12"
              sm="8"
              md="7"
              lg="7"
              xl="8"
              className="party-select-list"
            >
              {/* agency select dropwdown here */}
              <CustomSelect
                isLoading={tableOption.isLoading}
                isDisabled={tableOption.isLoading}
                onChange={(option) => handleSelectOnChange(option)}
                value={selectedProject}
                options={selectorOptions}
                styles={customProjectStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
              />
            </Col>
          </Row>
        </Col>

        <Col
          xs="5"
          className="d-flex align-items-center justify-content-end search-section"
        >
          {/* searchbar here */}
          <div className="position-relative">
            <SearchBarComponent
              onChangeSearch={onChangeSearch}
              isDisabled={tableOption?.isLoading}
            />
          </div>
        </Col>
      </Row>
      <Row className="p-0 d-flex mx-5 row-section">
        <Col xs="auto" />
        <Col className="card-container">
          <Row className="d-flex justify-content-between mb-3 align-items-center">
            <Col>
              <h5 className="text-danger">
                {"Members of the House of Representatives"}
              </h5>
            </Col>
          </Row>
          {/* table here */}
          <Card className="mb-5 p-0">
            <CardBody>
              <CustomDataTable
                columns={columns}
                data={tableOption.data}
                noHeader
                progressPending={tableOption.isLoading}
                sortServer
                height="calc(100vh - 200px)"
                onRowClicked={handleOnRowClicked}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Main;
