import { Toast } from "utility/helpers";
import api from "./api";

export const fetchUtilizationReports = ({ url, params, callback }) => {
  api({
    Url: url,
    Params: params,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(data);
      }
    },
  });
};

export const fetchUtilizationReport = ({ url, params, callback }) => {
  api({
    Url: url,
    Params: params,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(data);
      }
    },
  });
};

export const createUtilizationReport = ({ formData, callback }) => {
  api({
    Url: "/utilization",
    Method: "POST",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 201) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: true });
    },
  });
};

export const updateUtilizationReport = ({ param, formData, callback }) => {
  api({
    Url: `/utilization/${param}`,
    Method: "PUT",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: true });
    },
  });
};

export const deleteUtilizationReport = ({ param, callback }) => {
  api({
    Url: `utilization/${param}`,
    Method: "DELETE",
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: true });
    },
  });
};

export const fetchOthersList = ({ url, callback }) => {
  api({
    Url: url,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(data);
      }
    },
  });
};

export const fetchUtilizationSetting = ({ url, callback }) => {
  api({
    Url: url,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(data);
      }
    },
  });
};

export const updateUtilizationSetting = ({ url, formData, callback }) => {
  api({
    Url: url,
    Method: "PUT",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: true });
    },
  });
};
