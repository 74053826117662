import CustomSpinner from "components/customs/spinner/CustomSpinner";
import React, { useEffect } from "react";
import { Toast } from "utility/helpers";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";

const Logout = () => {
  const navigate = useNavigate();

  const logoutUser = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      navigate("/login");
    } catch (Exception) {
      Toast({
        type: 4,
        content: Exception?.message,
      });
    }
  };

  useEffect(() => {
    logoutUser();
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <CustomSpinner alignment="center" />
    </div>
  );
};

export default Logout;
