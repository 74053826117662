import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import "./styles.scss";

const BreadCrumbComponent = (props) => {
  const { links } = props;

  return (
    <Breadcrumb>
      {links &&
        links?.length !== 0 &&
        links?.map((link, index) => {
          return (
            <BreadcrumbItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="custom-breadcrumb--item"
              active={link?.isActive}
            >
              <a href={link?.redirect}>{link.name}</a>
            </BreadcrumbItem>
          );
        })}
    </Breadcrumb>
  );
};

BreadCrumbComponent.propTypes = {
  links: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

export default BreadCrumbComponent;
