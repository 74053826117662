import React from "react";
import Layout3 from "components/layout/Layout3";
import Mayor from "./Mayor";

const MayorDetails = (props) => {
  return (
    <Layout3>
      <Mayor {...props} />
    </Layout3>
  );
};

export default MayorDetails;
