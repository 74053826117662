import React from "react";
import * as Yup from "yup";
import styled from "styled-components";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import classNames from "classnames";
import FormGenertor from "components/customs/form-generator/input";
import CustomSelect from "components/customs/dropdown";
import { DropdownIndicator, accountCustomStyle } from "utility/constants";
import { useNavigate } from "react-router";

const StyledRed = styled.span`
  color: red;
`;
const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const validSchema = Yup.object().shape({
  lastname: Yup.string().required("Required"),
  firstname: Yup.string().required("Required"),
  mobile: Yup.string()
    .min(12, "At least 12 digits")
    .max(12, "Cannot be more than 12 digits")
    .matches(/^(639)\d{9}$/, "Invalid format")
    .required("Required"),
});

const CongressmanForm = ({
  handleOnSumbmit,
  formFields,
  setFormFields,
  districtOptions,
  selectedDistrict,
  setSelectedDistrict,
  districtError,
  partylists,
  selectedPartylist,
  setSelectedPartylist,
  partylistError,
  isLoading,
}) => {
  const navigate = useNavigate();

  return (
    <Formik
      validationSchema={validSchema}
      onSubmit={handleOnSumbmit}
      initialValues={formFields}
      enableReinitialize
    >
      {({ handleChange, values }) => (
        <Form id="add-account-form">
          <Field name="lastname">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"Last Name"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="lastname"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.lastname && touched?.lastname,
                    })}
                    value={values.lastname}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        lastname: evt.target.value || "",
                      });
                    }}
                  />

                  <ErrorMessage name="lastname">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="firstname">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"First Name"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="firstname"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.firstname && touched?.firstname,
                    })}
                    value={values.firstname}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        firstname: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="firstname">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="middleInitial">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">{"Middle Name"}</h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="middleInitial"
                    className={classNames("form-control input", {
                      "is-invalid":
                        errors?.middleInitial && touched?.middleInitial,
                    })}
                    value={values.middleInitial}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        middleInitial: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="middleInitial">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="mobile">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"Contact Number"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <FormGenertor
                    name="mobile"
                    type="mobile"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.mobile && touched?.mobile,
                    })}
                    value={values.mobile || "63"}
                    onChange={(value) => {
                      handleChange(value);

                      setFormFields({
                        ...formFields,
                        mobile: value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="mobile">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <h5 className="">
                {"District"}
                {RequiredIndicator}
              </h5>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <CustomSelect
                placeholder=""
                name="district"
                styles={accountCustomStyle}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={districtOptions}
                value={selectedDistrict}
                onChange={(option) => setSelectedDistrict(option)}
              />
              <small className="field-error text-danger">{districtError}</small>
            </Col>
          </Row>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <h5 className="">
                {"Party"}
                {RequiredIndicator}
              </h5>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <CustomSelect
                placeholder=""
                name="party"
                styles={accountCustomStyle}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={partylists}
                value={selectedPartylist}
                onChange={(option) => setSelectedPartylist(option)}
              />
              <small className="field-error text-danger">
                {partylistError}
              </small>
            </Col>
          </Row>

          <Field name="cosFirstName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">{"Chief of Staff First Name"}</h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="cosFirstName"
                    className={classNames("form-control input", {
                      "is-invalid":
                        errors?.cosFirstName && touched?.cosFirstName,
                    })}
                    value={values.cosFirstName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        cosFirstName: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="cosFirstName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="cosLastName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">{"Chief of Staff Last Name"}</h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="cosLastName"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.cosLastName && touched?.cosLastName,
                    })}
                    value={values.cosLastName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        cosLastName: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="cosLastName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="cosNumber">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">{"Contact Number"}</h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <FormGenertor
                    type="mobile"
                    name="cosNumber"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.cosNumber && touched?.cosNumber,
                    })}
                    value={values.cosNumber || "63"}
                    onChange={(value) => {
                      handleChange(value);

                      setFormFields({
                        ...formFields,
                        cosNumber: value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="cosNumber">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-5  d-flex justify-content-end">
            <Button
              className="btn-cancel mr-1"
              disabled={isLoading}
              onClick={() => navigate("/directory")}
            >
              {"Back"}
            </Button>

            <Button
              type="submit"
              className="btn-save"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="light"
                  size="sm"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default CongressmanForm;
