import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const CustomCheckbox = (props) => {
  return (
    <div
      className={`vx-checkbox-con custom-vx-checkbox-con ${
        props.className ? props.className : ""
      } vx-checkbox-custom`}
    >
      <input
        type="checkbox"
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        value={props.value}
        disabled={props.disabled}
        onClick={props.onClick ? props.onClick : null}
        onChange={props.onChange ? props.onChange : null}
        name={props?.name || null}
      />
      <span
        id={props.id}
        className={`vx-checkbox vx-checkbox-${props.size ? props.size : "md"}`}
      >
        <span className="vx-checkbox--check">{props.icon}</span>
      </span>
      {props?.label}
    </div>
  );
};

export default CustomCheckbox;

CustomCheckbox.propTypes = {
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
};
