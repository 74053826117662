import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { DEBUG } from "config";
import { store, persistor } from "./redux/store";
import App from "./App";

import "assets/scss/app.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const debug = DEBUG === "true";

if (!debug) {
  // eslint-disable-next-line func-names, no-console
  console.log = function () {};
}

const app = (
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <App />
    </PersistGate>
  </Provider>
);

root.render(debug ? <React.StrictMode>{app}</React.StrictMode> : app);
