import React from "react";
import Layout3 from "components/layout/Layout3";
import Account from "./Account";

const AccountList = (props) => {
  return (
    <Layout3>
      <Account {...props} />
    </Layout3>
  );
};

export default AccountList;
