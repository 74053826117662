import React, { useEffect } from "react";
import SearchBarComponent from "components/customs/searchbarV2";
import { defaultTableOption } from "utility/constants";
import CustomDataTable from "components/customs/table";
import CustomCheckbox from "components/customs/checkbox";
import { Check } from "react-feather";
import { getFormattedContactNumber } from "utility/helpers";
import {
  RecipientsTable,
  RecipientsTableContainer,
  RecipientsTableHeader,
} from "../styled";
import CustomCongressmanSelection from "./Custom";

const RecipientTable = ({
  data,
  action,
  setRecipients,
  recipients,
  isCustomSelected,
}) => {
  const [tableOption, setTableOption] = React.useState(defaultTableOption);

  let changeSearchTimeoutID;

  const onChangeSearch = (event) => {
    event.persist();

    if (changeSearchTimeoutID) {
      clearTimeout(changeSearchTimeoutID);
    }

    changeSearchTimeoutID = setTimeout(() => {
      const eventValue = event.target.value.trim();

      if (eventValue.length !== 0) {
        const dataTable = tableOption.data.filter(
          (item) =>
            item?.name?.toLowerCase()?.includes(eventValue) ||
            item?.designation?.toLowerCase()?.includes(eventValue) ||
            item?.area?.toLowerCase()?.includes(eventValue) ||
            item?.agency?.toLowerCase()?.includes(eventValue)
        );

        setTableOption({
          ...tableOption,
          data: dataTable,
        });
      } else {
        setTableOption({
          ...tableOption,
          data,
        });
      }
    }, 1000);
  };

  const columns = [
    {
      name: "",
      selector: (row) => row?.id,
      sortable: false,
      width: "60px",
      // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
      cell: ({ id }) => {
        return (
          <div className="w-100 p-0 d-flex justify-content-center align-items-center">
            <CustomCheckbox
              onChange={(evt) => {
                const { checked } = evt.target;

                if (checked) {
                  setRecipients({
                    ...recipients,
                    include: [...recipients.include, id],
                  });
                } else {
                  const newItems = recipients.include.filter(
                    (recipient) => recipient !== id
                  );

                  setRecipients({
                    ...recipients,
                    include: newItems,
                  });
                }
              }}
              disabled={action !== "add"}
              icon={
                <Check
                  className="vx-icon"
                  size={16}
                />
              }
              checked={recipients.include.includes(id)}
            />
          </div>
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      grow: 2,
      sortable: true,
      // minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ name }) => <p className="mb-0">{name}</p>,
    },
    {
      name: "Designation",
      selector: (row) => row?.designation,
      // grow: 2,
      sortable: true,
      // minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ designation }) => <p className="mb-0">{designation}</p>,
    },
    {
      name: "Agency",
      selector: (row) => row?.agency,
      // grow: 2,
      sortable: true,
      // minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ agency }) => <p className="mb-0">{agency}</p>,
    },
    {
      name: "Area",
      selector: (row) => row?.area,
      // grow: 2,
      sortable: true,
      // minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ area }) => <p className="mb-0">{area}</p>,
    },
    {
      name: "Contact Number",
      selector: (row) => row?.contact,
      // grow: 2,
      // minWidth: "100px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ contact }) => (
        <p className="mb-0">{getFormattedContactNumber(contact)}</p>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      setTableOption({
        ...tableOption,
        data,
      });
    }
  }, [data]);

  return (
    <RecipientsTable>
      <RecipientsTableHeader>
        <h5 className="mb-0">{"Recipient Table"}</h5>

        {!isCustomSelected && (
          <div className="position-relative">
            <SearchBarComponent
              onChangeSearch={onChangeSearch}
              isDisabled={tableOption?.isLoading}
            />
          </div>
        )}
      </RecipientsTableHeader>

      <RecipientsTableContainer>
        {!isCustomSelected ? (
          <CustomDataTable
            columns={columns}
            data={tableOption.data}
            noHeader
            progressPending={tableOption.isLoading}
            height="calc(100vh - 200px)"
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        ) : (
          <CustomCongressmanSelection
            action={action}
            setRecipients={setRecipients}
            recipients={recipients}
          />
        )}
      </RecipientsTableContainer>
    </RecipientsTable>
  );
};

export default RecipientTable;
