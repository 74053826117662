import React, { useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import PropTypes from "prop-types";
import { Eye, EyeOff } from "react-feather";

const PasswordInput = ({ type, placeholder, onChange, value, name }) => {
  const [inputType, setInputType] = useState(type);

  return (
    <div className="custom-password-input position-relative has-icon-right">
      <InputGroup>
        <Input
          name={name || ""}
          type={inputType}
          onChange={onChange}
          value={value}
          placeholder={placeholder || ""}
          autoComplete="off"
        />

        <InputGroupText>
          {inputType !== "text" ? (
            <EyeOff
              size="20"
              color="#6771BB"
              onClick={() => setInputType("text")}
            />
          ) : (
            <Eye
              size="20"
              color="#6771BB"
              onClick={() => setInputType("password")}
            />
          )}
        </InputGroupText>
      </InputGroup>
    </div>
  );
};

export default PasswordInput;

PasswordInput.prototype = {
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
