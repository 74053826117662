import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import classNames from "classnames";
import FormGenertor from "components/customs/form-generator/input";
import CustomSelect from "components/customs/dropdown";
import { DropdownIndicator, addProjectDropdownStyles } from "utility/constants";
import { useNavigate } from "react-router";

const validSchema = Yup.object().shape({
  lastName: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  mobile: Yup.string()
    .min(12, "At least 12 digits")
    .max(12, "Cannot be more than 12 digits")
    .matches(/^(639)\d{9}$/, "Invalid format")
    .required("Required"),
});

const StyledRed = styled.span`
  color: red;
`;
const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const DirectorForm = ({
  handleOnSumbmit,
  formFields,
  setFormFields,
  regionOptions,
  selectedRegion,
  setSelectedRegion,
  regionError,
  agencyOptions,
  selectedAgency,
  setAgencyId,
  setSelectedAgency,
  agencyError,
  isLoading,
  agencyId,
}) => {
  const navigate = useNavigate();

  return (
    <Formik
      validationSchema={validSchema}
      onSubmit={handleOnSumbmit}
      initialValues={formFields}
      enableReinitialize
    >
      {({ handleChange, values }) => (
        <Form id="add-account-form">
          <Field name="lastName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Last Name"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Provide your Last Name"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <Input
                    type="text"
                    name="lastName"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.lastName && touched?.lastName,
                    })}
                    value={values.lastName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        lastName: evt.target.value || "",
                      });
                    }}
                  />

                  <ErrorMessage name="lastName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="firstName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"First Name"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Provide your First Name"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <Input
                    type="text"
                    name="firstName"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.firstName && touched?.firstName,
                    })}
                    value={values.firstName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        firstName: evt.target.value || "",
                      });
                    }}
                  />

                  <ErrorMessage name="firstName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="mobile">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Contact Number"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Provide your Contact Number"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <FormGenertor
                    type="mobile"
                    name="mobile"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.mobile && touched?.mobile,
                    })}
                    value={values.mobile || "63"}
                    onChange={(value) => {
                      handleChange(value);

                      setFormFields({
                        ...formFields,
                        mobile: value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="mobile">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Region"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide your Region"}</p>
            </Col>
            <Col
              xs="12"
              md="6"
            >
              <CustomSelect
                placeholder=""
                name="region"
                styles={addProjectDropdownStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={regionOptions}
                value={selectedRegion}
                onChange={(option) => setSelectedRegion(option)}
              />
              <small className="field-error text-danger">{regionError}</small>
            </Col>
          </Row>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Agency"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide your Agency"}</p>
            </Col>
            <Col
              xs="12"
              md="6"
            >
              <CustomSelect
                placeholder=""
                name="agency"
                styles={addProjectDropdownStyles}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={agencyOptions}
                value={selectedAgency}
                onChange={(option) => {
                  setAgencyId(option.value);
                  setSelectedAgency(option);
                }}
                // eslint-disable-next-line consistent-return
                filterOption={(option, searchValue) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchValue?.toLowerCase()) ||
                    option?.data?.acronym
                      ?.toLowerCase()
                      ?.includes(searchValue?.toLowerCase())
                  ) {
                    return option;
                  }
                }}
              />
              <small className="field-error text-danger">{agencyError}</small>
            </Col>
          </Row>

          <Field name="cosLastName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Chief of Staff Last Name"}
                  </h5>
                  <p>{"Provide the Chief of Staff's Last Name"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <Input
                    type="text"
                    name="cosLastName"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.cosLastName && touched?.cosLastName,
                    })}
                    value={values.cosLastName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        cosLastName: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="cosLastName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="cosFirstName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Chief of Staff First Name"}
                  </h5>
                  <p>{"Provide the Chief of Staff's First Name"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <Input
                    type="text"
                    name="cosFirstName"
                    className={classNames("form-control input", {
                      "is-invalid":
                        errors?.cosFirstName && touched?.cosFirstName,
                    })}
                    value={values.cosFirstName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        cosFirstName: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="cosFirstName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="cosNumber">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">{"Contact Number"}</h5>
                  <p>{"Provide the Chief of Staff's Contact Number"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <FormGenertor
                    type="mobile"
                    name="cosNumber"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.cosNumber && touched?.cosNumber,
                    })}
                    value={values.cosNumber || "63"}
                    onChange={(value) => {
                      handleChange(value);

                      setFormFields({
                        ...formFields,
                        cosNumber: value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="cosNumber">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-5  d-flex justify-content-end">
            <Button
              className="btn-cancel mr-1"
              disabled={isLoading}
              onClick={() => {
                navigate("/directory", {
                  state: {
                    view: "regional",
                    agencySelect: agencyId,
                  },
                });
              }}
            >
              {"Back"}
            </Button>

            <Button
              type="submit"
              className="btn-save"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="light"
                  size="sm"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default DirectorForm;
