import React from "react";
import classnames from "classnames";
import { Spinner } from "reactstrap";
import PropTypes from "prop-types";

import "./spinner.scss";

const CustomSpinner = ({
  overlay = false,
  backdrop = false,
  alignment = "",
  ...props
}) => {
  return (
    <div
      className={classnames("custom-spinner", {
        "text-center": alignment === "center",
        overlayed: overlay,
        backdrop,
      })}
    >
      <Spinner {...props} />
    </div>
  );
};

CustomSpinner.propTypes = {
  overlay: PropTypes.bool,
  backdrop: PropTypes.bool,
  alignment: PropTypes.string,
};

export default CustomSpinner;
