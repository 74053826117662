import { OMEGADB_URL } from "config";
import axios from "axios";
import api from "./api";

const fetchCandidates = async ({ url, callback }) => {
  try {
    const baseUrl = `${OMEGADB_URL}${url}`;

    const response = await axios.get(baseUrl);

    if (response && response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const officialSearch = async ({ position, inputValue, callback }) => {
  await api({
    Method: "GET",
    Url: `/project/others-recipients?position=${position}&search=${inputValue}`,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.count !== 0 ? response?.data.rows : [];

        callback(
          data?.map((item) => ({
            ...item,
            label: item?.fullName,
            value: item?.id,
          }))
        );
      }
    },
    ResponseFailCallback: () => {
      callback([]);
    },
  });
};

export { fetchCandidates, officialSearch };
