import styled from "styled-components";
import { Badge, Button, Row } from "reactstrap";

/* Assets */
import addHeaderBG from "../../assets/img/BG_3.png";

const MainContainer = styled.div`
  background: url(${addHeaderBG}) no-repeat;
  background-position: bottom;
  height: 130px;
  background-size: cover;
`;

const BreadCrumbSection = styled.div`
  display: grid;
  justify-content: flex-end;

  @media (max-width: 575.98px) {
    display: none;
  }
`;

const Container = styled.div`
  margin-right: 8rem;
  margin-left: 8rem;

  @media (max-width: 768px) {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  @media (max-width: 575.98px) {
    margin-right: 2rem;
    margin-left: 2rem;
  }
`;

const HeaderTextSection = styled.div`
  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;
  align-items: center;

  h2 {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 575.98px) {
    grid-template-columns: 1fr 50px;

    a {
      display: flex;
      justify-content: flex-end;
    }

    padding-top: 4rem;
  }
`;

const HeaderSection = styled.div``;

const AddButton = styled(Button)`
  background: #4252c9;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  height: 50px;
  font-weight: 600;
  display: flex;
  align-items: center;

  @media (max-width: 575.98px) {
    p {
      display: none;
    }
  }
`;

const TableActionSection = styled.div`
  display: grid;
  justify-content: flex-end;
  grid-auto-flow: column;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const TableActionDownload = styled.a`
  display: flex;
  align-items: center;
  color: #5a69da;
  text-decoration: none;
`;

const TableActionDelete = styled.a`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const TableSection = styled.div`
  margin-top: 4rem;

  .rdt_TableRow:hover {
    .rdt_TableCell[data-column-id="highlight"] {
      background-color: #4252c9;
    }
  }
`;

const FormSection = styled.div`
  margin-top: 4rem;
`;

const RowSection = styled(Row)`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
`;

const StyledRed = styled.span`
  color: red;
`;

const FormColRight = styled.div`
  flex: 0 0 auto;
  width: 100%;

  @media (min-width: 768px) {
    flex: 0 0 auto;
    width: 50%;
  }

  .form-control {
    background: #f5f5f7;
    border-radius: 16px;
    height: 60px;

    &.input-textarea {
      height: initial;
    }
  }

  .dropzone-container {
    width: auto;
    height: 65px;
    border: 1px dashed #a3a3a3;

    .upload-files {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`;

const CharacterCountSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;

const SendWithOptions = styled.div`
  display: flex;
`;

const BtnSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
  padding-bottom: 4rem;
`;

const BtnSend = styled(Button)`
  background: #4252c9;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  height: 50px;
  width: 100px;
`;

const BtnSaveAsDraft = styled(Button)`
  background: #4252c9;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  height: 50px;
  width: 140px;
  margin-right: 1rem;
`;

const BtnCancel = styled(Button)`
  background: #f3f3f3;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  height: 50px;
  width: 140px;
  margin-right: 1rem;
  color: black;
  border: 0.5px solid #656eb2;
  font-weight: 600;
`;

const ExpandedTableContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 8rem;
  padding-right: 8rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  @media (max-width: 575.98px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const ViewCommentBtn = styled(Button)`
  color: white;
  background: #5a69da;
  border: 1px solid #5a69da;
  border-radius: 27px;
`;

const AddCommentBtn = styled(Button)`
  background: #f8f8f8;
  border: 1px solid #5a69da;
  border-radius: 27px;
  color: #5a69da;
`;

const UnreadBadge = styled(Badge)`
  height: initial;
  top: 9px;
  color: #5a69da;
  background-color: white !important;
  position: relative;
  box-shadow: 0px 4px 9px rgba(66, 82, 201, 0.38);
  font-size: small;
`;

const CommentsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;

  &:has(.unread-msgs) {
    bottom: 10px;
  }
`;

const RecipientsTable = styled.div`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top: solid #cecece 1px;
  border-bottom: solid #cecece 1px;
`;

const RecipientsTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .custom-searchbar-container input {
    box-shadow: initial;
  }
`;

const RecipientsTableContainer = styled.div`
  .rdt_Table {
    .rdt_TableCol_Sortable {
      .sc-ezOQGI {
        text-transform: uppercase;
      }
    }
  }
`;

const CustomFormContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ParentContainer = styled.div`
  max-height: 400px;
  overflow: auto;
`;

const CustomForm = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: 43% 25% 25% 3%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
`;

const CustomFormAddRowSection = styled.div`
  display: flex;
  margin-bottom: 2rem;

  button {
    width: 100%;
    border: 1px dashed rgba(0, 0, 0, 0.37);
    border-radius: 0;
    background-color: white;
    color: #999999;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CustomFormDeleteRowIcon = styled.div``;

export {
  MainContainer,
  BreadCrumbSection,
  Container,
  HeaderTextSection,
  HeaderSection,
  AddButton,
  TableActionSection,
  TableActionDownload,
  TableActionDelete,
  TableSection,
  FormSection,
  RowSection,
  StyledRed,
  FormColRight,
  CharacterCountSection,
  SendWithOptions,
  BtnSection,
  BtnSend,
  ExpandedTableContainer,
  ViewCommentBtn,
  AddCommentBtn,
  BtnCancel,
  BtnSaveAsDraft,
  UnreadBadge,
  CommentsContainer,
  RecipientsTable,
  RecipientsTableHeader,
  RecipientsTableContainer,
  CustomFormContainer,
  CustomForm,
  CustomFormAddRowSection,
  CustomFormDeleteRowIcon,
  ParentContainer,
};
