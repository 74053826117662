import React, { useEffect, useState } from "react";
import { splitArrayToChunks } from "utility/helpers";
import PartyContainer from "components/customs/partyContainer";
import { fetchPartylists } from "services/services.affiliation";
import {
  PartyColumn,
  PartyListContainer,
  PartyListHeader,
  PartyListWrapper,
  PartyParentContainer,
} from "./styled";

const Main = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);

  const getFormattedData = () => {
    const newData = splitArrayToChunks(data, 3);
    let resultData = [];

    resultData =
      newData &&
      newData?.length &&
      newData?.length !== 0 &&
      newData.map((datum) => {
        return (
          datum?.length &&
          datum?.length !== 0 &&
          datum?.map((d) => {
            return {
              label: d?.name,
              link: `/parties/${d?.id}`,
              photo: d?.photo,
              id: d?.id,
            };
          })
        );
      });

    return resultData;
  };

  const fetchData = () => {
    fetchPartylists({
      callback: (Data) => {
        const newData = Data?.length !== 0 ? Data : [];

        setData(newData);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data?.length !== 0) {
      const result = getFormattedData();
      setFilteredData(result);
    }
  }, [data]);

  return (
    <PartyListContainer>
      <PartyListHeader>
        <h1>{"Party Affiliation"}</h1>
      </PartyListHeader>

      <PartyListWrapper>
        {filteredData?.length !== 0 &&
          filteredData?.map((datum) => {
            return (
              <PartyColumn key={datum?.id}>
                {datum?.map((d) => {
                  return (
                    <PartyParentContainer key={d?.id}>
                      <PartyContainer
                        key={d?.id}
                        {...d}
                      />
                    </PartyParentContainer>
                  );
                })}
              </PartyColumn>
            );
          })}
      </PartyListWrapper>
    </PartyListContainer>
  );
};

export default Main;
