import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

import "./styles.scss";

const RepresentativesContainer = (props) => {
  const navigate = useNavigate();

  return (
    <a
      href="!#"
      className="rep-container"
      onClick={(e) => {
        e.preventDefault();
        navigate(props.link);
      }}
    >
      <h5 className="rep-container--label mb-0">{props?.label}</h5>
    </a>
  );
};

RepresentativesContainer.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default RepresentativesContainer;
