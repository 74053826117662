import React from "react";
import Layout3 from "components/layout/Layout3";
import Others from "./Others";

const OthersDetails = (props) => {
  return (
    <Layout3>
      <Others {...props} />
    </Layout3>
  );
};

export default OthersDetails;
