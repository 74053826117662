import React from "react";
import Layout3 from "components/layout/Layout3";
import AccountsTable from "./AccountsTable";

const AccountList = (props) => {
  return (
    <Layout3>
      <AccountsTable {...props} />
    </Layout3>
  );
};

export default AccountList;
