import React, { useEffect, useState } from "react";
import BreadCrumbComponent from "components/customs/breadCrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { Check, Edit2, Eye, Trash2, X } from "react-feather";
import { defaultTableOption } from "utility/constants";
import CustomDataTable from "components/customs/table";
import { Link } from "react-router-dom";
import { fetchTable } from "services/services.projects";
import { Toast, getFormattedDateString, hasPermission } from "utility/helpers";
import { UncontrolledTooltip } from "reactstrap";
import { genericDelete, genericGet } from "services/services.general";
import Swal from "sweetalert2";
import CommentsModal from "components/customs/comments-modal";
import { useNavigate } from "react-router";
import {
  BreadCrumbSection,
  HeaderTextSection,
  MainContainer,
  Container,
  AddButton,
  TableSection,
  ExpandedTableContainer,
  ViewCommentBtn,
  AddCommentBtn,
  UnreadBadge,
  CommentsContainer,
} from "./styled";

const NewsTable = () => {
  const [tableOption, setTableOption] = useState(defaultTableOption);
  const [isModalShown, setIsModalShown] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentId, setCommentId] = useState(null);

  const toggleModal = () => setIsModalShown(!isModalShown);

  const navigate = useNavigate();

  const fetchDataTable = () => {
    setTableOption({
      ...tableOption,
      isLoading: true,
    });

    fetchTable({
      url: "/news",
      params: {
        limit: tableOption.currentRowsPerPage,
        offset: tableOption.offset,
        search: tableOption.searchValue,
        column: tableOption.sort.field,
        order: tableOption.sort.direction,
      },
      callback: (data) => {
        setTableOption({
          ...tableOption,
          isLoading: false,
          data: data.rows,
          totalRows: data.count,
        });
      },
    });
  };

  const handleDeleteRow = (row) => {
    Swal.fire({
      title: "Warning",
      // eslint-disable-next-line max-len
      text: `Are you sure you want to delete ${row?.title}`,
      icon: "warning",
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await genericDelete({
          url: `/news/${row.id}`,
          callback: ({ isSuccess }) => {
            if (isSuccess) {
              fetchDataTable();

              Toast({
                type: 1,
                content: "Successfully deleted news.",
              });
            } else {
              Toast({
                type: 4,
                content: "An error occured.",
              });
            }
          },
        });
      }
    });
  };

  const fetchComments = (id) => {
    genericGet({
      url: `/news/${id}/comments`,
      callback: (data) => {
        setComments(data);
      },
    });
  };

  const columns = [
    {
      name: "",
      selector: null,
      sortable: false,
      id: "highlight",
      width: "5px",
      style: { paddingLeft: 0, paddingRight: 0 },
      cell: () => null,
    },
    {
      name: "News Heading",
      selector: null,
      sortable: false,
      minWidth: "180px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ title }) => <p className="mb-0 ">{title}</p>,
    },
    {
      name: "Date",
      selector: null,
      sortable: false,
      minWidth: "120px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ createdAt }) => (
        <p className="mb-0 ">
          {getFormattedDateString(createdAt, "MMM dd, yyyy")}
        </p>
      ),
    },
    {
      name: "Include COS",
      selector: null,
      sortable: false,
      minWidth: "110px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ includeCOS }) => (
        <p className="mb-0 ">
          {includeCOS ? (
            <Check
              className="vx-icon"
              size={16}
            />
          ) : (
            <X
              className="vx-icon"
              size={16}
            />
          )}
        </p>
      ),
    },
    {
      name: "Send with SMS",
      selector: null,
      sortable: false,
      minWidth: "130px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ sendWithSMS }) => (
        <p className="mb-0 ">
          {sendWithSMS ? (
            <Check
              className="vx-icon"
              size={16}
            />
          ) : (
            <X
              className="vx-icon"
              size={16}
            />
          )}
        </p>
      ),
    },
    {
      name: "Send with Email",
      selector: null,
      sortable: false,
      minWidth: "135px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ sendWithEmail }) => (
        <p className="mb-0 ">
          {sendWithEmail ? (
            <Check
              className="vx-icon"
              size={16}
            />
          ) : (
            <X
              className="vx-icon"
              size={16}
            />
          )}
        </p>
      ),
    },
    {
      name: "Remarks",
      selector: null,
      sortable: false,
      minWidth: "160px",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ hasComments, id, unreadMsg }) => (
        <CommentsContainer>
          {hasComments ? (
            <>
              <UnreadBadge
                className={unreadMsg !== 0 ? "unread-msgs" : ""}
                pill
              >
                {unreadMsg === 0 ? null : unreadMsg}
              </UnreadBadge>
              <ViewCommentBtn
                size="sm"
                onClick={() => {
                  fetchComments(id);
                  setCommentId(id);
                  toggleModal();
                }}
              >
                {"View Comment"}
              </ViewCommentBtn>
            </>
          ) : (
            <AddCommentBtn
              size="sm"
              onClick={() => {
                setCommentId(id);
                toggleModal();
              }}
            >
              {"Add Comment"}
            </AddCommentBtn>
          )}
        </CommentsContainer>
      ),
    },
    {
      name: "Actions",
      selector: null,
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => {
        return (
          <>
            {row?.isSent && hasPermission("news-update") && (
              <>
                <Edit2
                  size={16}
                  id={`edit-row--${row.id}`}
                  color="#4252C9"
                  className="mr-05"
                  onClick={() => {
                    navigate("/news/edit", {
                      state: {
                        row,
                        action: "edit",
                      },
                    });
                  }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`edit-row--${row.id}`}
                >
                  {"Edit"}
                </UncontrolledTooltip>
              </>
            )}

            <Eye
              size={16}
              color="#4252C9"
              onClick={() =>
                navigate("/news/view", {
                  state: {
                    row,
                    action: "view",
                  },
                })
              }
              id={`view-row--${row.id}`}
            />
            <UncontrolledTooltip
              placement="top"
              target={`view-row--${row.id}`}
            >
              {"View"}
            </UncontrolledTooltip>

            {hasPermission("news-delete") && (
              <>
                <Trash2
                  size={16}
                  color="#4252C9"
                  className="ml-05"
                  onClick={() => handleDeleteRow(row)}
                  id={`delete-row--${row.id}`}
                />
                <UncontrolledTooltip
                  placement="right"
                  target={`delete-row--${row.id}`}
                >
                  {"Delete"}
                </UncontrolledTooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  // eslint-disable-next-line react/no-unstable-nested-components
  const ExpandedComponent = ({ data }) => {
    return (
      <ExpandedTableContainer>
        {data?.description ? (
          <>
            <h5 className="font-weight-bold">{"Description"}</h5>

            <p>{data?.description}</p>
          </>
        ) : null}

        {data?.message ? (
          <>
            <h5 className="font-weight-bold">{"Message"}</h5>

            <p>{data?.message}</p>
          </>
        ) : null}

        {data?.attachedFileURL ? (
          <>
            <h5 className="font-weight-bold">{"Files"}</h5>
            <p className="d-flex flex-column mb-0">
              {data.attachedFileURL.map((file) => (
                <a
                  href={file}
                  download={decodeURIComponent(
                    file.split("/")[file.split("/").length - 1]
                  )}
                  className="mb-0"
                >
                  {decodeURIComponent(
                    file.split("/")[file.split("/").length - 1]
                  )}
                </a>
              ))}
            </p>
          </>
        ) : null}
      </ExpandedTableContainer>
    );
  };

  const onChangePage = (page) => {
    setTableOption({
      ...tableOption,
      page,
      offset: tableOption.currentRowsPerPage * page - 10,
    });
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setTableOption({
      ...tableOption,
      currentRowsPerPage,
      page: currentPage,
    });
  };

  useEffect(() => {
    fetchDataTable();
  }, [
    tableOption.currentRowsPerPage,
    tableOption.offset,
    tableOption.searchValue,
    tableOption.sort.field,
    tableOption.sort.direction,
  ]);

  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "News & Announcements",
                isActive: true,
              },
            ]}
          />
        </BreadCrumbSection>

        <HeaderTextSection>
          <h2 className="text-danger">{"News & Announcements"}</h2>

          {hasPermission("news-create") && (
            <Link
              to="/news/add"
              state={{ action: "add" }}
            >
              <AddButton>
                <FontAwesomeIcon
                  className="mr-25"
                  icon={faBullhorn}
                />
                <p className="mb-0">{"Add Announcement"}</p>
              </AddButton>
            </Link>
          )}
        </HeaderTextSection>

        {/* <TableActionSection>
          {selectedItems.length !== 0 ? (
            <>
              <TableActionDownload
                href="#"
                onClick={handleDownloadClicked}
              >
                <ArrowDown size={18} />
                {"Download File"}
              </TableActionDownload>

              <TableActionDelete
                href="#"
                onClick={handleDeleteClicked}
              >
                <Trash2
                  size={18}
                  className="mr-25 text-danger"
                />
                <p className="text-danger mb-0">{"Delete All"}</p>
              </TableActionDelete>
            </>
          ) : null}
        </TableActionSection> */}

        <TableSection>
          <CustomDataTable
            columns={columns}
            data={tableOption.data}
            noHeader
            progressPending={tableOption.isLoading}
            expandableRows
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            expandOnRowClicked
            expandableRowsHideExpander
            expandableRowsComponent={ExpandedComponent}
            height="calc(100vh - 200px)"
            paginationServer
            paginationPerPage={tableOption.currentRowsPerPage}
            paginationTotalRows={tableOption.totalRows}
          />
        </TableSection>
      </Container>
      <CommentsModal
        isOpen={isModalShown}
        handleClose={toggleModal}
        comments={comments}
        fetchComments={fetchComments}
        congID={commentId}
        view="news"
        handleFetchTable={fetchDataTable}
      />
    </MainContainer>
  );
};

export default NewsTable;
