import api from "./api";

const deleteAccount = ({ params, callback }) => {
  api({
    Method: "DELETE",
    Url: `/auth/delete-user/${params}`,
    ResponseSuccessCallback: () => {
      callback({ isSuccess: true });
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

const fethAllAcounts = ({ url, params, callback }) => {
  api({
    Url: url,
    Params: params,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data?.data : [];

        callback(data);
      }
    },
  });
};

const fetchAllAgencies = ({ callback }) => {
  api({
    Url: "/agency?type=all",
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];
        callback(data);
      }
    },
  });
};

const fetchAllRoles = ({ callback }) => {
  api({
    Url: "/auth/list-groups",
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];

        callback(data);
      }
    },
  });
};

const AddAccount = ({ url, method, formData, callback }) => {
  api({
    Method: method,
    Url: url,
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data?.status) {
        callback({
          isSuccess: true,
          msg: response?.data?.message,
        });
      } else {
        callback({
          isSuccess: false,
          msg: response?.data?.message,
        });
      }
    },
    ResponseFailCallback: (err) => {
      callback({
        isSuccess: false,
        msg: err?.response?.data?.message,
      });
    },
  });
};

const fetchAccountDetails = ({ url, callback }) => {
  api({
    Url: url,
    Method: "GET",
    ResponseSuccessCallback: (response) => {
      if (response && response.data.status) {
        callback(response.data.data);
      }
    },
  });
};

export {
  deleteAccount,
  fethAllAcounts,
  fetchAllAgencies,
  fetchAllRoles,
  AddAccount,
  fetchAccountDetails,
};
