import styled from "styled-components";
import { Button } from "reactstrap";

/* Assets */

const StyledRed = styled.span`
  color: red;
`;

const BtnSave = styled(Button)`
  background: #4252c9;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  height: 50px;
  width: 140px;
`;

export { StyledRed, BtnSave };
