import FormGenertor from "components/customs/form-generator/input";
import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import { XCircle } from "react-feather";
import CustomSelect from "components/customs/dropdown";
import CommonModal from "components/common/modal";
import OthersForm from "components/forms/Others";
import {
  DropdownIndicator,
  addProjectDropdownStyles,
  addProjectSearchInputStyle,
} from "utility/constants";
import { Toast, getFormattedContactNumber } from "utility/helpers";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { officialSearch } from "services/services.official";
import { genericPost } from "services/services.general";
import { ModalHeader, ModalContainer } from "./styled";
import "./styles.scss";
import { HouseMemberColRight, HouseMemberSection, StyledRed } from "../styled";

const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const selectorOptions = [
  {
    value: 1,
    label: "Senator",
  },
  {
    value: 2,
    label: "Governor",
  },
  {
    value: 3,
    label: "Vice Governor",
  },
  {
    value: 4,
    label: "Mayor",
  },
  {
    value: 5,
    label: "Vice Mayor",
  },
  {
    value: 6,
    label: "Others",
  },
];

const Others = ({ onChange, allocationName, contact }) => {
  const [name, setName] = useState(null || allocationName);
  const [contactNumber, setContactNumber] = useState("63" || contact);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formFields, setFormFields] = useState({
    lastName: "",
    firstName: "",
    fullName: "",
    contact: "",
  });

  const toggleModal = () => setIsModalShown(!isModalShown);

  const handleOfficialSearch = _.debounce((inputValue, callback) => {
    officialSearch({
      position: selectedOption.label,
      inputValue,
      callback,
    });
  }, 1000);

  const validate = () => {
    if (
      formFields.contact?.length > 0 &&
      (formFields.contact?.length <= 2 ||
        !/^(639)\d{9}$/.test(formFields.contact))
    ) {
      return "Invalid Others contact number.";
    }

    if (formFields.lastName === "") {
      return "Required Last Name.";
    }

    if (formFields.firstName === "") {
      return "Required First Name.";
    }

    return true;
  };

  const sendData = async (formData) => {
    setIsLoading(true);

    await genericPost({
      url: "/official/create-others",
      formData,
      callback: ({ isSuccess, msg }) => {
        setIsLoading(false);

        if (isSuccess) {
          // navigate("/directory", {
          //   state: { view: "others" },
          // });

          setIsModalShown(false);

          Toast({
            type: 1,
            content: "Successfully added directory.",
          });
        } else {
          Toast({
            type: 4,
            content: msg,
          });
        }
      },
    });
  };

  const handleOnSumbmit = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const data = {
        // eslint-disable-next-line max-len
        fullName: `${formFields.firstName} ${formFields.lastName}`,
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        contact: formFields.contact?.slice(2),
        position: "Others",
      };

      await sendData(data);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  useEffect(() => {
    if (contactNumber !== "63") {
      onChange({ allocationContactNumber: contactNumber });
    }
  }, [contactNumber]);

  useEffect(() => {
    setSelectedOption(selectorOptions[0]);
    onChange({ officialPostion: selectorOptions[0].label });
  }, []);

  return (
    <>
      <HouseMemberSection>
        <Col
          xs="12"
          md="6"
        >
          <h5 className="font-weight-bold">
            {"Position"}
            {RequiredIndicator}
          </h5>
          <p>{"Provide the recipient position"}</p>
        </Col>
        <HouseMemberColRight>
          <CustomSelect
            onChange={(option) => {
              setSelectedOption(option);
              if (name) setName(null);
              if (contactNumber) setContactNumber("63");
              onChange({ officialPostion: option.value });
            }}
            value={selectedOption}
            options={selectorOptions}
            styles={addProjectDropdownStyles}
            components={{ DropdownIndicator }}
            classNamePrefix="select"
          />
        </HouseMemberColRight>
      </HouseMemberSection>

      <HouseMemberSection>
        <Col
          xs="12"
          md="6"
        >
          <h5 className="font-weight-bold">
            {"Recipient Contact"}
            {RequiredIndicator}
          </h5>
          <p>{"Provide the recipient contact"}</p>
        </Col>
        <HouseMemberColRight>
          <div className="add-project-section others-field">
            <AsyncSelect
              className="asyc-dropdown w-100"
              classNamePrefix="select"
              placeholder="Official"
              onChange={(option) => {
                setName(option);

                onChange({
                  allocationContactName: option.label,
                  officialId: option.value,
                });

                setContactNumber(getFormattedContactNumber(option.contact));
              }}
              loadOptions={handleOfficialSearch}
              styles={addProjectSearchInputStyle}
              value={name}
            />
            {selectedOption?.value === 6 && (
              <a
                href="!#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalShown(true);
                }}
                className="add-proj-field p-0"
              >
                {"+ Add Others"}
              </a>
            )}
          </div>
        </HouseMemberColRight>
      </HouseMemberSection>

      <HouseMemberSection>
        <Col
          xs="12"
          md="6"
        >
          <h5 className="font-weight-bold">
            {"Contact Number"}
            {RequiredIndicator}
          </h5>
          <p>{"Provide the number of the recipient"}</p>
        </Col>
        <HouseMemberColRight>
          <FormGenertor
            type="mobile"
            className="input-date form-control"
            placeholder="Contact Number"
            value={contactNumber}
            isDisabled
          />
        </HouseMemberColRight>
      </HouseMemberSection>
      <CommonModal
        isOpen={isModalShown}
        handleClose={toggleModal}
        renderBody={
          <>
            <div className="d-flex justify-content-end">
              <a
                href="!#"
                onClick={(e) => {
                  toggleModal();
                  setIsModalShown(false);
                  e.preventDefault();
                }}
              >
                <XCircle />
              </a>
            </div>
            <ModalContainer>
              <ModalHeader>
                <h3>{"Add Others"}</h3>
              </ModalHeader>
              <OthersForm
                handleOnSumbmit={handleOnSumbmit}
                formFields={formFields}
                setFormFields={setFormFields}
                isLoading={isLoading}
                isModal
                setIsModalShown={setIsModalShown}
              />
            </ModalContainer>
          </>
        }
        modalSize="lg"
      />
    </>
  );
};

export default Others;
