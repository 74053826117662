/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
import React from "react";
import { Badge, Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Plus } from "react-feather";
import YearPicker from "components/customs/daterange";
import { hasPermission } from "utility/helpers";
import { getUserDetails } from "config";
import { canEditRoles, congRoles } from "utility/constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchPendingProjects } from "../../../redux/actions/projects";

import ImgContainer from "../../common/img";
import UserDropdown from "../dropdown/user";
import horTxtImage from "../../../assets/img/19thCong.png";
import horSeal from "../../../assets/img/HOR.png";
import "./style.scss";

const NavbarUser = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const role = getUserDetails()?.type;

  const dispatch = useDispatch();

  const { pendingProjects } = useSelector((state) => state.projects);

  const isVisited = (url) => {
    return location.pathname.includes(url) ? "active" : "";
  };

  window.onload = () => {
    isVisited();
  };

  const navItems = [
    {
      label: "Home",
      link: "/home",
      showItem: hasPermission("landing"),
      className: `mx-3 nav-link layout1-nav-links ${isVisited("/home")}`,
    },
    {
      label: "Representatives",
      link: "/representatives",
      showItem:
        !congRoles.includes(getUserDetails()?.type) &&
        hasPermission("congressman-view"),
      className: `mx-3 nav-link layout1-nav-links ${isVisited(
        "/representatives"
      )}`,
    },
    {
      label: "Programs",
      link: "/projects",
      showItem:
        !congRoles.includes(getUserDetails()?.type) &&
        hasPermission("project-view"),
      className: `mr-3 nav-link layout1-nav-links ${isVisited("/projects")}`,
    },
    {
      label: "Affiliation",
      link: "/parties",
      showItem:
        !congRoles.includes(getUserDetails()?.type) &&
        hasPermission("party-view"),
      className: `nav-link mx-3 layout1-nav-links ${isVisited("/parties")}`,
    },
    {
      label: "Directory",
      link: "/directory",
      showItem: canEditRoles.includes(getUserDetails()?.type),
      className: `nav-link mx-3 layout1-nav-links ${isVisited("/directory")}`,
    },
  ];

  React.useEffect(() => {
    if (role === "Director" && !pendingProjects) {
      dispatch(fetchPendingProjects());
    }
  }, [pendingProjects]);

  return (
    <nav
      // eslint-disable-next-line max-len
      className={`custom-navbar spare sticky-top navbar navbar-expand-lg navbar-light 
            ${
              location.pathname !== "/home" && location.pathname !== "/"
                ? "white-bg"
                : ""
            } ${
        location.pathname !== "/home" && location.pathname !== "/"
          ? "has-shadow"
          : ""
      } ${role?.toLowerCase()}`}
    >
      <a
        className={`navbar-brand ${
          location.pathname !== "/home" && location.pathname !== "/"
            ? ""
            : "is-hidden"
        }`}
        href="/"
        onClick={(e) => {
          e.preventDefault();

          if (hasPermission("landing")) {
            navigate("/");
          }
        }}
      >
        <ImgContainer
          src={horSeal}
          height="50px"
        />
        <ImgContainer
          src={horTxtImage}
          height="49px"
          width="157px"
        />
      </a>

      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav mr-auto">
          {navItems.map(
            ({ showItem, link, label, className }) =>
              showItem && (
                <li
                  className="nav-item"
                  key={`${link}${label}`}
                >
                  {label === "Programs" && role === "Director" && (
                    <Badge
                      className={`${
                        pendingProjects === 0 || pendingProjects === null
                          ? "d-none"
                          : ""
                      }`}
                      pill
                    >
                      {pendingProjects}
                    </Badge>
                  )}
                  <div
                    type="button"
                    aria-hidden
                    className={className}
                    onClick={() => {
                      navigate(link);
                    }}
                  >
                    {label}
                  </div>
                </li>
              )
          )}
        </ul>
      </div>

      <div className="dropdown-section">
        {hasPermission("project-create") && (
          <Button
            className="add-project-btn d-sm-flex d-none d-lg-none d-xl-flex"
            onClick={() => navigate("/add-project")}
          >
            <Plus size={22} />
            <p className="mb-0 ml-25 font-weight-bold">{"Add Program"}</p>
          </Button>
        )}

        <div className="search-navbar">
          <YearPicker />
        </div>

        <div className="user-dropdown section">
          <UserDropdown />
        </div>
      </div>
    </nav>
  );
};

export default NavbarUser;
