import { Toast } from "utility/helpers";
import api from "./api";

export const createNews = ({ formData, callback }) => {
  api({
    Url: "/news",
    Method: "POST",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 201) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: true });
    },
  });
};

export const createDraftNews = ({ formData, callback }) => {
  api({
    Url: "/news/save-draft",
    Method: "POST",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 201) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: true });
    },
  });
};

export const updateDraftNews = ({ param, formData, callback }) => {
  api({
    Url: `/news/${param}`,
    Method: "PUT",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: true });
    },
  });
};

export const batchDeleteNews = ({ formData, callback }) => {
  api({
    Url: "/news/batch-delete",
    Method: "DELETE",
    Data: formData,
    ResponseSuccessCallback: (response) => {
      if (response && response.status === 200) {
        callback({ isSuccess: true });
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: true });
    },
  });
};
