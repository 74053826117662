import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import {
  getFormattedContactNumber,
  getFormattedOptions,
  getModifiedObject,
  Toast,
} from "utility/helpers";
import { fetchAllRegions } from "services/services.congressman";
import { fetchPartylists } from "services/services.affiliation";
import GovernorForm from "components/forms/Governor";
import { genericPost, genericPut, uploadFile } from "services/services.general";
import { fetchCandidates } from "services/services.official";

import ImgContainer from "components/common/img";
import defaultUserImage from "assets/img/default_user.png";
import "../styles.scss";

const EditViceGovernor = () => {
  const location = useLocation();
  const vicegov = location.state?.vicegovernor || null;

  const [isLoading, setIsLoading] = useState(false);
  const [partylists, setPartylists] = useState([]);
  const [selectedPartylist, setSelectedPartylist] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [regionOptions, setRegionOptions] = useState([]);

  const [viceGovernorOptions, setViceGovernorOptions] = useState([]);
  const [selectedViceGovernor, setSelectedViceGovernor] = useState(null);

  const [selectedRegion, setSelectedRegion] = useState(null);

  const [formFields, setFormFields] = useState({
    lastName: "",
    firstName: "",
    fullName: "",
    omegaId: "",
    region: "",
    contact: "",
    province: "",
    municipality: "",
    party: "",
    cos: "",
    cosNumber: "",
    photo: "",
    photoName: "",
    cosFirstName: "",
    cosLastName: "",
  });

  const navigate = useNavigate();

  const validate = () => {
    if (
      formFields.cosNumber?.length > 2 &&
      !/^(639)\d{9}$/.test(formFields?.cosNumber)
    ) {
      return "Invalid cos contact number.";
    }

    if (
      formFields.contact?.length > 0 &&
      (formFields.contact?.length <= 2 ||
        !/^(639)\d{9}$/.test(formFields.contact))
    ) {
      return "Invalid Vice Governor contact number.";
    }

    if (formFields.fullName === "") {
      return "Required Name.";
    }

    if (formFields.region === "") {
      return "Required Region.";
    }

    if (formFields.province === "") {
      return "Required Province.";
    }

    return true;
  };

  const fetchViceGovernors = async () => {
    await fetchCandidates({
      url: "/election/nominations/?position_id=4&is_winner=true&page_size=100",
      callback: (data) => {
        if (data.results) {
          const rows = data.results;

          const vicegovernors = rows.map((vicegovernor) => ({
            ...vicegovernor,
            value: vicegovernor.candidate.id,
            // eslint-disable-next-line max-len
            label: `${vicegovernor.candidate.first_name} ${vicegovernor.candidate.last_name}`,
          }));

          if (vicegov) {
            const selectedViceGov = vicegovernors.find(
              (vgovernor) => vgovernor.candidate.id === vicegov.omegaId
            );
            setSelectedViceGovernor(selectedViceGov);
          }

          setViceGovernorOptions(vicegovernors);
        }
      },
    });
  };

  const fetchRegion = async () => {
    await fetchAllRegions({
      callback: (data) => {
        if (data.length) {
          const options = data
            .filter((region) => region.name !== "Partylist")
            .map(({ acronym, id, omegaId }) => ({
              omegaId,
              label: acronym,
              value: id,
            }));

          setRegionOptions(options);

          const region = options?.find(
            (option) => option.value === vicegov?.regionId
          );

          if (region) {
            setSelectedRegion(region);
          } else {
            setFormFields({
              ...formFields,
              region: null,
            });
          }
        }
      },
    });
  };

  const setData = () => {
    const option = partylists.find(
      (partylist) => partylist.id === vicegov.partyId
    );

    if (option) setSelectedPartylist(option);

    setIsLoading(false);
  };

  const setViceGovDetails = () => {
    const updatedFields = {
      ...formFields,
      lastName: vicegov.lastName,
      firstName: vicegov.firstName,
      fullName: vicegov.fullName,
      contact: getFormattedContactNumber(vicegov.contact),
      party: vicegov.partyId,
      region: selectedRegion,
      provinceId: vicegov.provinceId,
      municipalityId: vicegov.municipalityId,
      cos: vicegov.cosName,
      cosNumber: getFormattedContactNumber(vicegov.cosContact),
      photo: vicegov.photo || "",
      cosFirstName: vicegov.cosFirstName || vicegov.cosName,
      cosLastName: vicegov.cosLastName,
    };

    if (vicegov.photo) {
      const imgContainer = document.querySelector(".vicegov-img");
      imgContainer.src = vicegov.photo;
    }

    setFormFields(updatedFields);
  };

  const sendData = async (formData) => {
    setIsLoading(true);

    if (vicegov) {
      await genericPut({
        url: `/official/${vicegov.id}`,
        formData,
        callback: ({ isSuccess, msg }) => {
          setIsLoading(false);

          if (isSuccess) {
            navigate("/directory", {
              state: { view: "vice governor" },
            });

            Toast({
              type: 1,
              content: "Successfully edited vice governor details",
            });
          } else {
            Toast({
              type: 4,
              content: msg,
            });
          }
        },
      });
    } else {
      await genericPost({
        url: "/official",
        formData,
        callback: ({ isSuccess, msg }) => {
          setIsLoading(false);

          if (isSuccess) {
            navigate("/directory", {
              state: { view: "vice governor" },
            });

            Toast({
              type: 1,
              content: "Successfully added vice governor.",
            });
          } else {
            Toast({
              type: 4,
              content: msg,
            });
          }
        },
      });
    }
  };

  const uploadImage = async () => {
    let fileName = "";

    await uploadFile({
      file: formFields.photo,
      fileName: formFields.photoName,
      callback: (data) => {
        if (data) fileName = data;
      },
    });

    return fileName;
  };

  const handleOnSumbmit = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const data = {
        // eslint-disable-next-line max-len
        fullName: `VICE GOV. ${formFields.firstName} ${formFields.lastName}`,
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        contact: formFields.contact?.slice(2),
        regionId: formFields.region.value,
        provinceId: formFields.province.value,
        cosName: `${formFields.cosFirstName} ${formFields.cosLastName}`,
        cosFirstName: formFields.cosFirstName,
        cosLastName: formFields.cosLastName,
        omegaId: formFields.omegaId,
        position: "Vice Governor",
        ...(formFields.cosNumber ? { cosContact: formFields.cosNumber } : {}),
        ...(formFields.municipality.value !== "All Municipalities"
          ? { municipalityId: formFields.municipality.value }
          : {}),
        ...(selectedPartylist ? { partyId: selectedPartylist.value } : {}),
      };

      if (formFields.cosNumber) {
        data.cosContact = formFields.cosNumber?.slice(2);
      }

      let initialData = {};

      if (vicegov) {
        initialData = {
          firstName: vicegov.firstName,
          lastName: vicegov.lastName,
          fullName: vicegov.fullName,
          contact: vicegov.contact,
          regionId: vicegov.regionId,
          provinceId: vicegov.provinceId,
          municipalityId: vicegov.municipalityId,
          partyId: vicegov.partyId,
          cosName: vicegov.cosName,
          cosContact: vicegov.cosContact,
          cosFirstName: vicegov.cosFirstName,
          cosLastName: vicegov.cosLastName,
          photo: vicegov.photo,
          omegaId: formFields.omegaId,
          position: "Vice Governor",
        };
      }

      setIsLoading(true);

      if (typeof formFields.photo !== "string") {
        const fileName = await uploadImage();
        data.photo = fileName;
      }

      const formData = vicegov
        ? await getModifiedObject(initialData, data)
        : data;

      if (vicegov) {
        if (formFields.municipality.value === "All Municipalities") {
          formData.municipalityId = null;
        }
      }

      await sendData(formData);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const fetchData = async () => {
    await fetchPartylists({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);

        setPartylists(newData);
      },
    });

    setIsLoading(false);
  };

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    const fileTye = file.type.split("/")[0];

    if (fileTye === "image") {
      reader.onloadend = () => {
        const imgContainer = document.querySelector(".vicegov-img");

        const fileName = vicegov
          ? `${
              vicegov.id
            }-${formFields.lastName.toLocaleLowerCase()}-${Date.now()}.${
              file.type.split("/")[1]
            }`
          : `${formFields.lastName.toLocaleLowerCase()}-${Date.now()}.${
              file.type.split("/")[1]
            }`;

        setFormFields({
          ...formFields,
          photo: file,
          photoName: fileName,
        });

        imgContainer.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      Toast({
        type: 4,
        content: "File uploaded must be an image.",
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    //  fetch all district, partylist, region
    fetchRegion();
    fetchData();
    fetchViceGovernors();
  }, []);

  useEffect(() => {
    if (vicegov !== null) {
      setData();
      setViceGovDetails();
      setIsEdit(true);
    }
  }, [partylists, vicegov]);

  return (
    <div className="add-project-container directory">
      <Col className="header">
        <Row className="d-flex justify-content-end w-100 m-0">
          <Col
            xs="6"
            className="d-flex mt-3 justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Directory",
                  redirect: "/directory",
                },
                {
                  name: !vicegov
                    ? "Add Vice Governor"
                    : "Vice Governor Details",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header-actions h-75 d-flex m-0 mt-4 justify-content-between align-items-end">
          <h2 className="text-danger">
            {!vicegov ? "Add Vice Governor" : "Vice Governor Details"}
          </h2>
        </Row>
        <div className="form-section">
          <Row>
            <Col
              xs="12"
              sm="6"
              lg="4"
              className="col--img-container"
            >
              <div className="img-container">
                <ImgContainer
                  alt="vicegov-img"
                  className="vicegov-img"
                  src={defaultUserImage}
                  width="196px"
                  height="196px"
                />

                <label
                  htmlFor="file-upload"
                  className="upload-btn"
                >
                  {"Upload Photo"}
                  <input
                    id="file-upload"
                    className="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </label>
              </div>
            </Col>
            <Col
              xs="12"
              sm="6"
              lg="8"
            >
              <GovernorForm
                handleOnSumbmit={handleOnSumbmit}
                formFields={formFields}
                setFormFields={setFormFields}
                governorOptions={viceGovernorOptions}
                selectedGovernor={selectedViceGovernor}
                setSelectedGovernor={setSelectedViceGovernor}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                regionOptions={regionOptions}
                partylists={partylists}
                selectedPartylist={selectedPartylist}
                setSelectedPartylist={setSelectedPartylist}
                isLoading={isLoading}
                isEdit={isEdit}
                isGovernor={false}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default EditViceGovernor;
