import { Col, Row } from "reactstrap";
import styled from "styled-components";

const PartyListContainer = styled.div`
  margin: 0;
  padding-left: 3rem;
  padding-right: 3rem;
`;

const PartyListHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const PartyListWrapper = styled(Row)`
  margin: 0;
  display: flex;
  --bs-gutter-x: 1.5rem;
  justify-content: center;

  @media (max-width: 340px) {
    .pl-0.col {
      display: none;
    }

    .party-container {
      width: auto;
    }

    img {
      height: 60px;
    }
  }

  @media (min-width: 768px) and (max-width: 1199.98px) {
    .party-container {
      width: 300px;
    }
  }
`;

const PartyColumn = styled(Col)`
  flex: 0 0 auto;
  width: auto;
`;

const PartyParentContainer = styled(Col)`
  margin-top: 1.5rem;
  margin-bottom: 1.543m;
  display: flex;
  justify-content: center;
`;

export {
  PartyListContainer,
  PartyListHeader,
  PartyListWrapper,
  PartyColumn,
  PartyParentContainer,
};
