import React from "react";
import pdf from "assets/img/pdf_icon.svg";
import ImgContainer from "components/common/img";
import { Progress } from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {
  UploadPreviewDetailsRight,
  UploadPreviewContainer,
  UploadPreviewFileDetails,
  UploadPreviewIconSection,
  UploadPreviewProgress,
  UploadPreviewProgressSection,
  UploadPreviewDetailsLeft,
} from "../styled";

const FilePreview = ({
  name,
  size,
  percent,
  handleDeleteFile,
  isDisabled = false,
}) => {
  return (
    <UploadPreviewContainer>
      <UploadPreviewIconSection>
        <ImgContainer
          src={pdf}
          height="45px"
        />
      </UploadPreviewIconSection>

      <UploadPreviewProgressSection>
        <UploadPreviewFileDetails>
          <UploadPreviewDetailsLeft>
            <p className="file-name">{name}</p>
            {size ? <p className="mb-0">{`${size} Kb`}</p> : null}
          </UploadPreviewDetailsLeft>

          <UploadPreviewDetailsRight>
            {percent === 100 ? (
              <FontAwesomeIcon
                icon={faCircleCheck}
                color="#0EBF80"
                className="mr-05"
              />
            ) : null}

            {!isDisabled ? (
              <a
                href="!#"
                disabled={isDisabled}
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteFile(name);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  color="#000000"
                />
              </a>
            ) : null}
          </UploadPreviewDetailsRight>
        </UploadPreviewFileDetails>

        <UploadPreviewProgress>
          <Progress value={percent || 0} />
        </UploadPreviewProgress>
      </UploadPreviewProgressSection>
    </UploadPreviewContainer>
  );
};

FilePreview.prototype = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
};

export default React.memo(FilePreview);
