import BreadCrumbComponent from "components/customs/breadCrumb";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import {
  getFormattedContactNumber,
  getFormattedOptions,
  Toast,
} from "utility/helpers";
import { fetchAllRegions } from "services/services.congressman";
import ImgContainer from "components/common/img";
import defaultUserImage from "assets/img/default_user.png";
import {
  genericGet,
  genericPost,
  genericPut,
  uploadFile,
} from "services/services.general";
import NationalAgencyForm from "./Form";

import "../congressman/styles.scss";

const NationalAgency = () => {
  const location = useLocation();
  const row = location.state?.row || null;

  const [selectedAgencyError, setSelectedAgencyError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedRegionError, setSelectedRegionError] = useState("");
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const [formFields, setFormFields] = useState({
    userId: "",
    lastname: "",
    firstname: "",
    email: "",
    mobile: "",
    photo: "",
    photoName: "",
    designation: "",
  });

  const navigate = useNavigate();

  const validate = () => {
    if (!selectedAgency) {
      setSelectedAgencyError("Required");

      return "Required agency";
    }
    setSelectedAgencyError("");

    if (!selectedRegion) {
      setSelectedRegionError("Required");

      return "Required region";
    }
    setSelectedRegionError("");

    return true;
  };

  const setAgencyDetails = () => {
    setIsLoading(true);

    setFormFields({
      ...formFields,
      userId: row.id,
      lastname: row.lastName,
      firstname: row.firstName,
      mobile: getFormattedContactNumber(row.contact),
      email: row.email || "",
      photo: row.photo || "",
      designation: row.designation,
    });

    if (row.photo) {
      const imgContainer = document.querySelector(".national-img");
      imgContainer.src = row.photo;
    }

    const option = agencies.find(
      (_agency) => _agency.value === row.NationalAgency.id
    );

    if (option) setSelectedAgency(option);

    const region = regions.find((reg) => reg.label === row.area);

    if (region) setSelectedRegion(region);

    setIsLoading(false);
  };

  const sendData = (formData) => {
    if (!row) {
      genericPost({
        url: "/national-agency-user",
        formData,
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            setIsLoading(false);

            navigate("/directory", {
              state: { view: "national" },
            });

            Toast({
              type: 1,
              content: "Successfully created national agency account!",
            });
          } else {
            setIsLoading(false);

            Toast({
              type: 4,
              content: "An error occured.",
            });
          }
        },
      });
    } else {
      genericPut({
        url: `/national-agency-user/${formFields.userId}`,
        formData,
        callback: ({ isSuccess }) => {
          if (isSuccess) {
            setIsLoading(false);

            navigate("/directory", {
              state: { view: "national" },
            });

            Toast({
              type: 1,
              content: "Successfully updated national agency account",
            });
          } else {
            setIsLoading(false);

            Toast({
              type: 4,
              content: "An error occured.",
            });
          }
        },
      });
    }
  };

  const uploadImage = async () => {
    let fileName = "";

    await uploadFile({
      file: formFields.photo,
      fileName: formFields.photoName,
      onProgress: () => {
        //
      },
      callback: (data) => {
        if (data) fileName = data;
      },
    });

    return fileName;
  };

  const handleOnSumbmit = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      const formData = {
        name: `${formFields.firstname}  ${formFields.lastname}`,
        firstName: formFields.firstname,
        lastName: formFields.lastname,
        contact: formFields.mobile?.slice(2),
        area: selectedRegion.label,
        nationalAgencyId: selectedAgency.value,
        email: formFields.email,
        designation: formFields.designation,
        photo: formFields.photo,
        regionId: selectedRegion.value,
      };

      setIsLoading(true);

      if (typeof formFields.photo !== "string") {
        const fileName = await uploadImage();
        formData.photo = fileName;
      }

      sendData(formData);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const fetchData = () => {
    setIsLoading(true);

    fetchAllRegions({
      params: {
        includeCentral: true,
      },
      callback: (data) => {
        const options = getFormattedOptions("acronym", data);

        setRegions(options);
      },
    });

    genericGet({
      url: "national-agency",
      callback: (data) => {
        const options = getFormattedOptions("agencyAcronym", data.rows);

        setAgencies(options);
      },
    });
    setIsLoading(false);
  };

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    const reader = new FileReader();
    const fileTye = file.type.split("/")[0];

    if (fileTye === "image") {
      reader.onloadend = () => {
        const imgContainer = document.querySelector(".national-img");

        const fileName = row
          ? `${
              row.userId
            }-${formFields.lastname.toLocaleLowerCase()}-${Date.now()}`
          : `${formFields.lastname.toLocaleLowerCase()}-${Date.now()}`;

        setFormFields({
          ...formFields,
          photo: file,
          photoName: fileName,
        });

        imgContainer.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      Toast({
        type: 4,
        content: "File uploaded must be an image.",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (row && agencies.length && regions.length) {
      setAgencyDetails();
    }
  }, [row, agencies, regions]);

  return (
    <div className="add-project-container directory">
      <Col className="header">
        <Row className="d-flex justify-content-end w-100 m-0">
          <Col
            xs="6"
            className="d-flex mt-3 justify-content-end align-items-center custom-breadcrumb--comtainer"
          >
            <BreadCrumbComponent
              links={[
                {
                  name: "Home",
                  redirect: "/home",
                },
                {
                  name: "Directory",
                  redirect: "/directory",
                },
                {
                  name: !row
                    ? "Add National Agency"
                    : "National Agency Details",
                  isActive: true,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="header-actions h-75 d-flex m-0 mt-4 justify-content-between align-items-end">
          <h2 className="text-danger">
            {!row ? "Add National Agency" : "National Agency Details"}
          </h2>
        </Row>
        <div className="form-section">
          <Row>
            <Col
              xs="12"
              sm="6"
              lg="4"
              className="col--img-container"
            >
              <div className="img-container">
                <ImgContainer
                  alt="national-img"
                  className="national-img"
                  src={defaultUserImage}
                  width="196px"
                  height="196px"
                />

                <label
                  htmlFor="file-upload"
                  className="upload-btn"
                >
                  {"Upload Photo"}
                  <input
                    id="file-upload"
                    className="file-upload"
                    type="file"
                    onChange={handleFileUpload}
                  />
                </label>
              </div>
            </Col>
            <Col
              xs="12"
              sm="6"
              lg="8"
            >
              <NationalAgencyForm
                handleOnSumbmit={handleOnSumbmit}
                formFields={formFields}
                setFormFields={setFormFields}
                agencies={agencies}
                regions={regions}
                selectedAgency={selectedAgency}
                setSelectedAgency={setSelectedAgency}
                selectedAgencyError={selectedAgencyError}
                isLoading={isLoading}
                selectedRegionError={selectedRegionError}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default NationalAgency;
