import React from "react";
import Layout3 from "components/layout/Layout3";
import Recipient from "./recipient";

const RecipientComponent = (props) => {
  return (
    <Layout3>
      <Recipient {...props} />
    </Layout3>
  );
};

export default RecipientComponent;
