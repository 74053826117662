import React from "react";
import * as Yup from "yup";
import styled from "styled-components";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import classNames from "classnames";
import FormGenertor from "components/customs/form-generator/input";
import { useNavigate } from "react-router";

const StyledRed = styled.span`
  color: red;
`;
const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const validSchema = Yup.object().shape({
  lastName: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  contact: Yup.string()
    .min(12, "At least 12 digits")
    .max(12, "Cannot be more than 12 digits")
    .matches(/^(639)\d{9}$/, "Invalid format")
    .required("Required"),
});

const OthersForm = ({
  handleOnSumbmit,
  formFields,
  setFormFields,
  isLoading,
  isModal,
  setIsModalShown,
}) => {
  const navigate = useNavigate();

  return (
    <Formik
      validationSchema={validSchema}
      onSubmit={handleOnSumbmit}
      initialValues={formFields}
      enableReinitialize
    >
      {({ handleChange, values }) => (
        <Form id="add-account-form">
          <Field name="lastName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"Last Name"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="lastName"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.lastName && touched?.lastName,
                    })}
                    value={values.lastName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        lastName: evt.target.value,
                      });
                    }}
                  />

                  <ErrorMessage name="lastName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="firstName">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"First Name"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="firstName"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.firstName && touched?.firstName,
                    })}
                    value={values.firstName}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        firstName: evt.target.value,
                      });
                    }}
                  />
                  <ErrorMessage name="firstName">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="contact">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"Contact Number"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <FormGenertor
                    name="contact"
                    type="mobile"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.contact && touched?.contact,
                    })}
                    value={values.contact || "63"}
                    onChange={(value) => {
                      handleChange({
                        target: { name: "contact", value },
                      });

                      setFormFields({
                        ...formFields,
                        contact: value,
                      });
                    }}
                  />
                  <ErrorMessage name="contact">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-5  d-flex justify-content-end">
            <Button
              className="btn-cancel mr-1"
              disabled={isLoading}
              onClick={(e) => {
                if (isModal) {
                  setIsModalShown(false);
                  e.preventDefault();
                } else {
                  navigate("/directory", {
                    state: { view: "others" },
                  });
                }
              }}
            >
              {"Back"}
            </Button>

            <Button
              type="submit"
              className="btn-save"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="light"
                  size="sm"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default OthersForm;
