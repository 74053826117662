import { Row } from "reactstrap";
import styled from "styled-components";

const HouseMemberSection = styled(Row)`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
`;

const HouseMemberColRight = styled.div`
  padding-right: 107px;
  flex: 0 0 auto;
  width: 100%;

  @media (min-width: 768px) {
    flex: 0 0 auto;
    width: 50%;
  }
`;

const DeleteRowIcon = styled.div`
  width: 107px;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  display: flex;
  align-items: center;
`;

const AddRowSection = styled(Row)`
  margin-top: 1.5rem;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  padding: 1rem;
  justify-content: flex-end;
  padding-right: 107px;
`;

const UploadPreview = styled.div`
  margin-top: 1rem;
`;

const UploadPreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const UploadPreviewIconSection = styled.div`
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadPreviewProgressSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const UploadPreviewFileDetails = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 40px;
`;

const UploadPreviewDetailsLeft = styled.div`
  overflow: hidden;

  .file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
  }
`;

const UploadPreviewDetailsRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const UploadPreviewProgress = styled.div`
  margin-top: 0.5rem;
`;

const StyledRed = styled.span`
  color: red;
`;

export {
  HouseMemberSection,
  HouseMemberColRight,
  DeleteRowIcon,
  AddRowSection,
  UploadPreview,
  UploadPreviewContainer,
  UploadPreviewIconSection,
  UploadPreviewProgressSection,
  UploadPreviewFileDetails,
  UploadPreviewProgress,
  UploadPreviewDetailsLeft,
  UploadPreviewDetailsRight,
  StyledRed,
};
