import React, { useEffect, useMemo } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import {
  changeDateRangeFrom,
  changeDateRangeTo,
} from "../../../redux/reducers/dateRange";

import "./style.scss";

const START_YEAR = 2024;

const YearPicker = () => {
  const dispatch = useDispatch();
  const selectedYear = useSelector((state) => state.dateRange.dateFrom);

  const currentYear = new Date().getFullYear();

  const yearOptions = useMemo(() => {
    const options = [];

    // eslint-disable-next-line no-plusplus
    for (let year = START_YEAR; year <= currentYear; year++) {
      options.push({
        value: year.toString(),
        label: year.toString(),
      });
    }

    return options;
  }, [currentYear]);

  useEffect(() => {
    if (!selectedYear) {
      const year = currentYear.toString();
      dispatch(changeDateRangeFrom(`${year}-01-01`));
      dispatch(changeDateRangeTo(`${year}-12-31`));
    }
  }, [currentYear, selectedYear, dispatch]);

  const handleYearChange = (option) => {
    const year = option.value;
    dispatch(changeDateRangeFrom(`${year}-01-01`));
    dispatch(changeDateRangeTo(`${year}-12-31`));
  };

  return (
    <div className="input-group mb-3 year-picker-wrapper">
      <Select
        value={yearOptions.find(
          (option) => option.value === selectedYear?.split("-")[0]
        )}
        onChange={handleYearChange}
        options={yearOptions}
        className="year-select"
        classNamePrefix="year-select"
        placeholder="Select year"
        isSearchable={false}
      />
    </div>
  );
};

export default YearPicker;
