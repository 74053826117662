import styled from "styled-components";

/* Assets */
import background from "../../assets/img/BG.png";
import background1 from "../../assets/img/19thCong.png";
import horseal from "../../assets/img/HOR.png";

const Container = styled.div`
  background: #f3f3f3 url(${background}) no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
`;

const Congress = styled.div`
  background: url(${background1}) no-repeat;
  height: 100px;
  background-position: center;
`;

const HorSeal = styled.div`
  background: url(${horseal}) no-repeat;
  height: 130px;
  background-position: center;
  margin-top: 3rem;
`;

const LayoutBackground = styled.div`
  background-color: #dde8ef;
  min-height: 100vh;
`;

const NavHorSeal = styled.div`
  background: url(${horseal}) no-repeat;
  background-position: center;
  height: 50px;
  width: 50px;
`;

const NavCongress = styled.div`
  background: url(${background1}) no-repeat;
  background-position: center;
  height: 50px;
  width: 50px;
`;

const DistrictBackground = styled.div`
  background-color: white;
  min-height: 100vh;
`;

const CongLayout = styled.div`
  min-height: 100vh;
`;

export {
  Container,
  Congress,
  HorSeal,
  LayoutBackground,
  NavHorSeal,
  NavCongress,
  DistrictBackground,
  CongLayout,
};
