import api from "./api";

const fetchRawReport = ({ params, callback }) => {
  api({
    Params: params,
    Method: "GET",
    Url: "/summary/export",
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        callback({ isSuccess: true, data: response?.data });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

export { fetchRawReport };
