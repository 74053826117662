import styled from "styled-components";

/* Assets */
import addHeaderBG from "../../assets/img/BG_3.png";

const MainContainer = styled.div`
  background: url(${addHeaderBG}) no-repeat;
  background-position: bottom;
  height: 90vh;
  background-size: cover;
`;

const BreadCrumbSection = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Container = styled.div`
  margin-right: 8rem;
  margin-left: 8rem;

  @media (max-width: 768px) {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  @media (max-width: 575.98px) {
    margin-right: 2rem;
    margin-left: 2rem;
  }
`;

export { MainContainer, BreadCrumbSection, Container };
