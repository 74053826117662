import { useEffect, useRef, useState } from "react";

export default function useIsEllipsisActive() {
  const isEllipsisUsed = () => {
    const [isEllipsisActive, setIsEllipsisActive] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
      const checkEllipsis = () => {
        if (textRef.current) {
          setIsEllipsisActive(
            textRef.current.offsetHeight < textRef.current.scrollHeight
          );
        }
      };

      checkEllipsis();
    }, []);

    return { isEllipsisActive, textRef };
  };

  return { isEllipsisUsed };
}
