import React, { useState } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as FontawesomeSolid from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasPermission } from "utility/helpers";
import { getUserDetails } from "config";
import { useNavigate } from "react-router";
import { canEditRoles } from "utility/constants";

const userDetails = getUserDetails();

const cong = userDetails?.congressman || null;

const dropdownItems = [
  {
    label: "Home",
    className: "d-lg-none",
    showItem: hasPermission("landing"),
    link: "/home",
  },
  {
    label: "Representatives",
    className: "d-lg-none",
    showItem: hasPermission("congressman-view"),
    link: "/representatives",
  },
  {
    label: "Programs",
    className: "d-lg-none",
    showItem: hasPermission("project-view"),
    link: "/projects",
  },
  {
    label: "Affiliation",
    className: "d-lg-none",
    showItem: hasPermission("party-view"),
    link: "/parties",
  },
  {
    label: "Home",
    showItem:
      userDetails?.type === "Congressman" || userDetails?.type === "COS",
    link: `/representatives/${cong?.region?.id}/cong/${cong?.id}`,
  },
  {
    label: "Add Program",
    className: "d-sm-none d-lg-block d-xl-none",
    showItem: hasPermission("project-create"),
    link: "/add-project",
  },
  {
    label: "Accounts",
    className: "",
    showItem:
      canEditRoles.includes(getUserDetails()?.type) ||
      getUserDetails()?.type === "Master" ||
      getUserDetails()?.type === "Congressman",
    link: "/accounts",
  },
  {
    label: "Profile",
    className: "",
    link: "/account",
    additionalParams: {
      state: {
        action: "view",
      },
    },
  },
  {
    label: "Summary Reports",
    className: "",
    showItem: hasPermission("summary-view"),
    link: "/summary/export",
  },
  {
    label: "Utilization Reports",
    className: "",
    showItem: hasPermission("utilization-view"),
    link: "/utilization",
  },
  {
    label: "Directory",
    className: "d-lg-none",
    showItem: canEditRoles.includes(getUserDetails()?.type),
    link: "/directory",
  },
  {
    label: "News",
    className: "",
    showItem: hasPermission("news-view"),
    link: "/news",
  },
  {
    label: "Logs",
    className: "",
    showItem: hasPermission("logs-view"),
    link: "/logs",
  },
];

const UserDropdown = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <ul className="float-right mb-0">
      <Dropdown
        tag="li"
        className="dropdown-user-menu nav-item p-0"
        isOpen={collapsed}
        toggle={toggleNavbar}
      >
        <DropdownToggle
          tag="a"
          className="nav-link user-dropdown"
        >
          <FontAwesomeIcon
            icon={FontawesomeSolid.faBars}
            size="xl"
          />
        </DropdownToggle>

        <DropdownMenu end>
          {dropdownItems.map(
            (
              { label, link, showItem = true, className, additionalParams },
              index
            ) =>
              showItem && (
                <DropdownItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={className}
                  onClick={() => {
                    navigate(link, additionalParams);
                  }}
                >
                  {label}
                </DropdownItem>
              )
          )}
          <DropdownItem
            divider
            className=""
          />
          <DropdownItem
            tag="a"
            href="/logout"
          >
            {"Logout"}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </ul>
  );
};

export default UserDropdown;
