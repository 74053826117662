import React from "react";
import PropTypes from "prop-types";
import ImgContainer from "components/common/img";
import { Col } from "reactstrap";
import { useNavigate } from "react-router";

import "./styles.scss";

const DistrictCard = (props) => {
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate(props?.link);
  };

  return (
    <a
      href="!#"
      className="custom-district-card mx-2"
      onClick={(e) => handleOnClick(e)}
    >
      <ImgContainer
        src={props?.img}
        height="310px"
      />

      <div className="district-details p-2 row flex-column">
        <Col>
          <h6 className="">
            <span className="text-bold">{props?.lastName}</span>
            <span>{`, ${props?.firstName} ${
              props?.middleInitial !== null ? props?.middleInitial : ""
            }`}</span>
          </h6>
        </Col>
        <Col className="d-flex flex-column">
          {props?.Party?.acronym !== "Partylist" ? (
            <small className="mb-0">{props?.Party?.acronym}</small>
          ) : null}

          <small className="mb-0">{props?.District?.name?.split("-")[0]}</small>
        </Col>
      </div>
    </a>
  );
};

DistrictCard.propTypes = {
  img: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  middleInitial: PropTypes.string,
  Party: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  District: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
};

export default DistrictCard;
