import React, { useEffect } from "react";
import BreadCrumbComponent from "components/customs/breadCrumb";
import { Col, Spinner } from "reactstrap";
import FormGenertor from "components/customs/form-generator/input";
import CustomCheckbox from "components/customs/checkbox";
import {
  addProjectDropdownStyles,
  DropdownIndicator2,
} from "utility/constants";
import { Check } from "react-feather";
import CustomDropzone from "views/projects/add/Dropzone";
import { Toast } from "utility/helpers";
import { updateDraftNews } from "services/serivces.news";
import { useLocation, useNavigate } from "react-router";
import { genericGet, genericPost } from "services/services.general";
import CustomSelect from "components/customs/dropdown";
import {
  fetchAllRegions,
  fetchCongressmanDetails,
  fetchRegionalCongressman,
} from "services/services.congressman";
import {
  BreadCrumbSection,
  HeaderTextSection,
  MainContainer,
  Container,
  FormSection,
  RowSection,
  StyledRed,
  FormColRight,
  CharacterCountSection,
  SendWithOptions,
  BtnSend,
  BtnSection,
  BtnCancel,
} from "../styled";
import RecipientTable from "./Table";

const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const officeOptions = [
  {
    value: 1,
    label: "Congressional Office",
  },
  {
    value: 2,
    label: "National Agencies",
  },
];

const AddAnnouncementForm = () => {
  const location = useLocation();
  const row = location.state?.row || null;
  const { action } = location.state;

  const [form, setForm] = React.useState({
    title: "",
    description: "",
    message: "",
    sms: true,
    email: false,
    cos: false,
  });

  const [recipients, setRecipients] = React.useState({
    include: [],
    exclude: [],
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [selectedOffice, setSelectedOffice] = React.useState(null);
  const [regionOptions, setRegionOptions] = React.useState([]);
  const [selectedRegion, setSelectedRegion] = React.useState(null);
  const [agencyOptions, setAgencyOptions] = React.useState([]);
  const [selectedRecipients, setSelectedRecipients] = React.useState([]);
  const [selectedAgencies, setSelectedAgencies] = React.useState([]);
  const [isCustomSelected, setIsCustomSelected] = React.useState(false);

  const navigate = useNavigate();

  const resetForm = () => {
    setRecipients({
      include: [],
      exclude: [],
    });

    setForm({
      title: "",
      description: "",
      message: "",
      sms: false,
      email: false,
      cos: false,
    });

    setUploadedFiles([]);
    setSelectedOffice(null);
    setSelectedRegion(null);
    setSelectedRecipients([]);
    setSelectedAgencies([]);
  };

  const sendData = (formData) => {
    genericPost({
      url: "/news",
      formData,
      callback: ({ isSuccess }) => {
        setIsLoading(false);

        if (isSuccess) {
          navigate("/news");

          Toast({
            type: 1,
            content: "Successfully created news.",
          });
        } else {
          Toast({
            type: 4,
            content: "An error occured.",
          });
        }
      },
    });
  };

  const sendEmail = (formData) => {
    genericPost({
      url: "/news/send-email",
      formData,
      callback: ({ isSuccess }) => {
        setIsLoading(false);

        if (!isSuccess) {
          Toast({
            type: 4,
            content: "An error occured when sending emails.",
          });
        }
      },
    });
  };

  const sendFinalDraft = (formData) => {
    updateDraftNews({
      param: row.id,
      formData,
      callback: ({ isSuccess }) => {
        setIsLoading(false);

        if (isSuccess) {
          resetForm();
          navigate("/news");

          Toast({
            type: 1,
            content: "Successfully edited news.",
          });
        } else {
          Toast({
            type: 4,
            content: "An error occured.",
          });
        }
      },
    });
  };

  const validate = () => {
    if (!form.title) {
      return "News title cannot be empty";
    }

    if (!form.message) {
      return "News message cannot be empty";
    }

    if (recipients.include.length === 0) {
      return "Recipient cannot be empty";
    }

    if (!selectedOffice) {
      return "Office cannot be empty";
    }

    if (!selectedRegion) {
      return "Region cannot be empty";
    }

    return true;
  };

  const handleSendOnClicked = () => {
    const isValid = validate();

    if (typeof isValid === "boolean") {
      setIsLoading(true);

      const formData = {
        title: form.title,
        description: form.description,
        attachedFileURL: uploadedFiles?.map((file) => file.location),
        excludedRecipients: "",
      };

      const emailForm = {
        title: form.title,
        message: form.message,
        includeCOS: form.cos ? 1 : 0,
      };

      if (!row) {
        formData.message = form.message;
        formData.sendWithSMS = form.sms;
        formData.sendWithEmail = form.email;
        formData.includeCOS = form.cos;
        formData.recipients = recipients.include.map((id) => id).join(";");
        formData.office = selectedOffice.label;
        formData.agencies = selectedAgencies.map((agency) => agency.value);

        formData.regionId =
          selectedRegion.value !== 0 ? selectedRegion.value : null;

        emailForm.recipients = recipients.include.map((id) => id).join(";");

        sendData(formData);
        if (form.email) sendEmail(emailForm);
      } else {
        formData.id = row.id;
        sendFinalDraft(formData);
      }
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const handleUploadedFiles = (link) => {
    setUploadedFiles((prevUploadedFiles) => {
      const fileIndex = prevUploadedFiles.findIndex(
        (file) => file.name === link.name
      );

      if (fileIndex !== -1) {
        const updatedFiles = [...prevUploadedFiles];
        updatedFiles[fileIndex] = link;

        return updatedFiles;
      }

      if (prevUploadedFiles.length < 5) {
        return [...prevUploadedFiles, link];
      }

      Toast({
        type: 4,
        content: "Exceeded file upload limit of 5",
      });

      return prevUploadedFiles;
    });
  };

  const handleDeleteFile = (file) => {
    const newUploadedFiles = uploadedFiles.filter(
      (files) => files.name !== file
    );
    setUploadedFiles(newUploadedFiles);
  };

  const fetchAgencies = () => {
    genericGet({
      url: "/national-agency",
      callback: (data) => {
        const options = data.rows.map(({ agencyAcronym, id }) => ({
          label: agencyAcronym,
          value: id,
        }));

        setAgencyOptions(options);
      },
    });
  };

  const fetchRegions = () => {
    fetchAllRegions({
      params: {
        includeCentral: true,
      },
      callback: (data) => {
        if (data.length) {
          let options = data.map(({ acronym, id, omegaId }) => ({
            omegaId,
            label: acronym,
            value: id,
          }));

          if (selectedOffice?.value === 1) {
            options.unshift({
              value: 0,
              label: "Custom",
            });
          } else {
            options = options.filter((op) => op.value !== 18);
          }

          setRegionOptions(options);
        }
      },
    });
  };

  const setFromData = () => {
    setForm({
      title: row.title,
      description: row.description,
      message: row.message,
      cos: row.includeCOS,
      sms: row.sendWithSMS,
      email: row.sendWithEmail,
    });

    const files = [];

    if (row?.attachedFileURL?.lenght !== 0) {
      row?.attachedFileURL?.forEach((fileURL) => {
        const fileArray = fileURL.split("/");

        const file = {
          name: decodeURIComponent(fileArray[fileArray.length - 1]),
          location: fileURL,
          percent: 100,
        };

        files.push(file);
      });
    }

    setUploadedFiles(files);
    const office = officeOptions.find((off) => off.label === row.office);

    if (office) setSelectedOffice(office);
    else {
      setSelectedOffice(officeOptions[0]);
    }
  };

  const fetchAllRegionalCongs = () => {
    fetchRegionalCongressman({
      params: selectedRegion?.value,
      callback: (data) => {
        if (data?.length !== 0) {
          const newData = data.map(({ id, fullName, District, contact }) => ({
            name: fullName,
            id,
            designation: "",
            agency: "",
            area: District?.Region?.acronym || "",
            contact,
          }));

          setSelectedRecipients(newData);
          const included = row?.recipients?.split(";");

          const includedIds = !included
            ? data?.map(({ id }) => id)
            : data
                .filter(({ id }) => included.includes(id.toString()))
                .map(({ id }) => id);

          setRecipients({
            ...recipients,
            include: includedIds,
          });
        } else {
          setSelectedRecipients([]);

          setRecipients({
            ...recipients,
            include: [],
          });
        }
      },
    });
  };

  const fetchNationalAgencies = () => {
    const agencies = selectedAgencies.map((agency) => agency.value).join(",");
    const region = selectedRegion?.value || "";

    genericGet({
      url: "/national-agency-user",
      params: {
        agency: agencies,
        region,
        pagination: false,
      },
      callback: (data) => {
        if (data.rows.length !== 0) {
          const newData = data.rows.map(
            ({ id, area, name, designation, contact, NationalAgency }) => ({
              name,
              id,
              designation,
              contact,
              agency: NationalAgency.agencyAcronym,
              area,
            })
          );

          setSelectedRecipients(newData);

          const included = row?.recipients?.split(";");

          const includedIds = !included
            ? data.rows.map(({ id }) => id)
            : data.rows
                .filter(({ id }) => included.includes(id.toString()))
                .map(({ id }) => id);

          setRecipients({
            ...recipients,
            include: includedIds,
          });
        } else {
          setSelectedRecipients([]);

          setRecipients({
            ...recipients,
            include: [],
          });
        }
      },
    });
  };

  const setSelectedCustomRecipients = async () => {
    try {
      setIsLoading(true);
      let included = row?.recipients?.split(";");
      included = included.map((id) => Number(id));
      const selecRecipients = [];

      const promises = included.map((recipient) => {
        return new Promise((resolve) => {
          fetchCongressmanDetails({
            url: `/congressman/${recipient}`,
            callback: (data) => {
              const newData = {
                name: data.fullName,
                id: data.id,
                designation: "",
                contact: data.contact,
                agency: "",
                area: data.District.Region.acronym,
              };
              selecRecipients.push(newData);
              resolve();
            },
          });
        });
      });

      await Promise.all(promises);

      setSelectedRecipients(selecRecipients);

      setRecipients({
        ...recipients,
        include: included,
      });

      setIsLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    if (row && regionOptions.length) {
      if (row.region) {
        const region = regionOptions.find((reg) => reg.value === row.region.id);

        if (region) setSelectedRegion(region);
      } else {
        setSelectedRegion({
          value: 0,
          label: "Custom",
        });
        setSelectedCustomRecipients();
      }
    }
  }, [row, regionOptions]);

  useEffect(() => {
    if (row && agencyOptions.length) {
      if (row.agencies && row.agencies.length) {
        const agency = agencyOptions.filter((agen) =>
          row.agencies.includes(agen.value)
        );

        if (agency) setSelectedAgencies(agency);
      }
    }
  }, [row, agencyOptions]);

  useEffect(() => {
    if (row) {
      setFromData();
    }
  }, [row]);

  useEffect(() => {
    fetchAgencies();
  }, []);

  useEffect(() => {
    if (selectedOffice) {
      fetchRegions();
      setSelectedAgencies([]);
      setSelectedRecipients([]);
      setSelectedRegion(null);
      if (selectedOffice?.value === 2) fetchNationalAgencies();
    }
  }, [selectedOffice]);

  useEffect(() => {
    if (selectedRegion) {
      if (selectedOffice?.value === 1) {
        fetchAllRegionalCongs();
      } else {
        fetchNationalAgencies();
      }
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedOffice?.value === 2) {
      fetchNationalAgencies();
    }
  }, [selectedAgencies]);

  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "News & Announcements",
                redirect: "/news",
              },
              {
                name: row ? "Edit Post" : "Create Post",
                isActive: true,
              },
            ]}
          />
        </BreadCrumbSection>

        <HeaderTextSection>
          <h2 className="text-danger">
            {/* eslint-disable-next-line no-nested-ternary */}
            {action === "edit"
              ? "Edit Post"
              : action !== "view"
              ? "Create a New Post"
              : "Post Details"}
          </h2>
        </HeaderTextSection>

        <FormSection>
          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Office"}
                {RequiredIndicator}
              </h5>
              <p>{"Choose your office"}</p>
            </Col>
            <FormColRight>
              <CustomSelect
                classNamePrefix="select"
                placeholder="Office"
                options={officeOptions}
                onChange={(option) => setSelectedOffice(option)}
                styles={addProjectDropdownStyles}
                value={selectedOffice}
                components={{ DropdownIndicator: DropdownIndicator2 }}
                isDisabled={action !== "add"}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Region"}
                {RequiredIndicator}
              </h5>
              <p>{"Choose your region"}</p>
            </Col>
            <FormColRight>
              <CustomSelect
                classNamePrefix="select"
                placeholder="Region"
                options={regionOptions}
                onChange={(option) => {
                  if (option.value === 0) {
                    setIsCustomSelected(true);
                  } else {
                    setIsCustomSelected(false);
                  }
                  setSelectedRegion(option);
                }}
                styles={addProjectDropdownStyles}
                value={selectedRegion}
                components={{ DropdownIndicator: DropdownIndicator2 }}
                isDisabled={action !== "add"}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"News Name"}
                {RequiredIndicator}
              </h5>
              <p>{"Write the title of your news or announcement"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="text"
                placeholder="Enter Your Title Here"
                className="form-control"
                value={form.title}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  setForm({
                    ...form,
                    title: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Upload a File"}</h5>
              <p>{"Upload your document"}</p>
            </Col>
            <FormColRight>
              <CustomDropzone
                handleUploadedFiles={handleUploadedFiles}
                handleDeleteFile={handleDeleteFile}
                uploadedFiles={uploadedFiles}
                label="Upload Files"
                isDisabled={action === "view"}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Description"}</h5>
              <p>{"Write your description"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="textarea"
                placeholder="Enter Text Here"
                className="w-100 input-textarea form-control"
                rows="5"
                value={form.description}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  setForm({
                    ...form,
                    description: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          {selectedOffice?.value === 2 && (
            <RowSection>
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">{"Agencies"}</h5>
                <p>{"Choose your agencies"}</p>
              </Col>
              <FormColRight>
                <CustomSelect
                  isMulti
                  classNamePrefix="select"
                  placeholder="Agencies"
                  onChange={(option) => setSelectedAgencies(option)}
                  options={agencyOptions}
                  styles={addProjectDropdownStyles}
                  value={selectedAgencies}
                  components={{ DropdownIndicator: DropdownIndicator2 }}
                  isDisabled={action !== "add"}
                />
              </FormColRight>
            </RowSection>
          )}

          <RecipientTable
            data={selectedRecipients}
            setRecipients={setRecipients}
            recipients={recipients}
            action={action}
            isCustomSelected={isCustomSelected}
          />

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Include the COS"}</h5>
            </Col>
            <FormColRight>
              <CustomCheckbox
                checked={form.cos}
                label="COS"
                disabled={action !== "add"}
                icon={
                  <Check
                    className="vx-icon"
                    size={16}
                  />
                }
                onChange={(evt) => {
                  const { checked } = evt.target;

                  setForm({
                    ...form,
                    cos: checked,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Send with?"}</h5>
            </Col>
            <FormColRight>
              <SendWithOptions>
                <CustomCheckbox
                  className="mr-2"
                  checked={form.sms}
                  label="SMS"
                  disabled={action !== "add"}
                  icon={
                    <Check
                      className="vx-icon"
                      size={16}
                    />
                  }
                  onChange={(evt) => {
                    const { checked } = evt.target;

                    setForm({
                      ...form,
                      sms: checked,
                    });
                  }}
                />

                <CustomCheckbox
                  checked={form.email}
                  label="Email"
                  disabled={action !== "add"}
                  icon={
                    <Check
                      className="vx-icon"
                      size={16}
                    />
                  }
                  onChange={(evt) => {
                    const { checked } = evt.target;

                    setForm({
                      ...form,
                      email: checked,
                    });
                  }}
                />
              </SendWithOptions>
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Message"}
                {RequiredIndicator}
              </h5>
              <p>{"Write your message"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="textarea"
                placeholder="Enter Text Here"
                className="w-100 input-textarea form-control"
                rows="5"
                value={form.message}
                maxLength={600}
                isDisabled={action !== "add"}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  if (value.lenght !== 600) {
                    setForm({
                      ...form,
                      message: value,
                    });
                  }
                }}
              />
              <CharacterCountSection>
                {`Characters remaining: ${600 - form.message.length}`}
              </CharacterCountSection>
            </FormColRight>
          </RowSection>
        </FormSection>

        <BtnSection>
          <BtnCancel
            disabled={isLoading}
            onClick={() => navigate("/news")}
          >
            {"Back"}
          </BtnCancel>

          <BtnSend
            onClick={() => handleSendOnClicked()}
            disabled={isLoading || action === "view"}
          >
            {isLoading ? (
              <Spinner
                animation="border"
                color="light"
                size="sm"
              />
            ) : (
              "Send"
            )}
          </BtnSend>
        </BtnSection>
      </Container>
    </MainContainer>
  );
};

export default AddAnnouncementForm;
