import React from "react";
import Main from "./Main";
import LandingPageLayout from "../../components/layout/LandingPageLayout";

const Home = (props) => {
  return (
    <LandingPageLayout>
      <Main {...props} />
    </LandingPageLayout>
  );
};

export default Home;
