import React from "react";
import Layout2 from "components/layout/Layout2";
import Main from "./List";

const Directory = (props) => {
  return (
    <Layout2>
      <Main {...props} />
    </Layout2>
  );
};

export default Directory;
