import React from "react";
import Layout3 from "components/layout/Layout3";
import Main from "./Main";

const NationalAgenciesComponent = (props) => {
  return (
    <Layout3>
      <Main {...props} />
    </Layout3>
  );
};

export default NationalAgenciesComponent;
