import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Spinner } from "reactstrap";
import {
  setAccessToken,
  setRefreshToken,
  setIdToken,
  getUserDetails,
} from "config";
import LoginComponent from "views/auth/Login";

const PrivateRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const userDetails = getUserDetails();
  const cong = userDetails?.congressman || null;

  const congViews = [
    "/login",
    `/representatives/${cong?.region?.id}/cong/${cong?.id}`,
    "/account",
    "/add-project",
    "/accounts",
    "/add-account",
    `/representatives/${cong?.region?.id}/cong/${cong?.id}/recipient`,
    "/news",
    "/news/view",
    "/utilization",
    "/utilization/add",
    "/utilization/edit",
    "/404",
    "/logout",
  ];

  const cosViews = [
    "/login",
    `/representatives/${cong?.region?.id}/cong/${cong?.id}`,
    "/account",
    "/add-project",
    `/representatives/${cong?.region?.id}/cong/${cong?.id}/recipient`,
    "/news",
    "/news/view",
    "/utilization",
    "/utilization/add",
    "/utilization/edit",
    "/404",
    "/logout",
  ];

  const getLocalSession = async () => {
    try {
      const session = await Auth.currentSession();

      if (session) {
        setAccessToken(session?.accessToken?.jwtToken);
        setRefreshToken(session?.refreshToken?.token);
        setIdToken(session?.idToken?.jwtToken);
        setIsLoggedIn(true);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      if (
        e === "No current user" ||
        Object.values(e)[0] === "NotAuthorizedException"
      ) {
        localStorage.clear();
        setIsLoggedIn(false);
        navigate("/login");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLocalSession();
  }, []);

  useEffect(() => {
    if (isLoading) return;

    if (
      userDetails?.type === "COS" &&
      (window.location.pathname === "/home" || window.location.pathname === "/")
    ) {
      navigate(`/representatives/${cong?.region?.id}/cong/${cong?.id}`);
    }

    if (
      userDetails?.type === "COS" &&
      !cosViews.includes(window.location.pathname)
    ) {
      navigate("/404");
    }

    if (
      userDetails?.type === "Congressman" &&
      (window.location.pathname === "/home" || window.location.pathname === "/")
    ) {
      navigate(`/representatives/${cong?.region?.id}/cong/${cong?.id}`);
    }

    if (
      userDetails?.type === "Congressman" &&
      !congViews.includes(window.location.pathname)
    ) {
      navigate("/404");
    }
  }, [isLoading, userDetails, cong]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isLoggedIn) {
    return <Outlet />;
  }

  return <LoginComponent />;
};

export default PrivateRoute;
