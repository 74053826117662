import axios from "axios";
import { getAccessToken, APP_URL } from "config";
import { getLocalSession, Toast } from "utility/helpers";
import { setPendingProjects } from "../reducers/projects";

export const fetchPendingProjects = () => {
  return async (dispatch) => {
    const token = getAccessToken();

    try {
      const response = await axios.get(`${APP_URL}/recipient?status=Pending`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const pendingCount = response.data?.length;
      dispatch(setPendingProjects(pendingCount));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      if (error?.response?.request?.status === 401) {
        getLocalSession();

        setTimeout(() => {
          dispatch(fetchPendingProjects());
        }, 1500);
      } else {
        Toast({
          type: 4,
          content: error,
        });
      }
    }
  };
};
