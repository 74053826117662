import axios from "axios";
import api from "./api";

export const genericDelete = ({ url, callback }) => {
  api({
    Method: "DELETE",
    Url: url,
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: () => {
      callback({ isSuccess: false });
    },
  });
};

export const genericPost = ({ url, formData, callback }) => {
  api({
    Method: "POST",
    Data: formData,
    Url: url,
    ResponseSuccessCallback: (response) => {
      if (response && (response?.status === 201 || response?.status === 200)) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: (response) => {
      callback({ isSuccess: false, msg: response.response?.data?.message });
    },
  });
};

export const genericGet = ({ url, params, callback }) => {
  api({
    Method: "GET",
    Url: url,
    Params: params,
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        callback(response.data);
      }
    },
    ResponseFailCallback: () => {
      callback();
    },
  });
};

const getPresignedUrl = (fileName) => {
  return new Promise((resolve, reject) => {
    api({
      Method: "GET",
      Url: "/file/presigned-upload-url",
      Params: {
        objectName: `cong/${fileName}`,
      },
      ResponseSuccessCallback: (response) => {
        if (response && response.status === 200) {
          resolve(response.data);
        } else {
          reject(new Error("Failed to get presigned URL"));
        }
      },
      ResponseFailCallback: (error) => {
        reject(error);
      },
    });
  });
};

export const uploadFile = async ({ file, fileName, onProgress, callback }) => {
  const url = await getPresignedUrl(fileName);

  if (url) {
    const headers = {
      "Content-Type": "application/octet-stream",
    };

    try {
      const response = await axios.put(url, file, {
        headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress(progress);
        },
      });

      if (response && response?.status === 200) {
        callback(url.split("?")[0]);
      }
    } catch (Exception) {
      // eslint-disable-next-line no-console
      console.error(Exception);
    }
  }
};

const getDownloadablePresignedUrl = (fileName) => {
  return new Promise((resolve, reject) => {
    api({
      Method: "GET",
      Url: "/file/presigned-download-url",
      Params: {
        objectName: fileName,
      },
      ResponseSuccessCallback: (response) => {
        if (response && response.status === 200) {
          resolve(response.data);
        } else {
          reject(new Error("Failed to get download presigned URL"));
        }
      },
      ResponseFailCallback: (error) => {
        reject(error);
      },
    });
  });
};

// eslint-disable-next-line consistent-return
export const downloadS3File = async (fileName) => {
  try {
    const url = await getDownloadablePresignedUrl(fileName);

    const response = await axios.get(url, {
      responseType: "blob",
    });

    if (response && response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const genericPut = ({ url, formData, callback }) => {
  api({
    Method: "PUT",
    Data: formData,
    Url: url,
    ResponseSuccessCallback: (response) => {
      if (response && (response?.status === 201 || response?.status === 200)) {
        callback({ isSuccess: true });
      }
    },
    ResponseFailCallback: (response) => {
      callback({ isSuccess: false, msg: response.response?.data?.message });
    },
  });
};
