import React, { useState } from "react";
import { Button, FormGroup, Spinner, Label, Input } from "reactstrap";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Toast } from "utility/helpers";

const ChangePasswordForm = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const navigate = useNavigate();

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value;
    const passwordInputFieldName = evnt.target.name;

    // for password
    if (passwordInputFieldName === "newpassword") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);

      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";

      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }

    // for confirm password
    if (
      passwordInputFieldName === "confirmpassword" ||
      (passwordInputFieldName === "newpassword" && confirmPassword.length > 0)
    ) {
      if (confirmPassword !== newPassword) {
        setConfirmPasswordError("Confirm password does not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    setIsLoggingIn(true);

    if (confirmPassword?.length !== 0) {
      Auth.completeNewPassword(newPassword)
        .then(() => {
          // const user = Auth.currentAuthenticatedUser();
          // setCognitoUser(user);
          setIsLoggingIn(false);

          navigate("/home");
        })
        .catch((Exception) => {
          setIsLoggingIn(false);

          Toast({
            type: 4,
            content: Exception?.message,
          });
        });
    }

    setConfirmPasswordError("Confirm password is empty");
    setIsLoggingIn(false);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleChangePassword}
      className="login-form w-75"
    >
      <div className="login-card--header mb-5">
        <h1 className="login-card--header-text">{"CHANGE PASSWORD"}</h1>
        <small className="text-muted">{"Change your temporary password"}</small>
      </div>
      <FormGroup className="mb-4">
        <Label className="mb-0">
          <small>{"New Password"}</small>
        </Label>
        <span className="form-control-feedback">
          <FontAwesomeIcon icon={faLock} />
        </span>

        <Input
          type="password"
          className="form-control"
          name="newpassword"
          onBlur={(e) => {
            setNewPassword(e.target.value);
            handleValidation(e);
          }}
          onChange={(e) => {
            setNewPassword(e.target.value);
            handleValidation(e);
          }}
        />

        <small className="error text-danger">{passwordError}</small>
      </FormGroup>
      <FormGroup className=" mb-4">
        <Label className="mb-0">
          <small>{"Confirm New Password"}</small>
        </Label>
        <span className="form-control-feedback">
          <FontAwesomeIcon icon={faLock} />
        </span>
        <Input
          type="password"
          className="form-control"
          name="confirmpassword"
          onBlur={(e) => {
            setConfirmPassword(e.target.value);
            handleValidation(e);
          }}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            handleValidation(e);
          }}
        />

        <small className="error text-danger">{confirmPasswordError}</small>
      </FormGroup>

      <div className="d-flex justify-content-start mt-5">
        <Button
          type="submit"
          className="submit-btn"
          // onClick={handleChangePassword}
        >
          {isLoggingIn ? (
            <Spinner
              animation="border"
              color="light"
              size="sm"
            />
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
