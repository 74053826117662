import React from "react";
import { Button, Col, Row } from "reactstrap";
import illustration from "assets/img/illustration.png";
import { BreadCrumbSection, MainContainer, Container } from "./styled";
import "./styles.scss";

const NotFound = () => {
  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          <Col
            className="mt-5 no-access"
            lg="12"
          >
            <Row className="d-flex justify-content-center m-0 align-items-center">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={illustration}
                  alt="404 illustration"
                  className="no-access-icon"
                />
              </div>
            </Row>
            <Row>
              <Col className="d-grid m-0">
                <div>
                  <h1 className="d-flex justify-content-center m-0 align-items-center no-access-label">
                    {"404"}
                  </h1>
                  <p className="d-flex justify-content-center m-0 align-items-center no-access-desc">
                    {"Uh oh! The page you are looking for couldn’t be found\r"}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center mt-4 align-items-center">
                <Button
                  href="/"
                  className="no-access-btn"
                >
                  {"Go to Home"}
                </Button>
              </Col>
            </Row>
          </Col>
        </BreadCrumbSection>
      </Container>
    </MainContainer>
  );
};

export default NotFound;
