import React from "react";
import Login from "views/auth/Login";
import Logout from "views/auth/Logout";
import NotFound from "views/404";

export const publicRoutes = [
  {
    exact: false,
    path: "/login",
    element: <Login />,
    HasPermisson: true,
  },
  {
    exact: false,
    path: "/logout",
    element: <Logout />,
    HasPermisson: true,
  },
  {
    exact: true,
    path: "/404",
    element: <NotFound />,
    HasPermisson: true,
  },
];
