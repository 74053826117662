import React from "react";
import HomeNavbar from "./navbar";
import { Container } from "./style";

const LandingPageLayout = ({ children }) => {
  return (
    <Container>
      <HomeNavbar />
      {children}
    </Container>
  );
};

export default LandingPageLayout;
