import axios from "axios";
import { APP_URL, getAccessToken } from "config";
import { getLocalSession } from "utility/helpers";

const api = (config) => {
  const {
    Method,
    Data,
    Params,
    Url,
    Headers,
    ResponseSuccessCallback,
    ResponseFailCallback,
  } = config;
  const token = getAccessToken();

  axios({
    baseURL: APP_URL,
    data: Data || null,
    params: Method === "GET" ? Params : null,
    headers: {
      Authorization: `Bearer ${token}`,
      ...Headers,
    },
    method: Method,
    url: Url,
  })
    .then((response) => {
      ResponseSuccessCallback(response);
    })
    .catch((thrown) => {
      // eslint-disable-next-line no-console
      console.error(thrown);

      if (
        thrown?.response?.request?.status === 401 ||
        thrown.response.data === "Failed to verify token"
      ) {
        getLocalSession();

        setTimeout(() => {
          api(config);
        }, 2000);
      }

      if (ResponseFailCallback) ResponseFailCallback(thrown);
    });
};

export default api;
