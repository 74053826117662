import ImgContainer from "components/common/img";
import DropzoneComponent from "components/customs/dropzone";
import React from "react";
import upload from "assets/img/upload.svg";
import { Toast } from "utility/helpers";
import { uploadFile } from "services/services.general";
import FilePreview from "./FilePreview";
import { UploadPreview } from "../styled";

const maxFileSize = 50 * 1024 * 1024;

const CustomDropzone = ({
  handleUploadedFiles,
  handleDeleteFile,
  uploadedFiles,
  label,
  acceptedFileTypes,
  acceptedFileTypeErrorMsg,
  isDisabled,
}) => {
  const [files, setfiles] = React.useState([]);
  const [uploadProgress, setUploadProgress] = React.useState(0);

  // eslint-disable-next-line require-await
  const handleUploadFiles = async () => {
    try {
      await Promise.all(
        files?.map(async (file) => {
          await uploadFile({
            file,
            fileName: file.name,
            onProgress: (progress) => {
              setUploadProgress((prevProgress) => ({
                ...prevProgress,
                [file.name]: progress,
              }));

              const newFile = {
                name: file.name,
                size: file.size,
                percent: progress, // Track progress
                location: null, // No location yet during upload
              };
              handleUploadedFiles(newFile);
            },
            callback: (data) => {
              if (data) {
                const updatedFile = {
                  name: file.name,
                  size: file.size,
                  percent: 100, // Set progress to 100% once done
                  location: data, // Set the uploaded file location
                };
                handleUploadedFiles(updatedFile);
              }
            },
          });
        })
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error uploading files", error);
    }
  };

  const handleOnDrop = (fileArray) => {
    const filteredFiles = fileArray.filter((file) => {
      if (file.size > maxFileSize) {
        // 50MB in bytes
        Toast({
          type: 4,
          content: `${file.name} exceeds the 50MB size limit.`,
        });

        return false;
      }

      return true;
    });

    if (acceptedFileTypes) {
      const acceptedFiles = filteredFiles.filter((file) =>
        acceptedFileTypes.includes(file.type)
      );

      setfiles(acceptedFiles);

      if (!acceptedFiles.length) {
        Toast({
          type: 4,
          content: acceptedFileTypeErrorMsg,
        });
      }
    }

    if (!acceptedFileTypes) {
      setfiles(filteredFiles);
    }
  };

  React.useEffect(() => {
    if (files.length) {
      handleUploadFiles();
    }
  }, [files]);

  return (
    <>
      <DropzoneComponent
        handleOnDrop={handleOnDrop}
        className="dropzone-container"
        label={label}
        isDisabled={isDisabled}
        uploadIcon={
          <ImgContainer
            className="mr-05"
            src={upload}
            height="24px"
          />
        }
      />

      <UploadPreview>
        {uploadedFiles?.length !== 0
          ? uploadedFiles?.map((file, idx) => (
              <FilePreview
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                handleDeleteFile={handleDeleteFile}
                isDisabled={isDisabled}
                percent={uploadProgress[file.name] || file.percent}
                {...file}
              />
            ))
          : null}
      </UploadPreview>
    </>
  );
};

export default CustomDropzone;
