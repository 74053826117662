import React, { useEffect, useState } from "react";
import { splitArrayToChunks } from "utility/helpers";
import { Row, Col } from "reactstrap";
import api from "services/api";
import RepresentativesContainer from "../../components/customs/representativesContainer";

const Main = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);

  const getFormattedData = () => {
    const newData = splitArrayToChunks(data, 3);
    let resultData = [];

    resultData =
      newData &&
      newData?.length &&
      newData?.length !== 0 &&
      newData.map((datum) => {
        return (
          datum?.length &&
          datum?.length !== 0 &&
          datum?.map((d) => {
            return {
              acronym: d.acronym,
              name: d.name,
              link: `/representatives/${d.id}`,
              label: `${d.acronym} - ${d.name}`,
              id: d?.id,
            };
          })
        );
      });

    return resultData;
  };

  const fetchData = async () => {
    await api({
      Method: "GET",
      Url: "/region",
      ResponseSuccessCallback: (response) => {
        if (response?.data) {
          const regions = response?.data?.length !== 0 ? response?.data : [];
          setData(regions);
        }
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data?.length !== 0) {
      const result = getFormattedData();
      setFilteredData(result);
    }
  }, [data]);

  return (
    <>
      <div className="d-flex justify-content-center my-5">
        <h1>{"House Members"}</h1>
      </div>

      <Row className="m-0 representatives-container pb-5">
        {filteredData?.length !== 0 &&
          filteredData?.map((datum) => {
            return (
              <Col
                key={datum?.id}
                xs="auto"
                className="px-3"
              >
                {datum?.map((d) => {
                  return (
                    <Col
                      key={d?.id}
                      className="my-4 rep-parent-container"
                    >
                      <RepresentativesContainer
                        key={d?.id}
                        label={d?.label}
                        link={d?.link}
                      />
                    </Col>
                  );
                })}
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default Main;
