import styled from "styled-components";
import { Button } from "reactstrap";

/* Assets */
import addHeaderBG from "../../assets/img/BG_1.png";

const SearchSection = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;

  input {
    box-shadow: initial;
  }
`;

const AddAccountSection = styled.div`
  display: flex;

  a {
    text-decoration: none;
  }

  @media (max-width: 575.98px) {
    p {
      display: none;
    }
  }
`;

const HeaderActions = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 160px;
`;

const AccountsTableContainer = styled.div`
  margin-left: 8rem;
  margin-right: 8rem;

  @media (max-width: 768px) {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  @media (max-width: 575.98px) {
    margin-right: 2rem;
    margin-left: 2rem;
  }
`;

const AccountsTableBackground = styled.div`
  background: url(${addHeaderBG}) no-repeat;
  background-position: bottom;
  height: 200px;
  background-size: cover;
`;

const TableSection = styled.div`
  margin-top: 4rem;
`;

const FormSection = styled.div`
  margin-top: 3rem;
  padding-bottom: 5rem;

  .form-control {
    background: #f5f5f7;
    border-radius: 16px;
    height: 60px;

    &:disabled {
      background-color: initial;
      color: hsl(0, 0%, 60%);
    }
  }

  .btn-save {
    background: #4252c9;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    height: 50px;
    width: 140px;
  }

  .btn-cancel {
    background: #f3f3f3;
    box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
    border-radius: 11px;
    height: 50px;
    width: 140px;
    color: black;
    border: 0.5px solid #656eb2;
    font-weight: 600;
  }

  @media screen and (max-width: 575.98px) {
    .btn-cancel,
    .btn-save {
      width: 120px;
    }
  }
`;

const AddAccountBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4252c9;
  box-shadow: 0px 2px 7px rgba(43, 53, 133, 0.27);
  border-radius: 11px;
  width: 200px;
  height: 45px;

  p {
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 0.25rem;
  }

  @media (max-width: 575.98px) {
    width: auto;
  }
`;

const BreadCrumbSection = styled.div`
  display: flex;
  justify-content: flex-end;

  ol {
    margin-bottom: 0;
  }

  @media (max-width: 575.98px) {
    display: none;
  }
`;

export {
  SearchSection,
  AddAccountSection,
  HeaderActions,
  AccountsTableContainer,
  AccountsTableBackground,
  TableSection,
  AddAccountBtn,
  BreadCrumbSection,
  FormSection,
};
