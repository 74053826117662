import React from "react";
import * as Yup from "yup";
import styled from "styled-components";
import { ErrorMessage, Field, Form, Formik } from "formik";
import classNames from "classnames";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import FormGenertor from "components/customs/form-generator/input";
import CustomSelect from "components/customs/dropdown";
import { DropdownIndicator, accountCustomStyle } from "utility/constants";
import { useNavigate } from "react-router";

const StyledRed = styled.span`
  color: red;
`;
const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const validSchema = Yup.object().shape({
  lastname: Yup.string().required("Required"),
  firstname: Yup.string().required("Required"),
  email: Yup.string().email("Invalid"),
  designation: Yup.string().required("Required"),
  mobile: Yup.string()
    .min(12, "At least 12 digits")
    .matches(/^(639)\d{9}$/, "Invalid format")
    .required("Required"),
});

const NationalAgencyForm = ({
  handleOnSumbmit,
  formFields,
  setFormFields,
  agencies,
  selectedAgency,
  setSelectedAgency,
  selectedAgencyError,
  isLoading,
  regions,
  setSelectedRegion,
  selectedRegion,
  selectedRegionError,
}) => {
  const navigate = useNavigate();

  return (
    <Formik
      validationSchema={validSchema}
      onSubmit={handleOnSumbmit}
      initialValues={formFields}
      enableReinitialize
    >
      {({ handleChange, values }) => (
        <Form id="add-account-form">
          <Field name="lastname">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"Last Name"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="lastname"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.lastname && touched?.lastname,
                    })}
                    value={values.lastname}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        lastname: evt.target.value || "",
                      });
                    }}
                  />

                  <ErrorMessage name="lastname">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="firstname">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"First Name"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="firstname"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.firstname && touched?.firstname,
                    })}
                    value={values.firstname}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        firstname: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="firstname">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="email">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">{"Email"}</h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="email"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.email && touched?.email,
                    })}
                    value={values.email}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        email: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="email">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Field name="mobile">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"Contact Number"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <FormGenertor
                    type="mobile"
                    name="mobile"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.mobile && touched?.mobile,
                    })}
                    value={values.mobile || "63"}
                    onChange={(value) => {
                      handleChange(value);

                      setFormFields({
                        ...formFields,
                        mobile: value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="mobile">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <h5 className="">
                {"Agency"}
                {RequiredIndicator}
              </h5>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <CustomSelect
                placeholder=""
                name="agency"
                styles={accountCustomStyle}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={agencies}
                value={selectedAgency}
                onChange={(option) => setSelectedAgency(option)}
                // eslint-disable-next-line consistent-return
                filterOption={(option, searchValue) => {
                  if (
                    option.label
                      .toLowerCase()
                      .includes(searchValue?.toLowerCase()) ||
                    option?.data?.acronym
                      ?.toLowerCase()
                      ?.includes(searchValue?.toLowerCase())
                  ) {
                    return option;
                  }
                }}
              />
              <small className="field-error text-danger">
                {selectedAgencyError}
              </small>
            </Col>
          </Row>

          <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <h5>
                {"Area"}
                {RequiredIndicator}
              </h5>
            </Col>
            <Col
              xs="12"
              sm="6"
              md="12"
              lg="6"
            >
              <CustomSelect
                placeholder=""
                name="area"
                styles={accountCustomStyle}
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                options={regions}
                value={selectedRegion}
                onChange={(option) => setSelectedRegion(option)}
              />
              <small className="field-error text-danger">
                {selectedRegionError}
              </small>
            </Col>
          </Row>

          <Field name="designation">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-4 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <h5 className="">
                    {"Designation"}
                    {RequiredIndicator}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  sm="6"
                  md="12"
                  lg="6"
                >
                  <Input
                    type="text"
                    name="designation"
                    className={classNames("form-control input", {
                      "is-invalid": errors?.designation && touched?.designation,
                    })}
                    value={values.designation}
                    onChange={(evt) => {
                      handleChange(evt);

                      setFormFields({
                        ...formFields,
                        designation: evt.target.value || "",
                      });
                    }}
                  />
                  <ErrorMessage name="designation">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-5  d-flex justify-content-end">
            <Button
              className="btn-cancel mr-1"
              disabled={isLoading}
              onClick={() =>
                navigate("/directory", {
                  state: { view: "national" },
                })
              }
            >
              {"Back"}
            </Button>

            <Button
              type="submit"
              className="btn-save"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="light"
                  size="sm"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default NationalAgencyForm;
