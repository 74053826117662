import React, { useEffect } from "react";
import BreadCrumbComponent from "components/customs/breadCrumb";
import { Col, Spinner } from "reactstrap";
import FormGenertor from "components/customs/form-generator/input";
import AsyncSelect from "react-select/async";
import { getUserDetails } from "config";
import {
  DropdownIndicator,
  addProjectDropdownStyles,
  addProjectSearchInputStyle,
  congRoles,
} from "utility/constants";
import {
  fetchAllAgencies,
  fetchAllAgencyProjects,
} from "services/services.projects";
import _ from "lodash";
import CustomSelect from "components/customs/dropdown";
import { NumericFormat } from "react-number-format";
import CustomDropzone from "views/projects/add/Dropzone";
import { Toast, getFormattedOptions } from "utility/helpers";
import { useLocation, useNavigate } from "react-router";
import { genericGet, genericPost } from "services/services.general";
import {
  updateUtilizationReport,
  fetchOthersList,
} from "services/services.utilization";
import DatePicker from "react-datepicker";
import {
  BreadCrumbSection,
  HeaderTextSection,
  MainContainer,
  Container,
  FormSection,
  RowSection,
  StyledRed,
  FormColRight,
  BtnSave,
  BtnSection,
  BtnCancel,
} from "../styled";
import "react-datepicker/dist/react-datepicker.css";
import "../styles.scss";

const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const AddUtilizationForm = () => {
  const location = useLocation();
  const row = location.state?.row || null;
  const { action } = location.state;

  const PRINCIPAL_IDS = [5, 234, 235];

  const acceptedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/heic",
    "image/gif",
    "video/mp4",
    "video/mpeg",
    "video/webm",
    "video/ogg",
    "video/avi",
  ];

  const acceptedExcel = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [uploadedExcel, setUploadedExcel] = React.useState([]);
  const [agencyOptions, setAgencyOptions] = React.useState([]);
  const [projectOptions, setProjectOptions] = React.useState([]);
  const [othersOptions, setOthersOptions] = React.useState([]);
  const [selectedAgency, setSelectedAgency] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [selectedPrincipal, setSelectedPrincipal] = React.useState(null);
  const [selectedOthers, setSelectedOthers] = React.useState(null);
  const [dateImplemented, setDateImplemented] = React.useState(null);
  const [totalCost, setTotalCost] = React.useState(null);
  const [isAgenciesFetched, setIsAgenciesFetched] = React.useState(false);
  const [isProjectsFetched, setIsProjectsFetched] = React.useState(false);
  const [displayOthersFilter, setDisplayOthersFilter] = React.useState(false);
  const [disablePrincipal, setdisablePrincipal] = React.useState(false);

  const userDetails = getUserDetails();

  const [form, setForm] = React.useState({
    title: "",
    venue: "",
    dateImplemented,
    numberOfBeneficiary: 0,
    costPerBeneficiary: 0,
    remarks: "",
    partners: "",
    attachments: [],
    congressmanId: 0,
    agencyId: 0,
    projectId: 0,
  });

  const navigate = useNavigate();

  const fetchAgencies = async () => {
    await fetchAllAgencies({
      callback: (data) => {
        const newData = getFormattedOptions("name", data);
        setAgencyOptions(newData);
        setIsAgenciesFetched(true);
      },
    });
  };

  const fetchProjects = async () => {
    await fetchAllAgencyProjects({
      url: `/agency/${selectedAgency?.value}`,
      callback: (data) => {
        let projects = data?.Projects;

        if (selectedAgency?.value === 14) {
          projects = data?.Projects.filter(
            (project) => project.name === "Infrastructure"
          );
        }

        const options = getFormattedOptions("name", projects);
        if (selectedAgency?.value === 14) setSelectedProject(options[0]);
        setProjectOptions(options);
        setIsProjectsFetched(true);
      },
    });
  };

  const fetchCongDetails = async (congName) => {
    await genericGet({
      url: `/congressman?search=${congName}`,
      callback: (data) => {
        const options = {
          ...data,
          label: data[0].name,
          value: data[0].id,
        };

        setSelectedPrincipal(options);

        if (PRINCIPAL_IDS.includes(data[0].id)) {
          setDisplayOthersFilter(true);
        }
      },
    });
  };

  const fetchOthers = async (congId) => {
    await fetchOthersList({
      url: `/utilization/others/list?congressmanId=${congId}`,

      callback: (data) => {
        const newData = getFormattedOptions("allocationContactName", data);
        setOthersOptions(newData);

        if (row) {
          const rowOthers = newData.find(
            (others) => others.allocationContactName === row.principalOthers
          );
          setSelectedOthers(rowOthers);
        }
      },
    });
  };

  const handleCongressmanSearch = _.debounce((inputValue, callback) => {
    genericGet({
      url: `/congressman?search=${inputValue}`,
      callback: (data) => {
        if (data?.length) {
          const options = data?.map((item) => ({
            ...item,
            label: item?.name,
            value: item?.id,
          }));

          callback(options);
        } else {
          callback([]);
        }
      },
    });
  }, 1000);

  const saveData = (formData) => {
    genericPost({
      url: "/utilization",
      formData,
      callback: ({ isSuccess }) => {
        setIsLoading(false);

        if (isSuccess) {
          navigate("/utilization");

          Toast({
            type: 1,
            content: "Successfully created utilization report.",
          });
        } else {
          Toast({
            type: 4,
            content: "An error occured.",
          });
        }
      },
    });
  };

  const updateData = async (formData, id) => {
    await updateUtilizationReport({
      param: id,
      url: `/utilization/${id}`,
      formData,
      callback: ({ isSuccess }) => {
        if (isSuccess) {
          setTimeout(() => {
            Toast({
              type: 1,
              content: "Successfully updated utilization report.",
            });
          }, 1500);

          navigate("/utilization");
        } else {
          Toast({
            type: 4,
            content: "An error occured.",
          });
        }
      },
    });
  };

  const validate = () => {
    if (!form.title) {
      return "Event title cannot be empty";
    }

    if (!form.venue) {
      return "Venue cannot be empty";
    }

    if (!dateImplemented) {
      return "Date cannot be empty";
    }

    if (!form.numberOfBeneficiary) {
      return "No. of Beneficiary cannot be empty";
    }

    if (!form.costPerBeneficiary) {
      return "Cost per Beneficiary cannot be empty";
    }

    if (!selectedAgency) {
      return "Agency cannot be empty";
    }

    if (!selectedProject) {
      return "Program cannot be empty";
    }

    if (!selectedPrincipal) {
      return "Principal cannot be empty";
    }

    if (uploadedExcel.length === 0) {
      return "List of Beneficiaries cannot be empty";
    }

    if (!form.fbLinks) {
      return "Facebook Link cannot be empty";
    }

    if (uploadedFiles.length === 0) {
      return "Photos & Videos cannot be empty";
    }

    setIsLoading(false);

    return true;
  };

  const handleSendOnClicked = async () => {
    const isValid = await validate();

    if (typeof isValid === "boolean") {
      setIsLoading(true);

      const formData = {
        title: form.title,
        venue: form.venue,
        dateImplemented,
        numberOfBeneficiary: form.numberOfBeneficiary,
        costPerBeneficiary: form.costPerBeneficiary,
        remarks: form.remarks,
        partners: form.partners,
        attachments: uploadedFiles?.map((file) => file.location),
        congressmanId: selectedPrincipal.value,
        agencyId: selectedAgency.value,
        projectId: selectedProject.value,
        principalOthers: selectedOthers?.allocationContactName,
        xlsxLinks: uploadedExcel?.map((file) => file.location),
        fbLinks: form.fbLinks,
      };

      if (row === null) {
        saveData(formData);
      } else {
        updateData(formData, row.id);
      }
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const handleUploadedFiles = (fileData) => {
    setUploadedFiles((prevUploadedFiles) => {
      const fileIndex = prevUploadedFiles.findIndex(
        (file) => file.name === fileData.name
      );

      // Update existing file or add new one
      if (fileIndex !== -1) {
        const updatedFiles = [...prevUploadedFiles];
        updatedFiles[fileIndex] = fileData;

        return updatedFiles;
      }

      if (prevUploadedFiles.length < 5) {
        return [...prevUploadedFiles, fileData]; // Add the new file
      }

      // Show toast notification if limit is exceeded
      Toast({
        type: 4,
        content: "Exceeded file upload limit of 5",
      });

      return prevUploadedFiles;
    });
  };

  const handleDeleteFile = (file) => {
    const newUploadedFiles = uploadedFiles.filter(
      (files) => files.name !== file
    );
    setUploadedFiles(newUploadedFiles);
  };

  const handleUploadedExcel = (link) => {
    setUploadedExcel((prevUploadedFiles) => {
      const fileIndex = prevUploadedFiles.findIndex(
        (file) => file.name === link.name
      );

      // Update existing file or add new one
      if (fileIndex !== -1) {
        const updatedExcels = [...prevUploadedFiles];
        updatedExcels[fileIndex] = link;

        return updatedExcels;
      }

      if (prevUploadedFiles.length < 5) {
        return [...prevUploadedFiles, link]; // Add the new file
      }

      // Show toast notification if limit is exceeded
      Toast({
        type: 4,
        content: "Exceeded file upload limit of 5",
      });

      return prevUploadedFiles;
    });
  };

  const handleDeleteExcel = (file) => {
    const newUploadedFiles = uploadedExcel.filter(
      (files) => files.name !== file
    );
    setUploadedExcel(newUploadedFiles);
  };

  const setFromData = () => {
    setForm({
      title: row.title,
      venue: row.venue,
      numberOfBeneficiary: row.numberOfBeneficiary,
      costPerBeneficiary: row.costPerBeneficiary,
      remarks: row.remarks,
      partners: row.partners,
      fbLinks: row.fbLinks,
    });

    const total = row.numberOfBeneficiary * row.costPerBeneficiary;
    const parsedDate = new Date(row.dateImplemented);
    setDateImplemented(parsedDate);
    setTotalCost(total);

    fetchCongDetails(row.Congressman.fullName);
    fetchOthers(row.congressmanId);

    const files = [];
    const excels = [];

    if (row?.attachments?.length !== 0) {
      row?.attachments?.forEach((fileURL) => {
        const fileArray = fileURL.split("/");

        const file = {
          name: decodeURIComponent(fileArray[fileArray.length - 1]),
          location: fileURL,
          percent: 100,
        };

        files.push(file);
      });
    }

    if (row?.xlsxLinks?.length !== 0) {
      row?.xlsxLinks?.forEach((fileURL) => {
        const fileArray = fileURL.split("/");

        const file = {
          name: decodeURIComponent(fileArray[fileArray.length - 1]),
          location: fileURL,
          percent: 100,
        };

        excels.push(file);
      });
    }

    setUploadedFiles(files);
    setUploadedExcel(excels);
  };

  useEffect(() => {
    //  fetch all agencies
    fetchAgencies();

    if (congRoles.includes(userDetails.type)) {
      setdisablePrincipal(true);
      fetchCongDetails(userDetails.congressman.name);
    }
  }, []);

  useEffect(() => {
    if (selectedAgency) {
      // reset selected project on agency change
      setSelectedProject(null);
      fetchProjects();
    }
  }, [selectedAgency]);

  useEffect(() => {
    if (row && isAgenciesFetched) {
      const rowAgency = agencyOptions.find(
        (agency) => agency.id === row.agencyId
      );
      setSelectedAgency(rowAgency);
    }
  }, [row, isAgenciesFetched]);

  useEffect(() => {
    if (row && isProjectsFetched) {
      const rowProgram = projectOptions.find(
        (project) => project.id === row.projectId
      );
      setSelectedProject(rowProgram);
    }
  }, [row, isProjectsFetched]);

  useEffect(() => {
    if (row) {
      setFromData();
    }
  }, [row]);

  return (
    <MainContainer>
      <Container>
        <BreadCrumbSection>
          <BreadCrumbComponent
            links={[
              {
                name: "Home",
                redirect: "/home",
              },
              {
                name: "Utilization Report",
                redirect: "/utilization",
              },
              {
                name: row ? "Edit Utilization" : "Create Utilization",
                isActive: true,
              },
            ]}
          />
        </BreadCrumbSection>

        <HeaderTextSection>
          <h2 className="text-danger">
            {/* eslint-disable-next-line no-nested-ternary */}
            {action === "edit"
              ? "Edit Utilization"
              : action !== "view"
              ? "Create New Utilization"
              : "Post Details"}
          </h2>
        </HeaderTextSection>

        <FormSection>
          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Event Title"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the event title"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="text"
                placeholder="Event Title"
                className="form-control"
                value={form.title}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  setForm({
                    ...form,
                    title: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Venue"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the Venue"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="text"
                placeholder="Venue"
                className="form-control"
                value={form.venue}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  setForm({
                    ...form,
                    venue: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection className="form-date-implemented">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Date"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the date of implementation"}</p>
            </Col>
            <FormColRight>
              <DatePicker
                selected={dateImplemented}
                placeholderText="Date"
                className="form-control input-date util-implement-date"
                dateFormat="MMM-dd-yyyy"
                onChange={(date) => {
                  setDateImplemented(date);
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Principal"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the Principal"}</p>
            </Col>
            <FormColRight>
              <div className="d-flex">
                <AsyncSelect
                  className="asyc-dropdown w-100"
                  classNamePrefix="select"
                  placeholder="Principal"
                  loadOptions={handleCongressmanSearch}
                  styles={addProjectSearchInputStyle}
                  value={selectedPrincipal}
                  onChange={(option) => {
                    setSelectedPrincipal(option);

                    if (PRINCIPAL_IDS.includes(option.id)) {
                      setDisplayOthersFilter(true);
                      fetchOthers(option.id);
                    } else {
                      setDisplayOthersFilter(false);
                    }
                  }}
                  isDisabled={disablePrincipal}
                />
              </div>
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Agency"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the Agency"}</p>
            </Col>
            <FormColRight>
              <div className="agency-container">
                <CustomSelect
                  placeholder="Agency"
                  styles={addProjectDropdownStyles}
                  components={{ DropdownIndicator }}
                  classNamePrefix="select"
                  options={agencyOptions}
                  value={selectedAgency}
                  onChange={(option) => {
                    setSelectedAgency(option);
                  }}
                  // eslint-disable-next-line consistent-return
                  filterOption={(option, searchValue) => {
                    if (
                      option.label
                        .toLowerCase()
                        .includes(searchValue?.toLowerCase()) ||
                      option?.data?.acronym
                        ?.toLowerCase()
                        ?.includes(searchValue?.toLowerCase())
                    ) {
                      return option;
                    }
                  }}
                />

                <div className="empty-space" />
              </div>
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Program"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the Program"}</p>
            </Col>
            <FormColRight>
              <CustomSelect
                className="w-100"
                placeholder="Program"
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                styles={addProjectDropdownStyles}
                options={projectOptions}
                value={selectedProject}
                onChange={(option) => setSelectedProject(option)}
                isDisabled={
                  selectedAgency === null || selectedAgency?.value === 14
                }
              />
            </FormColRight>
          </RowSection>

          {displayOthersFilter && (
            <RowSection>
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">{"Others"}</h5>
                <p>{"Provide Other Recipients"}</p>
              </Col>
              <FormColRight>
                <CustomSelect
                  className="w-100"
                  placeholder="Others"
                  components={{ DropdownIndicator }}
                  classNamePrefix="select"
                  styles={addProjectDropdownStyles}
                  options={othersOptions}
                  value={selectedOthers}
                  onChange={(option) => setSelectedOthers(option)}
                />
              </FormColRight>
            </RowSection>
          )}

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Number of Beneficiaries"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the Number of Beneficiaries"}</p>
            </Col>
            <FormColRight>
              <NumericFormat
                placeholder="Number of Beneficiaries"
                className="form-control"
                value={form.numberOfBeneficiary}
                isDisabled={action === "view"}
                onValueChange={(value) => {
                  const { floatValue } = value;

                  const updatedNumberOfBeneficiaries =
                    parseInt(floatValue, 10) || 0;

                  const updatedTotalCost =
                    updatedNumberOfBeneficiaries * form.costPerBeneficiary;

                  setForm({
                    ...form,
                    numberOfBeneficiary: updatedNumberOfBeneficiaries,
                  });

                  setTotalCost(updatedTotalCost);
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Cost per Beneficiaries"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the Cost per Beneficiaries"}</p>
            </Col>
            <FormColRight>
              <NumericFormat
                className="form-control"
                placeholder="Cost per Beneficiary"
                value={form.costPerBeneficiary}
                decimalSeparator="."
                thousandSeparator=","
                onValueChange={(value) => {
                  const { floatValue } = value;

                  const updatedCostPerBeneficiary = floatValue || 0;

                  const updatedTotalCost =
                    form.numberOfBeneficiary * updatedCostPerBeneficiary;

                  setForm({
                    ...form,
                    costPerBeneficiary: updatedCostPerBeneficiary,
                  });

                  setTotalCost(updatedTotalCost);
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Total Cost"}</h5>
              <p>{"Provide the Total Cost"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="text"
                placeholder="Total Cost"
                className="form-control"
                value={totalCost}
                isDisabled
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Remarks"}</h5>
              <p>{"Provide Remarks of the event"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="text"
                placeholder="Remarks"
                className="form-control"
                value={form.remarks}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  setForm({
                    ...form,
                    remarks: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Co-Partners"}</h5>
              <p>{"Provide Other Associations of the event"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="text"
                placeholder="Co-Partners"
                className="form-control"
                value={form.partners}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  setForm({
                    ...form,
                    partners: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"List of Beneficiaries"}
                {RequiredIndicator}
              </h5>
              <p>{"Upload the excel file of List of Beneficiaries"}</p>
            </Col>
            <FormColRight>
              <CustomDropzone
                handleUploadedFiles={handleUploadedExcel}
                handleDeleteFile={handleDeleteExcel}
                uploadedFiles={uploadedExcel}
                label="Upload Excel"
                isDisabled={action === "view"}
                acceptedFileTypes={acceptedExcel}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Facebook Link"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide Facebook Link"}</p>
            </Col>
            <FormColRight>
              <FormGenertor
                type="text"
                placeholder="Facebook Link"
                className="form-control"
                value={form.fbLinks}
                isDisabled={action === "view"}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  setForm({
                    ...form,
                    fbLinks: value,
                  });
                }}
              />
            </FormColRight>
          </RowSection>

          <RowSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Photos & Videos"}
                {RequiredIndicator}
              </h5>
              <p className="util-upload-desc">
                {"Upload the photos and videos of the event"}
              </p>
              <p className="util-file-types">
                {"Accepts jpeg/jpg, png, heic, gif, mp4, mpeg, webm, ogg, avi"}
              </p>
            </Col>
            <FormColRight>
              <CustomDropzone
                handleUploadedFiles={handleUploadedFiles}
                handleDeleteFile={handleDeleteFile}
                uploadedFiles={uploadedFiles}
                label="Upload Files"
                isDisabled={action === "view"}
                acceptedFileTypes={acceptedFileTypes}
              />
            </FormColRight>
          </RowSection>
        </FormSection>

        <BtnSection>
          <BtnCancel
            disabled={isLoading}
            onClick={() => navigate("/utilization")}
          >
            {"Back"}
          </BtnCancel>

          <BtnSave
            onClick={() => handleSendOnClicked()}
            disabled={isLoading || action === "view"}
          >
            {isLoading ? (
              <Spinner
                animation="border"
                color="light"
                size="sm"
              />
            ) : (
              "Save"
            )}
          </BtnSave>
        </BtnSection>
      </Container>
    </MainContainer>
  );
};

export default AddUtilizationForm;
