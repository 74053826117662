import styled from "styled-components";

const ModalHeader = styled.div`
  display: grid;
  justify-content: space-between;
  grid-auto-flow: column;
  margin-top: 0.5rem;
  margin-left: 0.7rem;
  margin-bottom: 1rem;
`;

const ModalBody = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 575.98px) {
    flex-direction: column;
  }

  .row-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ModalContainer = styled.div`
  margin: 1rem;

  textarea {
    width: 100%;
    border: none;
    background: transparent;
  }
`;

export { ModalHeader, ModalBody, ModalContainer };
