import { APP_URL } from "config";
import { Toast } from "utility/helpers";
import api from "./api";

const fetchPartylists = async ({ callback }) => {
  await api({
    Method: "GET",
    Url: `${APP_URL}/party`,
    ResponseSuccessCallback: (response) => {
      if (response && response?.status === 200) {
        const { data } = response;
        callback(data);
      } else {
        Toast({
          type: 4,
          content: "An error occured.",
        });
      }
    },
    ResponseFailCallback: (err) => {
      Toast({
        type: 4,
        content: err?.response.message,
      });
    },
  });
};

const fetchCongressmen = async ({ param, callback }) => {
  await api({
    Method: "GET",
    Url: `${APP_URL}/party/${param}/congressmen`,
    ResponseSuccessCallback: (response) => {
      if (response && response?.data) {
        const data = response?.data?.length !== 0 ? response?.data : [];
        callback(data);
      }
    },
  });
};

export { fetchPartylists, fetchCongressmen };
